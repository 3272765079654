import { useEffect, useState } from "react";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { fetchSubGroups } from "../../../redux/actions/actionSG";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import {
  CreateAutoPopulationData,
  getPrepareConsole,
} from "../services/apiHandler";
import swal from "sweetalert";
import {
  FetchAutoPopulationData,
  AddUpdateAutoPopulation,
} from "../../../redux/actions/actionAutoPopulation";
import {
  getGroupName,
  getScenario,
  groupDataCal,
  prepareAdjustmentData,
  prepareAutoPopulationData,
} from "../../../helper/calculation";
import { getAllIR, getCompany } from "../../../WorkSpace/Components/ApiServices/apiHandler";
import { fetchPCData } from "../../../redux/actions/actionPreset";
import { fetchIR } from "../../../redux/actions/actionIr";

const AutoPopulate = (props) => {
  const { currentCompany, subsiaryData, notes, scenario, id } = props;
  const [fsGropingData, setFsGroupingData] = useState([]);
  const dispatch = useDispatch();
  const subGroupData = useSelector((state) => state.SGReducer);
  const APAdjustment = useSelector((state) => state.APAReducer);
  const preAdjustment = useSelector((state) => state.PADJReducer.response);
  const AdjData = useSelector((state) => state.ADJReducer);
  const groupingData = useSelector((state) => state.GroupingReducer); //console group data
  const [finalSubsidiaryData, setFinalSubsidiaryData] = useState([]);
  const [newDataSet, setNewDataSet] = useState([]);
  const [allIrList, setAllIr] = useState([]);
  const fetchPrepareConsole = useSelector((state) => state.PCReducer);
  const [allcheckdata, setConsolidate] = useState([]);
  const fetchIr = useSelector((state) => state.IRReducer);
  const [allCompany, setAllCompany] = useState([]);

  useEffect(() => {
    AllIrData();
    dispatch(fetchPCData(id));
    dispatch(fetchIR());
    GetCompany();
  }, []);

  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(...allIrList, res?.data?.fetchIR);
    }
  };
  const FindIr = (ir_id) => {
    const IrData = fetchIr?.response?.fetchIR?.filter(
      (data) => data._id === ir_id
    );
    return IrData;
  };
  const GetCompany = async () => {
    const res = await getCompany();
    if (res?.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };
  const browse_fs =
    fetchPrepareConsole?.response?.getOnePreCompany[0]?.consolidation.map(
      (data) => FindIr(data.Ir_id)
    );

  useEffect(() => {
    fetchPreConsole();
    if (allcheckdata && fetchIr.isSuccess) {
      if (
        !subGroupData.isSuccess ||
        subGroupData.project_id !== currentCompany.upload_preset
      ) {
        dispatch(fetchSubGroups(currentCompany.upload_preset));
      }
      if (subGroupData.isSuccess) {
        setFsGroupingData(subGroupData.response.fs_grp[9].notes_grp);
      }
      if (subsiaryData.length > 0) {
        const finals_subsiaryData = subsiaryData.map((det)=>fetchIr?.response?.fetchIR?.filter(
          (data) => data._id === det.Ir_id
        ))
        const subData_ = finals_subsiaryData.map((data, index) => {
          if (getScenario_(subsiaryData,index,FindIr)) {
            return data;
          }
        });
        setFinalSubsidiaryData(subData_.filter((data) => data !== undefined));
      }
      if (
        !APAdjustment.isSuccess ||
        APAdjustment.adjustment_id !== currentCompany._id
      ) {
        dispatch(FetchAutoPopulationData(currentCompany._id));
      }
    }
  }, [subGroupData.isSuccess, APAdjustment.isSuccess]);

  const heading = {
    color: "var(--clr-accent)",
    fontWeight: "700",
  };

  const fetchPreConsole = async () => {
    try {
      const response = await getPrepareConsole(id);
      if (response.status === 200) {
        dispatch(fetchPCData(id.id));
        setConsolidate(response.data.getOnePreCompany[0]?.consolidation);
      }
    } catch (err) {
      console.log("err", err.response);
    }
  };
  const getScenario_ = (subsidiaryData,project_index,FindIr) => {
    const currentDate = moment(currentCompany.current_financial_year).format(
      "YYYY-04-01"
    );
    const previousDate = moment(currentCompany.current_financial_year)
      .subtract(1, "year")
      .format("YYYY-03-31");
      let data = FindIr(subsidiaryData[project_index].Ir_id);
      let testDate = moment(data[0]?.date_Of_investment).format('YYYY-MM-DD');
    //2nd scenario
    const py_date = moment(previousDate)
      .subtract(1, "year")
      .format("YYYY-03-31");
    const previousDate_2nd = moment(currentCompany.current_financial_year)
      .subtract(1, "year")
      .format("YYYY-04-01");
    const first_scenario = moment(testDate).isBetween(
      previousDate,
      currentDate
    );
    const second_scenario = moment(testDate).isBetween(
      py_date,
      previousDate_2nd
    );
    switch (scenario) {
      case "current_yr":
        if (
          first_scenario ||
          second_scenario ||
          (!first_scenario && !second_scenario)
        ) {
          return true;
        } else {
          return false;
        }

      case "previous_yr":
        if (second_scenario || (!first_scenario && !second_scenario)) {
          return true;
        } else {
          return false;
        }
      case "opening":
        if (!first_scenario && !second_scenario) {
          return true;
        } else {
          return false;
        }
      default:
        return false;
    }
  };
 
  const handleChangeParent = (subData, newData, equityData) => {
    let data = {
      additional_preset_id: currentCompany._id,
      date_of_adjustment: subData.date_Of_investment,
      company_id: subData._id,
      company_name: subData.company,
      ledger_name: "",
      note_no: newData.note_no,
      fs_grp: "",
      adjustment_value: Number(newData.adjustment_value),
      remark: newData.remark,
      status: 1,
    };
    switch (newData.ledger_name) {
      case "Goodwill": {
        data = {
          ...data,
          ledger_name: "Goodwill",
          fs_grp: "Non Current Assets",
        };
        const dataIndex = newDataSet.findIndex(
          (item) => item.ledger_name === "Goodwill"
        );
        if (dataIndex === -1) {
          return setNewDataSet([...newDataSet, data]);
        } else {
          const newArr = [
            ...newDataSet.slice(0, dataIndex),
            data,
            ...newDataSet.slice(dataIndex + 1),
          ];
          return setNewDataSet(newArr);
        }
      }
      case "Other inputs": {
        data = {
          ...data,
          ledger_name: "Brands/trademarks",
          fs_grp: "Non Current Assets",
        };
        const dataIndex = newDataSet.findIndex(
          (item) => item.ledger_name === "Brands/trademarks"
        );
        if (dataIndex === -1) {
          return setNewDataSet([...newDataSet, data]);
        } else {
          const newArr = [
            ...newDataSet.slice(0, dataIndex),
            data,
            ...newDataSet.slice(dataIndex + 1),
          ];
          return setNewDataSet(newArr);
        }
      }
      case "Investment": {
        data = {
          ...data,
          ledger_name: "Investments in Equity Instruments - Unquoted",
          fs_grp: "Current Assets",
        };
        const dataIndex = newDataSet.findIndex(
          (item) =>
            item.ledger_name === "Investments in Equity Instruments - Unquoted"
        );
        if (dataIndex === -1) {
          return setNewDataSet([...newDataSet, data]);
        } else {
          const newArr = [
            ...newDataSet.slice(0, dataIndex),
            data,
            ...newDataSet.slice(dataIndex + 1),
          ];
          return setNewDataSet(newArr);
        }
      }
      case "Non controlling": {
        data = {
          ...data,
          ledger_name: "Non-controlling interests",
          fs_grp: "Equity",
        };
        const dataIndex = newDataSet.findIndex(
          (item) => item.ledger_name === "Non-controlling interests"
        );
        if (dataIndex === -1) {
          return setNewDataSet([...newDataSet, data]);
        } else {
          const newArr = [
            ...newDataSet.slice(0, dataIndex),
            data,
            ...newDataSet.slice(dataIndex + 1),
          ];
          return setNewDataSet(newArr);
        }
      }
      default: {
        data = { ...data, ledger_name: equityData.sub_grp, fs_grp: "Equity" };
        const dataIndex = newDataSet.findIndex(
          (item) => item.ledger_name === equityData.sub_grp
        );
        if (dataIndex === -1) {
          return setNewDataSet([...newDataSet, data]);
        } else {
          const newArr = [
            ...newDataSet.slice(0, dataIndex),
            data,
            ...newDataSet.slice(dataIndex + 1),
          ];
          return setNewDataSet(newArr);
        }
      }
    }
  };

  const handleSubmit = () => {
    const checkAdj = newDataSet.map((item) => item.adjustment_value);
    const checkSubmit = checkAdj.reduce((num, acc) => num + acc, 0);
    const uploadSubmit = newDataSet.filter(
      (item) => item.adjustment_value !== 0
    );
    console.log("upload", uploadSubmit);
    if (uploadSubmit.length === 0) {
      swal({
        icon: "warning",
        text: "Please update adjustment value to proceed!",
      });
    }
    if (checkSubmit !== 0) {
      swal({
        icon: "warning",
        text: "make sure the sum of adjustment value must be 0!",
      });
    } else {
      swal({
        title: "Are you sure?",
        text: "Your set all auto population adjustment value will be submitted!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          CreateAutoPopulationData({ auto_adjustment: uploadSubmit })
            .then((response) => {
              dispatch(AddUpdateAutoPopulation(response.data.data));
              swal({
                icon: "success",
                text: response.data.message,
              });
            })
            .catch((err) => {
              swal({
                icon: "danger",
                text: err.message,
              });
            });
        }
      });
    }
  };

  const adjustmentResult = (
    groupDataCal,
    group_index,
    scenario,
    project_index
  ) => {
    try {
      const { BrowsFsData } = groupDataCal;
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => {
        return {
          scenario: getScenario(currentCompany, subsiaryData, sub_index,FindIr),
          brows_fs: BrowsFsData[sub_index][group_index][project_index],
        };
      });
      const browsDataRes = scenarioResult.filter(
        (data) => data.brows_fs !== undefined
      );
      if (browsDataRes.length === 0) {
        return 0;
      }
      let first_scenario_data = 0;
      let second_scenario_data = 0;
      let third_scenario_data = 0;
      if (preAdjustment.includes(browsDataRes[0].brows_fs.group)) {
        const first_scenario_res = scenarioResult.filter(
          (data) => data.scenario.first_scenario === true
        );
        const first_result = first_scenario_res
          .map((data) => data.brows_fs.cy_amt)
          .reduce((data, num) => data + num, 0);
        first_scenario_data += first_result;

        const second_scenario_res = scenarioResult.filter(
          (data) => data.scenario.second_scenario === true
        );
        const second_result = second_scenario_res
          .map((data) => data.brows_fs.cy_amt)
          .reduce((data, num) => data + num, 0);
        second_scenario_data += second_result;

        const third_scenario_res = scenarioResult.filter(
          (data) =>
            data.scenario.first_scenario !== true &&
            data.scenario.second_scenario !== true
        );
        const third_result = third_scenario_res
          .map((data) => data.brows_fs.cy_amt)
          .reduce((data, num) => data + num, 0);
        third_scenario_data += third_result;
      }
      switch (scenario) {
        case "current_yr": {
          return first_scenario_data * -1;
        }
        case "previous_yr": {
          return second_scenario_data * -1;
        }
        case "opening": {
          return third_scenario_data * -1;
        }
        default: {
          return 0;
        }
      }
    } catch (err) {}
  };

  const getPreAdjustment = (note_no, sub_group, project_index_) => {
    try {
      let scenario_ = scenario;
      if (scenario === "current_yr") {
        if (project_index_ === 1) {
          scenario_ = "previous_yr";
        } else if (project_index_ === 2) {
          scenario_ = "opening";
        }
      } else if (scenario === "previous_yr") {
        if (project_index_ === 1) {
          scenario_ = "opening";
        }
      }
      const groupResult = groupDataCal(groupingData, note_no);
      const { currentProject } = groupResult;
      const finalResult = currentProject.map((projectGroup, group_index) => {
        const project_index = projectGroup.findIndex(
          (item) => item.group === sub_group
        );
        if (project_index === -1) return 0;
        else
          return adjustmentResult(
            groupResult,
            group_index,
            scenario_,
            project_index
          );
      });
      return finalResult.reduce((num, acc) => Number(num) + acc, 0);
    } catch (err) {
      return 0;
    }
  };

  const CompanyName =(c_id)=>{
    const result = allCompany.filter((adj) => adj._id === c_id);
    return result[0]?.company_name ? result[0]?.company_name : "";
  }

  return (
    <>
    <p></p>
      {finalSubsidiaryData.length > 0 &&
        finalSubsidiaryData.map((subData, subIndex) => {
          return (
            <>
              <div style={{ marginLeft: "1rem" }}>
                <p style={heading}>
                  {CompanyName(browse_fs[subIndex][0].company)}</p>
              </div>
              <table>
                <AutoPopulateTableHeader />
                <tbody>
                  {/*  Goodwill*/}
                  <AutoPopulateTableData
                    subData={subData}
                    handleSubmit={handleSubmit}
                    currentCompany={currentCompany}
                    handleChangeParent={handleChangeParent}
                    group={"goodwill"}
                    groupName={"Goodwill"}
                    noteNo={"5A"}
                    browse_fs={browse_fs}
                    indexData={subIndex}
                    CompanyName={CompanyName}
                  />
                  {/*  Other inputs*/}
                  <AutoPopulateTableData
                    subData={subData}
                    handleSubmit={handleSubmit}
                    currentCompany={currentCompany}
                    handleChangeParent={handleChangeParent}
                    group={"otherinputs"}
                    groupName={"Other inputs"}
                    noteNo={"5"}
                    browse_fs={browse_fs}
                    indexData={subIndex}
                    CompanyName={CompanyName}
                  />
                  {/*  Investment*/}
                  <AutoPopulateTableData
                    subData={subData}
                    handleSubmit={handleSubmit}
                    currentCompany={currentCompany}
                    handleChangeParent={handleChangeParent}
                    group={"investment"}
                    groupName={"Investment"}
                    noteNo={"6(a)"}
                    browse_fs={browse_fs}
                    indexData={subIndex}
                    CompanyName={CompanyName}
                  />
                  {/* equity */}
                  {fsGropingData.slice(0, -1).map((data, i) => {
                    return (
                      <>
                        {data.sub_grp.map((ele, j) => {
                          return (
                            <AutoPopulateTableData
                              subData={subData}
                              handleSubmit={handleSubmit}
                              currentCompany={currentCompany}
                              handleChangeParent={handleChangeParent}
                              equityData={getPreAdjustment(
                                data.notes_no,
                                ele.sub_grp,
                                subIndex
                              )}
                              groupName={ele.sub_grp}
                              noteNo={data.notes_no}
                              browse_fs={browse_fs}
                              indexData={subIndex}
                              CompanyName={CompanyName}
                            />
                          );
                        })}
                      </>
                    );
                  })}

                  {/*  Non controling*/}
                  <AutoPopulateTableData
                    subData={subData}
                    handleSubmit={handleSubmit}
                    currentCompany={currentCompany}
                    handleChangeParent={handleChangeParent}
                    group={"non_controlling_interest"}
                    groupName={"Non controlling"}
                    noteNo={"39"}
                    browse_fs={browse_fs}
                    indexData={subIndex}
                    CompanyName={CompanyName}
                  />
                </tbody>
              </table>
            </>
          );
        })}
    </>
  );
};
const particlarTab = { 
  width: "300px",
  height: "50px",
  textAlign: "left",
  paddingLeft: "2rem"
}
const AutoPopulateTableHeader = () => {
  return (
    <thead style={{ fontSize: "2rem" }}>
      <tr>
        <th style={{ width: "5%", maxWidth: "5%" }}>Sl.No</th>
        <th
          style={{
            width: "4%",
            maxWidth: "8%",
            height: "62px",
            textAlign: "left",
          }}
        >
          Date input
        </th>
        <th style={particlarTab}>Company Name</th>
        <th style={particlarTab}>Ledger Name</th>
        <th style={particlarTab}>AutoPopulate Value</th>
        <th style={particlarTab}>Adjustments Value</th>
        <th style={particlarTab}>Remark</th>
        <th
        style={particlarTab}
        >
          Action
        </th>
      </tr>
    </thead>
  );
};

let index = 0;

const AutoPopulateTableData = (props) => {
  const {
    subData,
    group,
    groupName,
    equityData,
    noteNo,
    handleChangeParent,
    handleSubmit,
    currentCompany,
    browse_fs,
    indexData,
    CompanyName
  } = props;
  const { isSuccess, response } = useSelector((state) => state.APAReducer);
  const { autoPopulate } = useSelector((state) => state.PADJReducer);

  const [newData, setNewData] = useState({
    ledger_name: groupName,
    adjustment_value: 0,
    note_no: noteNo,
    remark: "",
  });
  useEffect(() => {
    if (isSuccess) {
      getAdjustmentValue();
    }
  }, [isSuccess]);

  const getAdjustmentValue = () => {
    if (group) {
      let apData = autoPopulate.find((item) => item.group === group);
      if (apData) {
        const setAutoPopulateData = response.find(
          (item) =>
            item.ledger_name === apData.ledger_name &&
            item.company_id === subData.all_data._id
        );
        if (setAutoPopulateData) {
          setNewData({
            ...newData,
            adjustment_value: setAutoPopulateData.adjustment_value,
            remark: setAutoPopulateData.remark,
          });
        }
      }
    } else {
      const setAutoPopulateData = response.find(
        (item) =>
          item.ledger_name === groupName &&
          item.company_id === subData.all_data._id
      );
      if (setAutoPopulateData) {
        setNewData({
          ...newData,
          adjustment_value: setAutoPopulateData.adjustment_value,
          remark: setAutoPopulateData.remark,
        });
      }
    }
  };

  const handleChange = (e) => {
    let new_data = { ...newData, [e.target.name]: e.target.value };
    setNewData(new_data);
    handleChangeParent(subData.all_data, new_data, equityData);
  };

  return (
    <tr>
      <td>{index++}</td>
      <td>{browse_fs[indexData][0].date_Of_investment}</td>
      <td>{CompanyName(browse_fs[indexData][0].company)}</td>
      <td style={{textAlign:"left"}}>{groupName}</td>
      <td>
        {equityData ? (
          equityData
        ) : (
          <>
            {browse_fs[indexData][0]?.console_entry[0][group]
              ? browse_fs[indexData][0]?.console_entry[0][group]
              : 0}
          </>
        )}
      </td>
      <td>
        <input
          type="number"
          value={newData.adjustment_value}
          style={{ border: "0" }}
          name="adjustment_value"
          onChange={handleChange}
          placeholder="Enter Adjust Value"
        ></input>
      </td>
      <td>
        <textarea
          name="remark"
          disabled={
            newData.adjustment_value && newData.adjustment_value !== "0"
              ? false
              : true
          }
          onChange={handleChange}
          value={newData.remark}
        ></textarea>
      </td>
      <td>
        {newData.adjustment_value && newData.adjustment_value !== "0" ? (
          <DataSaverOnIcon
            style={{ cursor: "pointer", color: "blue" }}
            onClick={() => handleSubmit()}
          />
        ) : (
          <DataSaverOnIcon style={{ color: "grey" }} />
        )}
      </td>
    </tr>
  );
};

export default AutoPopulate;
