import React, { useEffect, useLayoutEffect } from 'react'
import PropTypes from "prop-types";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Select from "react-select";
// import "./templateTabs.css";

// import BalanceSheet from "./BalanceSheet";
// import PnlStatement from "./PnlStatement";
// import Pattern1 from "./Pattern1";
// import Pattern2 from "./Pattern2";
// import Pattern3 from "./Pattern3";
import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ConsoleBalanceSheet from './consoleTabs/ConsoleBalanceSheet';
import ConsolePnlStatement from './consoleTabs/ConsolePnlStatement';
import ConsolePattern1 from './consoleTabs/ConsolePattern1';
import ConsolePattern2 from './consoleTabs/ConsolePattern2';
import ConsolePattern3 from './consoleTabs/ConsolePattern3';


function ConsoleTemplateTabs(props) {
    const [group, setGroup] = useState();
  const [rows, setRows] = useState([]);
  const [hideData, setHideData] = React.useState([]);
  const [hideChildData, sethideChildData] = useState([]);
  const [rowssubsidiary, setRowsSubsidiary] = useState([]);
 const [fsbrowsData,setFsBrowsData] =useState([]);
  const [allSubData, setAllSubData] = useState([]);
  const [groupingdata,setGroupingData] = useState([]);
  const [groupingdatafs,setGroupingDataBrowsFs] = useState([]);
  const [datafinalsub,setdataFinalSub] = useState([]);
  const [browsfsdata,setbrowsFsData] = useState([]);
  
  const shortcut = {
    color: "var(--clr-accent)",
    fontWeight: "600",
    fontSize: "1rem",
    marginLeft: "1rem",
    cursor: "pointer",
    borderRadius: "50%",
    border: "2px solid var(--clr-accent)",
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const options = [
    { value: 0, label: "Balance Sheet" },
    { value: 1, label: "PNL statement" },
    { value: 2, label: "Note - Property, Plant & Equipment" },
    { value: 3, label: "Note - Capital Work-In Progress" },
    { value: 4, label: "Note - Investment Properties" },
    { value: 5, label: "Note - Goodwill" },
    { value: 6, label: "Note - Other Intangible Assets" },
    { value: 7, label: "Note - Deferred Tax Assets" },
    { value: 8, label: "Note - Other non-current Assets" },
    { value: 9, label: "Note - Financial Assets - Investments" },
    { value: 10, label: "Note - Financial Assets - Loans" },
    { value: 11, label: "Note - Financial Assets - Other Financial Assets" },
    // { value: 12, label: "Note - Contract assets" },
    // {
    //   value: 13,
    //   label: "Note - Investments Accounted for using the equity method",
    // },
    { value: 12, label: "Note - Inventories" },
    { value: 13, label: "Note - Financial Assets - Trade Receivables" },
    { value: 14, label: "Note - Financial Assets - Cash and cash equivalents" },
    { value: 15, label: "Note - Other current assets" },
    { value: 16, label: "Note - Assets classified as held for sale" },
    { value: 17, label: "Note - Equity Share Capital" },
    {
      value: 18,
      label:
        "Note - other equity - equity component of compound financial instruments",
    },
    { value: 19, label: "Note - other equity - reserves and surplus" },
    { value: 20, label: "Note - other equity - other reserves" },
    { value: 21, label: "Note - Non Controlling Interest" },
    { value: 22, label: "Note - Financial Liabilities - Borrowings" },
    {
      value: 23,
      label: "Note - Financial liabilities - Other Financial Liabilities",
    },
    { value: 24, label: "Note - Provisions" },
    { value: 25, label: "Note - Employee benefit obligations" },
    { value: 26, label: "Note - Deferred tax liabilities" },
    { value: 27, label: "Note - Government grants" },
    { value: 28, label: "Note - Other Non-Current Liabilities" },
    { value: 29, label: "Note - Financial Liabilities - Trade Payable" },
    { value: 30, label: "Note - Contract Liabilities" },
    { value: 31, label: "Note - Current tax Liabilities" },
    { value: 32, label: "Note - Other Current Liabilities" },
    {
      value: 33,
      label:
        "Note - Liabilities directly associated with assets classified as held for sale",
    },
    { value: 34, label: "NOTE 36" },
    { value: 35, label: "NOTE 37" },
    { value: 36, label: "NOTE 38" },
    { value: 37, label: "NOTE 39" },
    { value: 38, label: "NOTE 40" },
    { value: 39, label: "NOTE 41" },
    { value: 40, label: "NOTE 42" },
    { value: 41, label: "NOTE 43" },
    { value: 42, label: "NOTE 44" },
    { value: 43, label: "NOTE 45" },
  ];


  

  const [value, setValue] = React.useState(0);
  // const TB_ID = useSelector((initialState) => initialState.reducer.tb_id);
  const [isHide, setHide] = useState(false);
  const auth = localStorage.getItem("auth_token");
  let allIdProject = [];
  let allbrouseFsData = [];
  React.useEffect(() => {
    // browsfsTbId();
    setRows([]);
    setHide(false);
    tbData();
    fetchBalanceSheet();
    subsiaryData();
    setRowsSubsidiary([]);
    datasubsiaryfetch();
    fetchBrowsfsData();
  }, [value, props.updateTabs]);

  const tbData =() =>{
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
  };
  axios
      .get(`api/v1/tb-mapping/get-tb/${props.currentProject}`, {
          headers,
      })
      .then((response) => {
          console.log(
              response.data.tb_id,
              "eeeeee"
          );
          fetchBalanceSheet(response.data.tb_id);
      });
  console.log('gdgdgd');
  }
  const fetchBalanceSheet = (TB_ID) => {
    console.log(TB_ID,"sasasasasasa");
    setHideData([]);
    const auth = localStorage.getItem("auth_token");
    // const project_id = localStorage.getItem("project_id");
    const project_id = props.currentProject;
    let headers = {
      "x-auth-token": auth,
    };
    console.log(value,"77777777777777");
    if (TB_ID) {
      if (value === 0) {
        axios
          .get(`/api/v1/bs/generate-bs/${project_id}/${TB_ID}`, { headers })
          .then((response) => {
            // setLoading(false)
            console.log(response.data.balance_sheet, "response response11");
            setRows(response.data.balance_sheet);
          })
          .catch((error) => {
            console.log(error.response, "error 3232323");
          });
      } else {
        axios
          .get(`/api/v1/pnl/generate-pnl/${project_id}/${TB_ID}`, { headers })
          .then((response) => {
            console.log(response, "response response22");
            setRows(response.data.pnl_statement);
          })
          .catch((error) => {
            console.log(error.response, "error");
          });
      }
    }
    
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function handleSelect(e) {
    if (e == null) {
      return;
    }
    setValue(e.value);
  }

  React.useEffect(() => {
    console.log(rows,'544');
    console.log(hideData,'3343');
    // setRows([])
    if (hideData.length > 0) {
      hideData.map((hidedata) => {
        console.log(rows[hidedata]);
        delete rows[hidedata];
      });

    } else {
    }
  }, [isHide]);

  const handleEye = () => {
    if (isHide) {
      setHide(false);
      fetchBalanceSheet();
    } else {
      setHide(true);
    }
  };

  const handleOptions = (o) => {
    setRows([]);
    if (o === 0) {
      setValue(0);
    } else {
      setValue(1);
    }
  };


  const subsiaryData=()=>{
    let headers = {
      "x-auth-token": auth,
      // 'Content-Type' : 'application/json'
  };
  axios
      .get(`api/v1/tb-mapping/get-tb/${props.currentProject}`, {
          headers,
      })
      .then((response) => {
          console.log(
              response.data.tb_id,
              "eeeeee"
          );
      });
  }



  // subsidiary data
      function datasubsiaryfetch(){
        console.log(props.headingValue,"props.headingValue");
        props.headingValue.length > 0 && props.headingValue.map((row, i) =>  {
            if (row.id) {
              let headers = {
                "x-auth-token": auth,
                  // 'Content-Type' : 'application/json'
              };
              axios
                  .get(`api/v1/tb-mapping/get-tb/${row.id}`, {
                      headers,
                  })
                  .then((response) => {
                      console.log(
                          response.data.tb_id,
                          "kjjjjdkkkjdjjdjjjdj"
                      );
                      // fetchBalanceSheetSub(,row.id);
                      fetchBalanceSheetSub(response.data.tb_id,row.id);
                  });
            }
            
        });
    }

    const fetchBalanceSheetSub = (tb_id,project_id) => {
      const auth = localStorage.getItem("auth_token");
      let headers = {
        "x-auth-token": auth,
      };
      if (tb_id) {
        if (value === 0) {
          axios
            .get(`/api/v1/bs/generate-bs/${project_id}/${tb_id}`, { headers })
            .then((response) => {
              // setLoading(false)
              console.log(response.data.balance_sheet, "response response198");
              setRowsSubsidiary(response.data.balance_sheet);
              setAllSubData([...allSubData,response.data.balance_sheet])
            })
            .catch((error) => {
              console.log(error.response, "error 3232323");
            });
        } else {
          axios
            .get(`/api/v1/pnl/generate-pnl/${project_id}/${tb_id}`, { headers })
            .then((response) => {
              console.log(response.data.pnl_statement, "response response898");
              setRowsSubsidiary(response.data.pnl_statement);
              setAllSubData([...allSubData,response.data.pnl_statement])
            })
            .catch((error) => {
              console.log(error.response, "error");
            });
        }
      }
      
    };


    console.log(props.dataAllCheck,"dataAllCheckdataAllCheck");
    props.dataAllCheck.length > 0 && props.dataAllCheck.forEach(element => {
      if (element.final_holdings > "50") {
        allIdProject.push(element.prepare_preset_project_id);
        allbrouseFsData.push(element.browse_control_dateFS);
      }
    });
    

      const fetchBrowsfsData=()=>{
          let testarr = [];
          let groupdata = [];
          let allp_id = [];
          // allIdProject.forEach(data => {
          //   console.log(data,"chin668878");
                

          //   // sub
            
          //   allp_id.push(data);


          //   let headers = {
          //     "x-auth-token": auth,
          //     // 'Content-Type' : 'application/json'
          //   };
          //   axios
          //       .get(`api/v1/grouping/fs/${data}`, {
          //           headers,
          //       })
          //       .then((response) => {
          //           console.log(
          //               response.data,
          //               "grouping responsegrtrtrtrgtr"
          //           );
          //           groupdata.push(response.data);
          //       });
        
            
          // });

          console.log(allp_id,"allp_id");
          console.log(allIdProject,"allIdProjectallIdProject");
          // all projectId comes in a array dn call to API call

          if (allIdProject.length > 0) {
            let headers = {
              "x-auth-token": auth,
            };
            axios
                .get(`api/v1/tb-mapping/console-get-tb?params=${JSON.stringify(allIdProject)}`,{
                    headers,
                })
                .then((response) => {
                    console.log(
                        response,
                        "cinmsauhdbjdb"
                    );

                      // if (value === 0) {
                      //   axios
                      //     .get(`/api/v1/bs/generate-bs/${data}/${response.data.tb_id}`, { headers })
                      //     .then((response) => {
                      //       testarr.push(response.data.balance_sheet)
                      //       console.log(response.data.balance_sheet, "response response198----");
                      //     })
                      //     .catch((error) => {
                      //       console.log(error.response, "error 3232323");
                      //     });
                      // } else {
                      //   axios
                      //     .get(`/api/v1/pnl/generate-pnl/${data}/${response.data.tb_id}`, { headers })
                      //     .then((response) => {
                      //       testarr.push(response.data.pnl_statement)
                      //       console.log(response.data.pnl_statement, "response response898");
                      //       // setNewDataSubsidiary(newData);

                      //     })
                      //     .catch((error) => {
                      //       console.log(error.response, "error");
                      //     });
                      // }
                });
          }


          browsfsdata(testarr);
          setGroupingData(groupdata);

          //browsfs
          let browsarr = [];
          let browsgrouping = [];

          // allbrouseFsData.forEach(elem=>{
          //   if (elem.length > 0) {
          //     let headers = {
          //       "x-auth-token": auth,
          //     };
          //     axios
          //         .get(`api/v1/tb-mapping/get-tb/${elem}`, {
          //             headers,
          //         })
          //         .then((response) => {
          //             console.log(
          //                 response.data.tb_id,
          //                 "kjjjjdkkkjdjjdjjjdj"
          //             );
          //               if (value === 0) {
          //                 axios
          //                   .get(`/api/v1/bs/generate-bs/${elem}/${response.data.tb_id}`, { headers })
          //                   .then((response) => {
          //                     browsarr.push(response.data.balance_sheet)
          //                   })
          //                   .catch((error) => {
          //                     console.log(error.response, "error 3232323");
          //                   });
          //               } else {
          //                 axios
          //                   .get(`/api/v1/pnl/generate-pnl/${elem}/${response.data.tb_id}`, { headers })
          //                   .then((response) => {
          //                     browsarr.push(response.data.pnl_statement)
          //                     console.log(response.data.pnl_statement, "response response898");

          //                   })
          //                   .catch((error) => {
          //                     console.log(error.response, "error");
          //                   });
          //               }
          //         });
          //   }

          //   let headers = {
          //     "x-auth-token": auth,
          //     // 'Content-Type' : 'application/json'
          //   };
          //   axios
          //       .get(`api/v1/grouping/fs/${elem}`, {
          //           headers,
          //       })
          //       .then((response) => {
          //           console.log(
          //               response.data,
          //               "grouping responsegrtrtrtrgtr"
          //           );
          //           browsgrouping.push(response.data);
          //       });
          // });
          setbrowsFsData(browsarr);
          setGroupingDataBrowsFs(browsgrouping);

      }
      console.log(groupingdata,"434334222222",groupingdatafs);

  return (
    <div style={{ width: "100%" }}>
    <div>
      <table>
        <thead>
          <tr>
            <th style={{ width: "60%", height: "60px" }}>
              <Select
                as="select"
                options={options}
                placeholder="select the notes"
                isClearable
                onChange={(e) => {
                  handleSelect(e);
                }}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "6px",
                  zIndex: "0",
                  colors: {
                    ...theme.colors,
                    borderColor: "white",
                    backgroundColor: "white",
                    primary25: "#03565a98",
                    primary: "#03565A",
                  },
                  spacing: {
                    ...theme.spacing,
                    // controlHeight: 20,
                  },
                })}
              />
            </th>
            <th style={{}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  onClick={(e) => {
                    handleOptions(0);
                    // setValue(0);
                  }}
                  style={shortcut}
                >
                  BS
                </div>
                <div
                  onClick={(e) => {
                    handleOptions(1);
                    // setValue(1);
                  }}
                  style={shortcut}
                >
                  PNL
                </div>
                {/* <div
                  onClick={(e) => {
                    handleEye();
                  }}
                  style={shortcut}
                >
                  {!isHide ? (
                    <i class="far fa-eye-slash"></i>
                  ) : (
                    <i class="far fa-eye"></i>
                  )}
                </div> */}
                <div></div>
              </div>
            </th>
            {/* <th style={{}}>Previous year</th> */}
            {/* <th style={{}}>Current year</th> */}
            {/* <th style={{minWidth:'200px',width: '20vw',}}>Guidances</th> */}
          </tr>
        </thead>
      </table>
    </div>
    <Box className="MuiBox-rootTab" sx={{ width: "100%" }}>
        <TabPanel style={{ margin: "0", padding: "0" }} value={value} index={0}>
          <ConsoleBalanceSheet
            setTempValue={setValue}
            setGroup={setGroup}
            rows={rows}
            setHideData={setHideData}
            hideData={hideData}
            hideChildData={hideChildData}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            sybsidiary ={allSubData}
            browsFs ={fsbrowsData}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ConsolePnlStatement
            setTempValue={setValue}
            rows={rows}
            setHideData={setHideData}
            hideData={hideData}
            hideChildData={hideChildData}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            sybsidiary ={allSubData}
            browsFs ={fsbrowsData}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ConsolePattern1
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            note_no={3}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
          {/* <Pattern1
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            note_no="22(c)"
          /> */}
        </TabPanel>
        <TabPanel value={value} index={3}>
          <ConsolePattern1
            SetNewSg={props.SetNewSg}
            // setAddGrouping={props.setAddGrouping}
            note_no={"3A"}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <ConsolePattern1
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            note_no={4}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <ConsolePattern1
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            note_no={"5A"}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={6}>
          <ConsolePattern1
            note_no={5}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <ConsolePattern1
            note_no={"5B"}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <ConsolePattern1
            note_no={"5C"}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <ConsolePattern1
            note_no="6(b)"
            group={0}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <ConsolePattern1
            note_no="7"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
          {/* <Pattern2
            note_no="6(b)"
            group={0}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
          /> */}
        </TabPanel>
        <TabPanel value={value} index={11}>
          <ConsolePattern1
            note_no="8"
            // group={0}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={12}>
          <ConsolePattern2
            note_no="6(a)"
            group={0}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={13}>
          <ConsolePattern2
            group={0}
            note_no="6(c)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={14}>
          <ConsolePattern2
            group={0}
            note_no="6(e)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={15}>
          <ConsolePattern1
            note_no="9"
            group={0}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={16}>
          <ConsolePattern1
            note_no="6(d)"
            group={0}
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={17}>
          <ConsolePattern1
            note_no="6(z)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={18}>
          <ConsolePattern1
            note_no="11(a)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={19}>
          <ConsolePattern1
            note_no="10"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={20}>
          <ConsolePattern1
            note_no="11"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={21}>
          <ConsolePattern1
            note_no="40"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        {/* <TabPanel value={value} index={22}>
                </TabPanel> */}
        <TabPanel value={value} index={22}>
          <ConsolePattern1
            note_no="41"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={23}>
          <ConsolePattern1
            note_no="35"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={24}>
          <ConsolePattern1
            note_no="36"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={25}>
          <ConsolePattern1
            note_no="39"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={26}>
        <ConsolePattern3
            note_no="13(a)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={27}>
          <ConsolePattern3
            note_no="13(b)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={28}>
          <ConsolePattern3
            note_no="14"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={29}>
          <ConsolePattern1
            note_no="16"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={30}>
          <ConsolePattern1
            note_no="18(b)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
          {/* <Pattern1 note_no={3} /> */}
        </TabPanel>
        <TabPanel value={value} index={31}>
          <ConsolePattern3
            note_no="13(c)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={32}>
          <ConsolePattern1
            note_no="18A"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={33}>
          <ConsolePattern1
            note_no="17"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={34}>
          <ConsolePattern1
            note_no="19"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={35}>
          <ConsolePattern1
            note_no="33"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={36}>
          <ConsolePattern3
            note_no_pnl="14"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={37}>
          <ConsolePattern1
            note_no_pnl="16"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            presentCompany={props.presentCompany}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={38}>
          <ConsolePattern3
            note_no_pnl="18"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={39}>
          <ConsolePattern3
            note_no_pnl="18(b)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>

        <TabPanel value={value} index={40}>
          <ConsolePattern3
            note_no_pnl="13(c)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={41}>
          <ConsolePattern1
            note_no_pnl="18A"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={42}>
          <ConsolePattern1
            note_no_pnl="17"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={43}>
          <ConsolePattern1
            note_no_pnl="19"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={44}>
          <ConsolePattern1
            note_no_pnl="33"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={45}>
          <ConsolePattern1
            note_no_pnl="20"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={46}>
          <ConsolePattern1
            note_no_pnl="21(a)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={47}>
          <ConsolePattern1
            note_no_pnl="21(c)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={48}>
          <ConsolePattern1
            note_no_pnl="21(d)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={49}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="22(a)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={50}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="22(c)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={51}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="22(b)"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={52}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="23"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={53}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="24"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={54}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="3,5"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>

        {/* added by @kanha */}
        <TabPanel value={value} index={55}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="29"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={56}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="29A"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={57}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="25"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={58}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="26"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={59}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="27"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={60}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="28"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={61}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="30"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={62}>
          {/* <Pattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="31"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={63}>
          {/* <ConsolePattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="12"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
        <TabPanel value={value} index={64}>
          {/* <ConsolePattern1 note_no='17' /> */}
          <ConsolePattern1
            note_no_pnl="12A"
            SetNewSg={props.SetNewSg}
            setAddGrouping={props.setAddGrouping}
            currentCompany ={props.setCurrentCompany}
            subsiaryData={props.subsidiaryCompany}
            headingData = {props.headingValue}
            subsiary ={rowssubsidiary}
            browsFs ={fsbrowsData}
            subsidiaryGrouping = {groupingdata}
            browsFsGrouping = {groupingdatafs}
            finalSubsidiaryData = {datafinalsub}
            finalBrowsFsData = {browsfsdata}
          />
        </TabPanel>
    </Box>
  </div>
  )
}

export default ConsoleTemplateTabs
