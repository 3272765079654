// import Reacct from 'react'
import { Navbar } from "../../Components/Navbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import React, { useState } from "react";
import "./finalisefs.css";
import Preview from "./Preview";
import ExportExcel from "./exportExcel";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import AddHeaders from "./headers";
import { useHistory } from "react-router-dom";
import AddFooters from "./footers";

// import "./finalisefs.css";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const FinaliseFs = () => {
  const [headers, setHeaders] = useState([]);
  const [footers, setFooters] = useState([]);
  const [exportPages, setExportPages] = useState([]);

  const [uploadedUrl, setUploadedUrl] = useState({
    image1: null,
    image2: null,
    image3: null,
    sheet1: [],
    sheet2: [],
    sheet3: [],
  });

  const [headerCount, setHeaderCount] = useState([0]);
  const [footerCount, setFooterCount] = useState([0]);

  const popHeaderCount = (index) => {
    setHeaderCount(headerCount.filter((_, idx) => idx !== index));
    setHeaders(headers.filter((_, idx)=> idx !== index));
  }

  const popFooterCount = (index) => {
    setFooterCount(footerCount.filter((_, idx) => idx !== index));
    setFooters(footers.filter((_, idx)=> idx !== index));
  }

    function getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

  const units_values = [
    "Whole Number",
    "Hundreds",
    "Thousands",
    "Lakhs",
    "Crores",
    "Millions",
  ];
  const decimal_values = [0, 1, 2, 3];

  const [unit, setUnit] = useState(null);
  const [decimal, setDecimal] = useState(null);
  const history = useHistory(); 

  const handleNavigateBack = () => {
    history.push("/financialstatement");
  }

  const handleUnitDecimal = (e) => {
    if (e.target.name === "unit") {
      setUnit(e.target.value);
    } else {
      setDecimal(e.target.value);
    }
  };

  const uploadImages = (imageUrl, name) => {
    setUploadedUrl((prevItems) => {
      return {
        ...prevItems,
        [name]: imageUrl,
      };
    });
  };

  const sampleHeaders = [
    "Balance Sheet",
    "P & L",
    "Socie",
    "Cashflow Working",
    "Disclosure Checklist",
    "General Notes",
    "Balancesheet Notes",
    "P & L Notes",
    "Cashflow",
    "Disclosures",
    "All Pages",
  ];

  const handleExportPages = (e) => {
    setExportPages((prevValue)=>{
      if(e.target.checked){
        return [
          ...prevValue,
          e.target.value
        ]
      }else{
        return prevValue.filter(item => item !== e.target.value);
      }
    })
  }

  const handleHeaders = (e, headerNumber) => {
    let value = e.target.value;
    setHeaders((prevHeaders) => {
      // Check if the header already exists
      const headerExists = prevHeaders.some(header => header.header === headerNumber);

      if (headerExists) {
        // If header exists, update its value
        return prevHeaders.map(header =>
          header.header === headerNumber ? { ...header, value: value } : header
        );
      } else {
        // If header does not exist, add it
        return [...prevHeaders, { header: headerNumber, value: value, sheets: [] }];
      }
    });
  };

  const handleImages = (image_url, headerNumber) => {
    setHeaders((prevValue)=>{
      const headerExists = prevValue.some(header => header.header === headerNumber);
      if(headerExists){
        return prevValue.map(header => 
          header.header === headerNumber ? {...header, image: image_url} : header
        )
      } else {
        return [...prevValue, {header: headerNumber, image: image_url}];
      }
    })
  }

  const handleFooters = (e, footerNumber) => {
    let value = e.target.value;
    setFooters((prevFooters) => {
      // Check if the footer already exists
      const footerExists = prevFooters.some(footer => footer.footer === footerNumber);

      if (footerExists) {
        // If footer exists, update its value
        return prevFooters.map(footer =>
          footer.footer === footerNumber ? { footer: footerNumber, value: value, sheets: footer.sheets } : footer
        );
      } else {
        // If footer does not exist, add it
        return [...prevFooters, { footer: footerNumber, value: value, sheets: [] }];
      }
    });
  };

  const handleCheckBox = (e, headerNumber) => {
    const { checked, value} = e.target;
    if(headerCount.length > 1 && value === 'All Pages'){
      alert("You can't select it, as multiple headers opened");
      return false
    }
    if(checked && headers.some(item => item.sheets.includes(value))){
      alert("its already checked in header");
      return false
    }
    setHeaders((prevHeaders) => {
      // Check if the header already exists
      const headerExists = prevHeaders.some(header => header.header === headerNumber);

      if (headerExists) {
        // If header exists, update or add the sheet
        return prevHeaders.map(header => {
          if (header.header === headerNumber) {
            const sheetExists = header.sheets.includes(value);
            if(checked){
              return {
                ...header,
                sheets: sheetExists ? header.sheets : [...header.sheets, value]
              };
            }else{
              return {
                ...header,
                sheets: !sheetExists ? header.sheets : header.sheets.filter((head)=> head !== value)
              };
            }
            
          }
          return header;
        });
      } else {
        // If header does not exist, add it with the new sheet
        return [...prevHeaders, { header: headerNumber, value: '', sheets: [value] }];
      }
    });
    console.log("headers check", headers)
  };

  const handleFooterCheckBox = (e, footerNumber) => {
    const { checked, value} = e.target;
    if(footerCount.length > 1 && value === 'All Pages'){
      alert("You can't select it, as multiple footers opened");
      return false
    }
    console.log("footers", footers)
    if(checked && footers.some(item => item.sheets.includes(value))){
      alert("its already checked in footer");
      return false
    }
    setFooters((prevFooters) => {
      // Check if the footer already exists
      const footerExists = prevFooters.some(footer => footer.footer === footerNumber);

      if (footerExists) {
        // If header exists, update or add the sheet
        return prevFooters.map(footer => {
          if (footer.footer === footerNumber) {
            const sheetExists = footer.sheets.includes(value);
            if(checked){
              return {
                ...footer,
                sheets: sheetExists ? footer.sheets : [...footer.sheets, value]
              };
            }else{
              return {
                ...footer,
                sheets: !sheetExists ? footer.sheets : footer.sheets.filter((foot)=> foot !== value)
              };
            }
            
          }
          return footer;
        });
      } else {
        // If footer does not exist, add it with the new sheet
        return [...prevFooters, { footer: footerNumber, value: '', sheets: [value] }];
      }
    });
    console.log("footers check", footers)
  };

  return (
    <>
      <div>
        <Navbar text="Financial Year: 2021 - 2022" />
        <div className="px-5">
          <div className="row">
            <div className="col-md-8">
              <button className="rounded back-btn btn btn-border-none pl-0 mt-4">
                <div className="d-flex  custom-font-600 custom-common-theme-text-color" style={{cursor:"pointer"}} onClick={handleNavigateBack}>
                  <ArrowBackIcon className="mr-1" /> Back
                </div>
              </button>
            </div>
            <div className="col-md-4">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                  marginLeft: "150px",
                }}
              >
                <ExportExcel
                  headers={headers}
                  footers={footers}
                  images={uploadedUrl}
                  unit={unit}
                  decimal={decimal}
                  exportPages={exportPages}
                />
                <div className="d-flex align-items-center">
                  <span>
                    <PictureAsPdfIcon
                      className="mt-3"
                      style={{ width: "24px", height: "24px" }}
                    />
                  </span>
                  <p
                    className="mb-0 ml-2 font-size-16"
                    style={{ color: "#343434" }}
                  >
                    Export as XL
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div>
                <h6 className="font-weight-bold" style={{ lineHeight: "28px" }}>
                  Finalise your Financial Statements
                </h6>
              </div>
              <div
                style={{
                  height: "100%",
                  border: "2px solid #C3C8D3",
                  borderRadius: "15px",
                }}
              >
                <div className="d-flex mt-4 ml-3" style={{ gap: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "250px",
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel id="unit-value-select-label">
                        Units
                      </InputLabel>
                      <Select
                        labelId="unit-value-select-label"
                        id="unit-value-select"
                        value={unit}
                        label="Units"
                        name="unit"
                        onChange={handleUnitDecimal}
                      >
                        {units_values.map((item, key) => (
                          <MenuItem value={item} key={key}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "250px",
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel id="decimal-value-select-label">
                          Decimals
                        </InputLabel>
                        <Select
                          labelId="decimal-value-select-label"
                          id="decimal-value-select"
                          value={decimal}
                          label="Decimals"
                          name="decimal"
                          onChange={handleUnitDecimal}
                        >
                          {decimal_values.map((item, key) => (
                            <MenuItem value={item} key={key}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <Card style={{ borderRadius: "10px" }}>
                    <div
                      style={{
                        backgroundColor: "#E5EEEE",
                        height: "40px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <h6 className="p-2 ml-3">Header</h6>
                    </div>
                    {headerCount.map((_, idx)=>(
                      <AddHeaders 
                        sampleHeaders={sampleHeaders} 
                        headers={headers} 
                        handleCheckBox={handleCheckBox} 
                        handleHeaders={handleHeaders} 
                        uploadImages={handleImages} 
                        close={idx} 
                        popHeaderCount={popHeaderCount} 
                        key={idx}
                      />
                    ))}
                    {!headers.some(item => item.sheets.includes('All Pages')) &&
                    <div className="text-center">
                      <Button variant="contained" onClick={()=>setHeaderCount([...headerCount, getRandomInt(1,9)])}>Add Header</Button>
                    </div>
                    }
                  </Card>
                </div>
                <div className="p-3">
                  <Card style={{ borderRadius: "15px" }}>
                    <div
                      style={{
                        backgroundColor: "#E5EEEE",
                        height: "40px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <h6 className="p-2 ml-3">Footer</h6>
                    </div>
                    {footerCount.map((_, idx)=>(
                      <AddFooters 
                        sampleFooters={sampleHeaders} 
                        Footers={footers} 
                        handleCheckBox={handleFooterCheckBox} 
                        handleFooters={handleFooters} 
                        uploadImages={uploadImages} 
                        close={idx} 
                        popFooterCount={popFooterCount} 
                        key={idx}
                    />
                    ))}
                    {!footers.some(item => item.sheets.includes('All Pages')) &&
                    <div className="text-center">
                      <Button variant="contained" onClick={()=>setFooterCount([...footerCount, getRandomInt(1,9)])}>Add Footer</Button>
                    </div>
                    }
                  </Card>
                </div>
                <div className="p-3">
                  <Card style={{ borderRadius: "15px" }}>
                    <div
                      style={{
                        backgroundColor: "#E5EEEE",
                        height: "40px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    >
                      <h6 className="p-2 ml-3">Export Pages</h6>
                    </div>
                    <CardContent>
                      <div className="d-flex flex-wrap justify-content-between">
                        {sampleHeaders.map((header, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flex: "0 1 100px", // Adjust the width as needed
                              marginBottom: "5px", // Adds spacing between rows
                            }}
                          >
                            <Checkbox
                              {...label}
                              name="sheet1"
                              value={header}
                              onChange={handleExportPages}
                              checked={
                                exportPages.includes(header) || false
                              }
                              sx={{
                                "&.Mui-checked": {
                                  color: "#03565A ", // Color when checked
                                },
                              }}
                            />
                            <h6 className="mr-2 mt-2">{header}</h6>
                          </div>
                        ))}
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-2">
              <div>
                <h6 className="custom-font-600 font-size-16">Preview</h6>
              </div>
              <div
                style={{ border: "2px solid #C3C8D3", borderRadius: "12px" }}
              >
                <Preview />
              </div>
            </div>
          </div>
        </div>

        <div
          className="mt-5 mb-3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <Button
            className="custom-common-theme-bg-color"
            style={{ width: "150px", height: "50px", color: "white" }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default FinaliseFs;
