import React, { useEffect, useState } from "react";
import { Navbar } from "../../Components/Navbar";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import { Box, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";






const ImportPreset = () => {
    const history = useHistory();
    const {wid}=useParams();

    return (
        <>
            <Navbar />
            <div className="px-5 py-4">
                <div className="d-flex align-items-center">
                    <span><ArrowBackIcon className="custom-common-theme-text-color" /></span>
                    <p className="mb-0 mt-0 ml-2 custom-common-theme-text-color font-size-16 custom-font-600font-sans " onClick={() => history.push(`/bookclosure/${wid}`)} style={{cursor:"pointer"}}>Back</p>
                </div>
                <div className="d-flex justify-content-between mt-4">
                    <div>
                        <p className="font-size-24 custom-font-600 font-sans">Import Preset</p>
                        <p className="text-secondary font-size-16 custom-font-500 font-sans">Some text to give the user Info about Import Preset</p>
                    </div>
                    <div>
                        <div className="search-btnv2 d-flex flex-row jusify-content-center align-items-center mt-3">
                            <span>
                                <SearchIcon  style={{color:"gray"}}/>
                            </span>
                            <input
                                className="border-0 mt-0 p-0"
                                type="text"
                                //   value={search}
                                //   onChange={handleChange}
                                placeholder="Search by preset name..."
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <p className="custom-font-600 font-size-18 font-sans">All presets</p>
                </div>
                <div className="d-flex" style={{ gap: "10px" }}>
                    <div className="w-100">
                        <Card style={{ borderRadius: "15px" }}>
                            <div className="d-flex justify-content-center " style={{ backgroundColor: "#E5EEEE", height: "60px" }}>
                                <p className="mt-0 mb-0 font-size-18 custom-font-600 font-inter">TCS</p>
                            </div>
                            <div className="px-3">
                                <p className="font-size-16 custom-font-600 font-sans">Import</p>
                            </div>
                            <div className="d-flex" style={{ gap: "60px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Tasks
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Templates
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        FSCP Entries
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex ml-3" style={{ gap: "50px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Sub tasks
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        with amount
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        with amount
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex ml-3" style={{ gap: "60px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Assignee
                                    </label>
                                </div>

                            </div>
                            <div className="d-flex ml-3 mb-3" style={{ gap: "60px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Approver
                                    </label>
                                </div>

                            </div>

                        </Card>

                    </div>
                    <div className="w-100">
                        <Card style={{ borderRadius: "15px" }}>
                            <div className="d-flex justify-content-center " style={{ backgroundColor: "#E5EEEE", height: "60px" }}>
                                <p className="mt-0 mb-0 font-size-18 custom-font-600 font-inter">TCS Q4</p>
                            </div>
                            <div className="px-3">
                                <p className="font-size-16 custom-font-600 font-sans">Import</p>
                            </div>
                            <div className="d-flex" style={{ gap: "60px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Tasks
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Templates
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        FSCP Entries
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex ml-3" style={{ gap: "50px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Sub tasks
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input font-sans" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        with amount
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        with amount
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex ml-3" style={{ gap: "60px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Assignee
                                    </label>
                                </div>

                            </div>
                            <div className="d-flex ml-3 mb-3" style={{ gap: "60px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" style={{ width: "23px", height: "23px" }} />
                                    <label className="form-check-label font-sans" for="flexCheckDefault" style={{ marginLeft: "32px", padding: "8px" }}>
                                        Approver
                                    </label>
                                </div>

                            </div>

                        </Card>

                    </div>
                </div>
                <div className="d-flex justify-content-center mt-5 import-btnv1" >
                    <button className="custom-common-theme-bg-color text-white radius-6px font-sans btnv1-imort font-sans ">Import</button>
                </div>
            </div >
        </>
    );
};

export default ImportPreset;
