import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 650,
  bgcolor: "background.paper",
  outline: "none",
  p: 4,
};
const CreateFsModal = (props) => {
  const {
    open,
    handleClose,
    handleChangeValue,
    allCompany,
    selectedIr,
    uploadPreset,
    uploadPresetID,
    handleSubmitFs,
    workspaceData,
    ProjectName,
    fieldModal
  } = props;

    const hash = selectedIr?.reduce((p,c) => (p[c.ir_id] ? p[c.ir_id].push(c) : p[c.ir_id] = [c],p) ,{});
    const newData = Object.keys(hash).map(k => ({ir_id: k, car: hash[k]}));
  const IRName = (ir_id)=>{
      const nameOfType = selectedIr.filter((adj) => adj.ir_id === ir_id);
    const result = nameOfType[0]?.ir_name
      ? nameOfType[0]?.ir_name
      : "NA";
    return result;
  }
  return (
    <>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p style={{ fontSize: "20px", fontWeight: "bold" }}>
                {" "}
                Create consol FS
              </p>
              <p onClick={handleClose} style={{ cursor: "pointer" }}>
                Close
              </p>
            </div>
            <div>
              <span>Enter name of consol</span>
              <input
                style={{ height: "50px" }}
                type="text"
                onChange={(e) => handleChangeValue(e, "consol_name")}
              ></input>
            </div>
            <div className="mt-3">
              <span>Select parent company name</span>
              <select
                style={{
                  height: "50px",
                  width: "100%",
                  border: "1px solid gray",
                  marginTop: "10px",
                  borderRadius: "5px",
                }}
                name="cars"
                id="cars"
                onChange={(e) => handleChangeValue(e, "parent_company")}
              >
                <option>Select...</option>
                {allCompany.length > 0 &&
                  allCompany.map((det) => {
                    return (
                      <>
                        <option value={det._id}>{det.company_name}</option>
                      </>
                    );
                  })}
              </select>
            </div>
            <div className="mt-3">
              <span>Select Investment Register</span>
              <select
                style={{
                  height: "50px",
                  width: "100%",
                  border: "1px solid gray",
                  marginTop: "10px",
                  borderRadius: "5px",
                }}
                name="cars"
                id="cars"
                onChange={(e) => handleChangeValue(e, "link_ir")}
              >
                <option>Select...</option>
                {newData.length > 0 &&
                  newData.map((det) => {
                    return (
                      <>
                        <option value={det.ir_id}>{IRName(det.ir_id)}</option>
                        {/* <option value={det.ir_id}>{det.ir_name}</option> */}

                      </>
                    );
                  })}
              </select>
            </div>
            <div className="mt-3">
              <Button
                style={{
                  backgroundColor: "#03565A",
                  textTransform: "none",
                  width: "228px",
                  height: "32px",
                  color: "white",
                  borderRadius: "10px",
                }}
                variant="contained"
                onClick={uploadPreset}
              >
                Upload Preset
              </Button>
              &nbsp;&nbsp;&nbsp;<small>{ProjectName(uploadPresetID)}</small>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "15px",
              }}
            >
              <div>
                <span>Current financial year</span>
                <input
                  style={{
                    width: "95%",
                    height: "50px",
                    border: "1px solid gray",
                    borderRadius: "5px",
                  }}
                  type="date"
                  //   onChange={(e) => handleChangeValue(e, "c_yr")}
                  value={workspaceData[0]?.console_cy}
                  disabled
                ></input>
              </div>
              <div>
                <span style={{ marginLeft: "10px" }}>
                  Previous financial year
                </span>
                <input
                  style={{
                    width: "97%",
                    height: "50px",
                    border: "1px solid gray",
                    borderRadius: "5px",
                    marginLeft: "10px",
                  }}
                  type="date"
                  value={workspaceData[0]?.console_py}
                  disabled
                  //   onChange={(e) => handleChangeValue(e, "p_yr")}
                ></input>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "40px",
              }}
            >
              {/* {uploadPresetID ? (
                <Button
                  style={{
                    backgroundColor: "#03565A",
                    textTransform: "none",
                    width: "300px",
                    height: "50px",
                    color: "white",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                  onClick={handleSubmitFs}
                >
                  Create consol FS
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundColor: "grey",
                    textTransform: "none",
                    width: "300px",
                    height: "50px",
                    color: "white",
                    borderRadius: "10px",
                  }}
                  variant="contained"
                  onClick={handleSubmitFs}
                >
                  Create consol FS
                </Button>
              )} */}

              <Button
                style={{
                  backgroundColor: "#03565A",
                  textTransform: "none",
                  width: "300px",
                  height: "50px",
                  color: "white",
                  borderRadius: "10px",
                }}
                variant="contained"
                onClick={handleSubmitFs}
              >
                Create consol FS
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default CreateFsModal;
