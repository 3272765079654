import React, { useState, useEffect } from "react";
import { CircularProgress, Tooltip } from "@mui/material";
import TableHeader from "./soccieTableHeader";
import moment from "moment";
import { PYTableData, CYTableData,TotalPYTableData,TotalCYTableData, Opening } from "./soccieTableData";
import {getProjectDetailById} from "../services/apiHandler";
import { useSelector, useDispatch } from "react-redux";
import { fetchSocieData } from "../../../redux/actions/actionSocie";
import { getGroupName, getNoteNumber, getPatternType, getScenario, groupDataCal, manualAutoAdjustment, prepareAdjustmentData, prepareAutoPopulationData } from "../../../helper/calculation";
import { getAllIR } from "../../../WorkSpace/Components/ApiServices/apiHandler";

const NewConsoleSoccie = (props) => {
  const {
    allcheckdata,
    currentCompProjectId,
    subsiaryData,
    currentCompany,
    pnlStatementData,
    headingData,
    groupingData,
    consoleNotes
  } = props;

const dispatch = useDispatch(); 
const getSoccie = useSelector((state)=>state.SoccieReducer);
const AdjData = useSelector((state)=>state.ADJReducer);
const APAdjustment = useSelector((state) => state.APAReducer);
const preAdjustment = useSelector((state)=>state.PADJReducer.response);
const preAdjustmentOther = useSelector((state)=>state.PADJReducer.otherResponse);
const [currentProjectSoccie, setCurrentProjectSoccie] = useState([]);
const [ adjustmentSoccie, setAdjustmentSoccie ] = useState([]);
const [projectIdsSoccie, setProjectIdsSoccie] = useState([]);
const [browseFsSoccie, setBrowseFsSoccie] = useState([]);
const [currentProjectName,setCurrentProjectName] = useState("");
const [ soccieTotalData, setSoccieTotalData ] = useState([]);
const [no, setNo] = useState([]);
const [allIrList, setAllIr] = useState([]);

const currentProjectPnl = pnlStatementData.response.data?.pnl_statement?.pnl_statement ?? [];
const projectIdsPnl= pnlStatementData.response?.data?.project_ids_pnl?.map((data) => data.pnl_statement) ?? [];
const projectFssPnl= pnlStatementData.response?.data?.browse_fs_pnl?.map((data) => data.pnl_statement) ?? [];


  const heading = {
    color: "var(--clr-accent)",
    fontWeight: "700",
  };
  useEffect(()=>{
    if(getSoccie.isSuccess){
        CurrentProjectData();
        ProjectIdsData();
        BrowseFsData();
        getCurrentDataName(currentCompProjectId);
        // OpeningValue();
        AdjustmentSoccieData();
        AllIrData();
    }
  },[getSoccie])


  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
    }
  };
  const FindIr = (ir_id) => {
    const IrData = allIrList.filter((data) => data._id === ir_id);
    return IrData;
  };
  const browse_fs_data = allcheckdata.map((data) => FindIr(data.Ir_id));
  useEffect(() => {
    if (allcheckdata?.length > 0) {
      const browse_fs = allcheckdata.map((data) => FindIr(data.Ir_id));
      const payload = { project_ids:  allcheckdata.map((data) => data.project_id),
         browse_fs_ids:  browse_fs.map(
          (data) => data[0]?.browse_control_dateFS
        ),
           current_comp_project_id: currentCompProjectId}
      if(!getSoccie.isSuccess || getSoccie.payload !== JSON.stringify(payload)){
        dispatch(fetchSocieData(payload));
      }
    }
  }, []);


 
  useEffect(()=>{
    if(currentProjectSoccie.length > 0 && projectIdsSoccie.length > 0 && adjustmentSoccie.length > 0){
      MergeSoccieTable();
    }
  },[currentProjectSoccie,projectIdsSoccie,adjustmentSoccie])

  useEffect(()=>{
    let noArr = [];
    fsRows.map((row, i) => {
      currentProjectPnl[row].map((line, ix) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
    setNo(noArr);
  }, [])

  const getNoteNoIndex = (notes_no) => {
    try {
      let resultIndex = 0;
      Object.keys(consoleNotes).forEach(function(key, index2) {
        let index = consoleNotes[key].find(data => data.note_number === notes_no);
        if(index !== undefined){
          resultIndex = index.index;
        }
      });
      return resultIndex
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }

  const MergeSoccieTable = () => {
    try {
      const finalResult = []
        currentProjectSoccie.forEach((dataset, index)=> {
          const keyData = {};
          Object.keys(dataset).forEach((key, index2)=>{
              dataset[key].forEach((item, index3)=>{
                  if(keyData[key] === undefined){
                    keyData[key] = [];
                  }
                  const newData = {};
                  Object.keys(item).forEach((keyname)=>{
                      if(keyname === "row_header"){
                      newData[keyname] = item[keyname]
                      }else if(keyname === "sub_grp"){
                        newData[keyname] = item[keyname]
                      }
                      else{
                        newData[keyname] = (Number(item[keyname]) + (adjustmentSoccie[index][key][index3][keyname] || 0) + projectIdsSoccie.map((item2)=>Number(item2[index][key][index3][keyname])).reduce((num, acc)=>num + acc, 0))     
                      }
                  })
                  keyData[key].push(newData);
              })
          })
          finalResult.push(keyData)
        })
      setSoccieTotalData(finalResult);
    } catch (error) {
      console.log("err merge", error.message)
    }
  } 


  const CurrentProjectData = () => {
    const currentProjectSoccie = getSoccie.response?.data?.curent_comp_project?.socie_list ?? [];
        setCurrentProjectSoccie(currentProjectSoccie);
        try {
            const previousYearData = currentProjectSoccie[0].previous_row_header.map((data)=>{
              if(data.row_header === "Profit for the year"){
                  return {...data, "Retained Earnings": PreviousYearProfit()}
              }return {...data}
            })
            const currentYearData = currentProjectSoccie[0].current_row_header.map((data)=>{
              if(data.row_header === "Profit for the year"){
                  return {...data, "Retained Earnings": currentYearProfit()}
              } else if(data.row_header === "Balance at the beginning of the previous reporting period"){
                Object.keys(data).map((keyname, idx)=>{
                  if(keyname !== "row_header"){
                    data[keyname] = BalAtEndOfPrevReportPy(currentProjectSoccie[0], keyname, false, false)
                  }
                })
                return {...data}
              }
              return {...data}
            })
            currentProjectSoccie[0].previous_row_header=previousYearData;
            currentProjectSoccie[0].current_row_header=currentYearData;
            setCurrentProjectSoccie(currentProjectSoccie);
        } catch (error) {
            console.log("err", error)
        }
  }

  const ProjectIdsData = () => {
    const projectIdsSoccie = getSoccie.response?.data?.final_balance_sheet.flat().map((data) => data.socie_list) ?? [];
    setProjectIdsSoccie(projectIdsSoccie);
    try {
        const projectSoccie = projectIdsSoccie.map((projectSoccie, index)=> ProjectIdTotalProfit(projectSoccie, index))
        setProjectIdsSoccie(projectSoccie);
    } catch (error) {
        console.log("err", error.message)
    }
  }

  const BrowseFsData = () => {
    const browseFsSoccie = getSoccie.response?.data?.browse_fs_socie?.flat().map((data) => data.socie_list) ?? [];
    setBrowseFsSoccie(browseFsSoccie);
  }

  const AdjustmentSoccieData = () => {
    const current_project_soccie = getSoccie.response?.data?.curent_comp_project?.socie_list ?? [];
    const adjSoccieRaw = JSON.parse(JSON.stringify(current_project_soccie));   //deep copy implemented
    setAdjustmentSoccie(adjSoccieRaw);
    try {
      const previousYearData = adjSoccieRaw[0].previous_row_header.map((data)=>{
        if(data["row_header"] === "Balance at the beginning of the previous reporting period"){
         Object.keys(data).map((keyname, index) => {
          if(keyname !== 'row_header'){
            data[keyname] = socieAdjustmentOpening(keyname);
          }
         })
         return data
        }else{
          return {...socieAdjustment(data)}
        }
    })
    const currentYearData = adjSoccieRaw[0].current_row_header.map((data)=>{
      if(data.row_header === "Balance at the beginning of the previous reporting period"){
        Object.keys(data).map((keyname, idx)=>{
          if(keyname !== "row_header"){
            data[keyname] = BalAtEndOfPrevReportPy(currentProjectSoccie[0], keyname, false, true)
          }
        })
        return {...data}
      }
      return {...socieAdjustment(data, "cy")}        
    })
    adjSoccieRaw[0].previous_row_header=previousYearData;
    adjSoccieRaw[0].current_row_header=currentYearData;
    setAdjustmentSoccie(adjSoccieRaw);
    } catch (error) {
      console.log("err adjSoccie", error.message)
    }
  }

  const socieAdjustment = (soccie, type="py") => {
    let year_status = (type==="py") ? "previous_yr" : "current_yr";
    let AdjustmentData = AdjData.response.getConsoleAdjustment.filter(ele => ele.status === year_status);
      let adj_result = AdjustmentData.map(ele => ele.adjustment_other_data.map(itm => {if(itm.hasOwnProperty("socie_name")){return itm}})).flat().filter(data => data != undefined);
      let result_data = adj_result.map(data => { if(data.socie_name === soccie["row_header"]){
        return {ledger_name: data.ledger_name,adjustment: data.adjustment_value};
      }else{
        return {ledger_name: null, adjustment: null}
      }
    })
    Object.keys(soccie).map((keyname, index) => {
      if(keyname !== "row_header"){
        let adj_data_ = result_data.find(adj => adj.ledger_name!== null && adj.ledger_name.toLowerCase().includes(keyname.toLowerCase()));
        if(adj_data_){
          soccie[keyname] = adj_data_.adjustment * -1;
        }else{
          soccie[keyname] = 0
        }
      }
    })
    return soccie
  }

  const socieAdjustmentOpening = (keyname) => {
    try{
      let note_no = (keyname === "Share application money pending allotment")? "35" : "36";
      const {BrowsFsData} = groupDataCal(groupingData,note_no);
      let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes(keyname.toLowerCase()));
      if(filterData){
        const adj_result = adjustmentIr(filterData.group, 3, BrowsFsData);
        return adj_result;
      }else if(keyname === "Money received against share warrants"){
        let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes("Money received against share warrant".toLowerCase()));
        if(filterData){
          const adj_result = adjustmentIr(filterData.group, 3, BrowsFsData)
          return adj_result;
        }else{
          return 0
        }
      }else if(keyname === "Equity component of compounded financial instruments"){
        let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes("Equity component of compound financial instruments".toLowerCase()));
        if(filterData){
          const adj_result = adjustmentIr(filterData.group, 3, BrowsFsData)
          return adj_result;
        }else{
          return 0
        }
      }
      return 0      
    }catch(err){
      console.log("err", err.message)
      return 0
    }
    
  }

  const ProjectIdTotalProfit = (projectSoccie, pIndex) => {
    const data_type_1 = "Debt instruments through Other Comprehensive Income";
    const data_type_2 = "Equity Instruments through Other Comprehensive Income";
    const data_type_3 = "Effective portion of Cash Flow Hedges";
    const data_type_4 = "Revaluation Surplus";
    const data_type_5 = "Exchange differences on translating the financial statements of a foreign operation";
    try {
        const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData, pIndex);
        const previousYearData = projectSoccie[0].previous_row_header.map((data)=>{
          if(first_scenario){
            Object.keys(data).map((keyname, index) => {
              if(keyname !== "row_header"){
                data[keyname] = 0
              }
            })
            return {...data}
          }else if(second_scenario){
            if(data.row_header === "Profit for the year"){
              return {...data, "Retained Earnings": ProjectIdCurrentYearProfit(pIndex)}
            }else if(data.row_header === "Other comprehensive income"){
            return {
              ...data, 
              [data_type_1]: getPnlValue(pIndex, 64, "Fair value changes in Debt Instruments through other comprehensive income", "py_amt"),
              [data_type_2]: getPnlValue(pIndex, 63, "Fair value changes on Equity Instruments through other comprehensive income", "py_amt"),
              [data_type_3]: getPnlValue(pIndex, 64, "The effective portion of gain and loss on hedging instruments in a cash flow hedge", "py_amt"),
              [data_type_4]: getPnlValue(pIndex, 63, "Changes in revaluation surplus", "py_amt"),
              [data_type_5]: getPnlValue(pIndex, 64, "Exchange differences in translating the financial statements of a foreign operation", "py_amt"),
              ["Retained Earnings"] : 0
            }
          }
            return {...data}
          }else{
            if(data.row_header === "Profit for the year"){
              return {...data, "Retained Earnings": ProjectIdPreviousYearProfit(pIndex)}
            }else if(data.row_header === "Any other change (to be specified)"){
              Object.keys(data).map((keyname, index) => {
                if(keyname !== "row_header"){
                  data[keyname] = 0;
                }
              })
              return {...data}
            }
          }          
        })
        const currentYearData =  projectSoccie[0].current_row_header.map((data, cIndex)=>{
          if(second_scenario){
            if(data.row_header === "Balance at the beginning of the previous reporting period"){
              Object.keys(data).map((keyname, index) => {
                if(keyname !== "row_header"){
                  data[keyname] = BalAtEndOfPrevReportPy(currentProjectSoccie[0], keyname, true, false);
                }
              })
              return {...data}
            }
          }
          if(first_scenario){
            if(data.row_header === "Balance at the beginning of the previous reporting period"){
              Object.keys(data).map((keyname, index) => {
                if(keyname !== "row_header"){
                  data[keyname] = 0
                }
              })
              return {...data}
            }
          }
          if(data.row_header === "Any other change (to be specified)"){
            Object.keys(data).map((keyname, index) => {
              if(keyname !== "row_header"){
                data[keyname] = 0
              }
            })
            return {...data}
          }
          if(data.row_header === "Profit for the year"){
              return {...data, "Retained Earnings": ProjectIdCurrentYearProfit(pIndex)}
          }else if(data.row_header === "Other comprehensive income"){
            return {
              ...data, 
              [data_type_1]: getPnlValue(pIndex, 64, "Fair value changes in Debt Instruments through other comprehensive income"),
              [data_type_2]: getPnlValue(pIndex, 63, "Fair value changes on Equity Instruments through other comprehensive income"),
              [data_type_3]: getPnlValue(pIndex, 64, "The effective portion of gain and loss on hedging instruments in a cash flow hedge"),
              [data_type_4]: getPnlValue(pIndex, 63, "Changes in revaluation surplus"),
              [data_type_5]: getPnlValue(pIndex, 64, "Exchange differences in translating the financial statements of a foreign operation"),
              ["Retained Earnings"] : 0
            }
          }
          return {...data}
        })
        projectSoccie[0].previous_row_header=previousYearData;
        projectSoccie[0].current_row_header=currentYearData;
        return projectSoccie;
    } catch (error) {
        console.log("err", error)
        return 0
    }
  }

  const getCurrentDataName=async(currentCompProjectId)=>{
    const res = await getProjectDetailById(currentCompProjectId);
    setCurrentProjectName(res.data.project.project_name);
  }


  const rows = pnlStatementData.response?.data?.pnl_statement?.pnl_statement ?? [];
  const fsRows = Object.keys(rows);

  function sum(obj) {
    delete obj["_id"];
    delete obj["row_header"];
    return Object.keys(obj).reduce(
      (sum, key) => sum + parseInt(obj[key] || 0),
      0
    );
  }

  const objectData = (socie_data) => {
    const res =
      socie_data.response?.data?.curent_comp_project?.socie_list[0]?.previous_row_header.map(
        (data, key) => sum(Object.assign({}, data))
      );
    return res;
  };

  const RestatedBalanceCalPy = (soccieData, previous_row_index, soccie_key) => {
    try {
      return soccieData.previous_row_header.slice(0, previous_row_index).map((data)=> Number(data[soccie_key])).reduce((data, num)=>data+num, 0)
    } catch (error) {
      console.log("error",error.message)
      return 0
    }
  }

  const RestatedBalanceCalCy = (soccieData, current_row_index, soccie_key)=>{
    try {
      return soccieData.current_row_header.slice(0, current_row_index).map((data)=> Number(data[soccie_key])).reduce((data, num)=>data+num, 0)
    } catch (error) {
      console.log("error",error.message)
      return 0
    }
  }

  const TotalRestatedBalanceCalPy = (prIndex) => {
    try {
      const soccieTotalRBP = soccieTotalData[0].previous_row_header.map((soccie, pr_index)=>{
        if(pr_index === prIndex){
          return Object.keys(soccie).map((keyname, soccie_index) => {
            if(keyname !== "row_header" && keyname !== "sub_grp"){
              return RestatedBalanceCalPy(soccieTotalData[0], pr_index, keyname)
            }else{
              return 0
            }
          })
        }else{
          return 0
        }
      })
      return (soccieTotalRBP.flat().reduce((data, num) => data + num, 0));
    } catch (error) {
      console.log("err", error.message)
      return "--"
    }
  
  }

  const TotalRestatedBalanceCalCy = (crIndex) => {
    try {
      const soccieTotalRBC = soccieTotalData[0].current_row_header.map((soccie, cr_index)=>{
        if(cr_index === crIndex){
          return Object.keys(soccie).map((keyname, soccie_index) => {
            if(keyname !== "row_header" && keyname !== "sub_grp"){
              return RestatedBalanceCalCy(soccieTotalData[0], cr_index, keyname)
            }else{
              return 0
            }
          })
        }else{
          return 0
        }
      })
      return (soccieTotalRBC.flat().reduce((data, num) => data + num, 0));
    } catch (error) {
      console.log("err", error.message)
      return "--"
    }
  
  }

  const TotalRowCalPy = (prIndex) => {
    try {
      const totalRowValue = soccieTotalData[0].previous_row_header.map((soccie, pr_index) =>  {
        if(pr_index === prIndex){
          return Object.keys(soccie).map((keyname, soccie_index) => {
            if(keyname !== "row_header" && keyname !== "sub_grp"){
              return Number(soccie[keyname])
            }else{
              return 0
            }
          })
        }else{
          return 0
        }
      })
      return (totalRowValue.flat().reduce((data, num) => data + num, 0));
    } catch (error) {
      console.log("TotalRowCalPy err", error.message);
      return "--"
    }
  }

  const TotalTableTotalCalPy = (soccieData, previous_row_index, soccie_key, project_index=false, adjustment=false) => {
   try {
    const projectIdTotalPy = projectIdsSoccie.map((SoccieProject,project_index_) => TotalCalPy(SoccieProject[0], previous_row_index, soccie_key, project_index_, false)).reduce((data, num)=> data + num, 0);
    const adjustmentTotalPy = TotalCalPy(adjustmentSoccie[0], previous_row_index, soccie_key, false, true);
      return (projectIdTotalPy + adjustmentTotalPy);
   } catch (error) {
    console.error("totalTable", error.message)
    return 0
   }
  }

  const TotalOfTotalPy = (prIndex) => {
    try {
      const soccieData = soccieTotalData[0].previous_row_header[prIndex];
      const finalResult = Object.keys(soccieData).map((keyname, index) => {
        if(keyname !== 'row_header' && keyname !== 'sub_grp')return Number(TotalTableTotalCalPy(soccieData, prIndex, keyname, false, false));
        else return 0
      }).reduce((data, num)=> data + num, 0)
      return finalResult
    } catch (error) {
      console.log("TotalOfTotalPy err", error.message);
      return 0
    }
  } 


  const TotalTableTotalCalCy = (soccieData, current_row_index, soccie_key, project_index=false, adjustment=false) => {
    try {
     const projectIdTotalCy = projectIdsSoccie.map((SoccieProject,project_index_) => TotalCalCy(SoccieProject[0], current_row_index, soccie_key, project_index_, false)).reduce((data, num)=> data + num, 0);
     const adjustmentTotalCy = TotalCalCy(adjustmentSoccie[0], current_row_index, soccie_key, false, true);
      return (projectIdTotalCy + adjustmentTotalCy);
    } catch (error) {
     console.error("totalTable", error.message)
     return 0
    }
   }

  const TotalCalCy=(soccieData, current_row_index, soccie_key, project_index=false, adjustment=false)=>{
    try {
        const result = soccieData.current_row_header.slice(4, current_row_index).map((data)=> Number(data[soccie_key])).reduce((data, num)=>data+num, 0);
        if(project_index !== false && project_index !== undefined){
          return result + AcquisitionSubsidiaryCy(project_index, soccie_key);
        }else if(adjustment != false && adjustment !== undefined){
          return result + AcquisitionAdjustmentCy(soccie_key);
        }
        return result;
    } catch (error) {
        console.log("error",error.message)
        return 0
    }
  }
  
  const TotalRowCalCy = (prIndex) => {
    try {
      const totalRowValue = soccieTotalData[0].current_row_header.map((soccie, pr_index) =>  {
        if(pr_index === prIndex){
          return Object.keys(soccie).map((keyname, soccie_index) => {
            if(keyname !== "row_header" && keyname !== "sub_grp"){
              return Number(soccie[keyname])
            }else{
              return 0
            }
          })
        }else{
          return 0
        }
      })
      return (totalRowValue.flat().reduce((data, num) => data + num, 0));
    } catch (error) {
      console.log("TotalRowCalCy err", error.message);
      return "--"
    }
  }

  const TotalCalPy = (soccieData, previous_row_index, soccie_key, project_index=false, adjustment=false)=>{
    try {
      const result = soccieData.previous_row_header.slice(4, previous_row_index).map((data)=> Number(data[soccie_key])).reduce((data, num)=>data+num, 0)
        if(project_index !== false && project_index !==undefined){
          return Number(result) + AcquisitionSubsidiaryPy(project_index, soccie_key)
        } else if(adjustment !== false && adjustment !==undefined){
          return Number(result) + AcquisitionAdjustmentPy(soccie_key)
        }
      return result
    } catch (error) {
        console.log("error",error.message)
        return 0
    }
  }


  const TotalOfTotalCy = (crIndex) => {
    try {
      const soccieData = soccieTotalData[0].current_row_header[crIndex];
      const finalResult = Object.keys(soccieData).map((keyname, index) => {
        if(keyname !== 'row_header' && keyname !== 'sub_grp')return Number(TotalTableTotalCalCy(soccieData, crIndex, keyname, false, false));
        else return 0
      }).reduce((data, num)=> data + num, 0)
      return finalResult
    } catch (error) {
      console.log("TotalOfTotalCy err", error.message);
      return 0
    }
  } 

  const ComprehensiveCalPy = (soccieData, previous_row_index, soccie_key)=>{
    try {
        return soccieData.previous_row_header.slice(2, previous_row_index).map((data)=> Number(data[soccie_key])).reduce((data, num)=>data+num, 0)
    } catch (error) {
        console.log("error",error.message)
        return 0
    }
  }

  const ComprehensiveCalCy = (soccieData, current_row_index, soccie_key)=>{
    try {
        return soccieData.current_row_header.slice(2, current_row_index).map((data)=> Number(data[soccie_key])).reduce((data, num)=>data+num, 0)
    } catch (error) {
        console.log("error",error.message)
        return 0
    }
  }

  const TotalComprehensiveCalPy = (prIndex) => {
    try {
      const totalComprehensiveSocie = soccieTotalData[0].previous_row_header.map((soccie, pr_index) =>  {
        if(pr_index === prIndex){
          return Object.keys(soccie).map((keyname, soccie_index) => {
            if(keyname !== "row_header" && keyname !== "sub_grp"){
              return ComprehensiveCalPy(soccieTotalData[0], pr_index, keyname)
            }else{
              return 0
            }
          })
        }else{
          return 0
        }
      })
      return (totalComprehensiveSocie.flat().reduce((data, num) => data + num, 0));
    } catch (error) {
      console.log("err", error.message)
      return "--"
    }
  
  }

  const TotalComprehensiveCalCy = (crIndex) => {
    try {
      const totalComprehensiveSocie = soccieTotalData[0].current_row_header.map((soccie, cr_index) =>  {
        if(cr_index === crIndex){
          return Object.keys(soccie).map((keyname, soccie_index) => {
            if(keyname !== "row_header" && keyname !== "sub_grp"){
              return ComprehensiveCalPy(soccieTotalData[0], cr_index, keyname)
            }else{
              return 0
            }
          })
        }else{
          return 0
        }
      })
      return (totalComprehensiveSocie.flat().reduce((data, num) => data + num, 0));
    } catch (error) {
      console.log("err", error.message)
      return "--"
    }
  
  }

  const TotalAcquisitionSubsidiaryPy = (project_index, keyname, adjustment=false) => {
    try {
      const projectIdAcquisition = projectIdsSoccie.map((data,project_index_) => AcquisitionSubsidiaryPy(project_index_, keyname)).reduce((data, num)=> data + num, 0);
      const adjustmentAcquisition = AcquisitionSubsidiaryPy(undefined, keyname, true);
      return (projectIdAcquisition + adjustmentAcquisition)
    } catch (error) {
      console.log("error", error.message)
      return "fail.."
    }
  }

  const TotalAcquisitionSubsidiaryCy = (project_index, keyname, adjustment=false) => {
    try {
      const projectIdAcquisition = projectIdsSoccie.map((data,project_index_) => AcquisitionSubsidiaryCy(project_index_, keyname)).reduce((data, num)=> data + num, 0);
      const adjustmentAcquisition = AcquisitionSubsidiaryCy(undefined, keyname, true);
      return (projectIdAcquisition + adjustmentAcquisition)
    } catch (error) {
      console.log("error", error.message)
      return "fail.."
    }
  }
  
  const GrossAcquisitionSubsidiaryPy = () => {
    try {
      const soccieData = soccieTotalData[0].previous_row_header[4];
      const finalResult = Object.keys(soccieData).map((keyname, index) => {
        if(keyname !== 'row_header' && keyname !== 'sub_grp')return Number(TotalAcquisitionSubsidiaryPy(undefined, keyname, false));
        else return 0
      }).reduce((data, num)=> data + num, 0)
      return finalResult
    return "..."
    } catch (error) {
      console.log("err", error.message)
      return "..."
    }
  }

  const GrossAcquisitionSubsidiaryCy = () => {
    try {
      const soccieData = soccieTotalData[0].current_row_header[4];
      const finalResult = Object.keys(soccieData).map((keyname, index) => {
        if(keyname !== 'row_header' && keyname !== 'sub_grp')return Number(TotalAcquisitionSubsidiaryCy(undefined, keyname, false));
        else return 0
      }).reduce((data, num)=> data + num, 0)
      return (finalResult)
    } catch (error) {
      console.log("err", error.message)
      return "..."
    }
  }

  const AcquisitionSubsidiaryPy = (project_index, keyname, adjustment=false) => {
    try {
      if(adjustment) return Number(AcquisitionAdjustmentPy(keyname));
      const row_data = "Balance at the beginning of the previous reporting period";
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData, project_index);
      if(second_scenario){
        const BrowseData = browseFsSoccie[project_index][0].previous_row_header.find(data=>data.row_header === row_data);
        if(BrowseData){
          return Number(BrowseData[keyname])
        }else{
          return 0
        }
      }else{
        return 0
      }
    } catch (error) {
      console.log("err", error.message)
      return 0
    }
  }

  const AcquisitionSubsidiaryCy = (project_index, keyname, adjustment=false) => {
    try {
      if(adjustment) return Number(AcquisitionAdjustmentCy(keyname));
      const row_data = "Balance at the beginning of the previous reporting period";
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData, project_index);
      if(first_scenario){
        const BrowseData = browseFsSoccie[project_index][0].current_row_header.find(data=>data.row_header === row_data);
        if(BrowseData){
          return Number(BrowseData[keyname])
        }else{
          return 0
        }
      }else{
        return 0
      }
    } catch (error) {
      console.log("err", error.message)
      return 0
    }
  }

  const AcquisitionAdjustmentPy = (keyname) => {
    try {
      let note_no = (keyname === "Share application money pending allotment")? "35" : "36";
      const {BrowsFsData} = groupDataCal(groupingData,note_no);
      let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes(keyname.toLowerCase()));
      if(filterData){
        const adj_result = adjustmentIr(filterData.group, 2, BrowsFsData);
        return adj_result;
      }else if(keyname === "Money received against share warrants"){
        let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes("Money received against share warrant".toLowerCase()));
        if(filterData){
          const adj_result = adjustmentIr(filterData.group, 2, BrowsFsData)
          return adj_result;
        }else{
          return 0
        }
      }else if(keyname === "Equity component of compounded financial instruments"){
        let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes("Equity component of compound financial instruments".toLowerCase()));
        if(filterData){
          const adj_result = adjustmentIr(filterData.group, 2, BrowsFsData)
          return adj_result;
        }else{
          return 0
        }
      }
      return 0
    } catch (error) {
      console.log("err", error.message);
     return 0 
    }
  }

  const AcquisitionAdjustmentCy = (keyname) => {
    try {
      let note_no = (keyname === "Share application money pending allotment")? "35" : "36";
      const {BrowsFsData} = groupDataCal(groupingData,note_no);
      let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes(keyname.toLowerCase()));
      if(filterData){
        const adj_result = adjustmentIr(filterData.group, 1, BrowsFsData)
        return adj_result;
      }else if(keyname === "Money received against share warrants"){
        let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes("Money received against share warrant".toLowerCase()));
        if(filterData){
          const adj_result = adjustmentIr(filterData.group, 1, BrowsFsData)
          return adj_result;
        }else{
          return 0
        }
      }else if(keyname === "Equity component of compounded financial instruments"){
        let filterData = BrowsFsData[0].flat().find(ele => ele.group.toLowerCase().includes("Equity component of compound financial instruments".toLowerCase()));
        if(filterData){
          const adj_result = adjustmentIr(filterData.group, 1, BrowsFsData)
          return adj_result;
        }else{
          return 0
        }
      }
      console.log("keyname", keyname)
      return 0
    } catch (error) {
      console.log("err", error.message);
     return 0 
    }
  }

  const CurrentProjectCy = (group_name, total_tax=false) => {
    if(group_name === "Expenses"){
      let res = 0;
      if(total_tax){
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp === "Current tax" || data.notes_grp ==="Deferred tax")
      }else{
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp !== "Current tax" && data.notes_grp !=="Deferred tax")
      } 
      return res.map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
    }
    return currentProjectPnl[group_name].map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
  }
  
  const CurrentProjectPy = (group_name, total_tax=false) => {
    if(group_name === "Expenses"){
      let res = 0;
      if(total_tax){
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp === "Current tax" || data.notes_grp ==="Deferred tax")
      }else{
        res = currentProjectPnl[group_name].filter((data) => data.notes_grp !== "Current tax" && data.notes_grp !=="Deferred tax")
      } 
      return res.map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
    }
    return currentProjectPnl[group_name].map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
  }

  const PreviousYearProfit = () => {
    try {
        const profitBeforeExceptionalItem = (CurrentProjectPy("Income") - CurrentProjectPy("Expenses"));
        const TotalTax = CurrentProjectPy("Expenses", true)
        return (profitBeforeExceptionalItem - TotalTax)
    } catch (error) {
        console.log("err previouYearProfit", error.message);
        return "-"
    }
  }

  const currentYearProfit = () => {
    try {
        const profitBeforeExceptionalItem = (CurrentProjectCy("Income") - CurrentProjectCy("Expenses"));
        const TotalTax = CurrentProjectCy("Expenses", true)
        return (profitBeforeExceptionalItem - TotalTax)
    } catch (error) {
        console.log("err previouYearProfit", error.message);
        return "-"
    }
  }

  const ProjectIDCy = (pnl_statement, group_name, project_index, total_tax=false) => {
    const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData, project_index);
    let result = 0;
    if(group_name === "Expenses"){
      let res = 0;
      if(total_tax){
        res = pnl_statement[group_name].filter((data) => data.notes_grp === "Current tax" || data.notes_grp ==="Deferred tax")
      }else{
        res = pnl_statement[group_name].filter((data) => data.notes_grp !== "Current tax" && data.notes_grp !=="Deferred tax")
      }
      result = res.map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
    }else{
      result = pnl_statement[group_name].map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
    }
    if (first_scenario) {
      return result;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  }

  const ProjectIDPy = (pnl_statement, group_name, project_index, total_tax=false) => {
    const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData,project_index);
    let result = 0;
    if(group_name === "Expenses"){
      let res = 0;
      if(total_tax){
        res = pnl_statement[group_name].filter((data) => data.notes_grp === "Current tax" || data.notes_grp ==="Deferred tax")
      }else{
        res = pnl_statement[group_name].filter((data) => data.notes_grp !== "Current tax" && data.notes_grp !=="Deferred tax")
      }
      result = res.map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
    }else{
      result = pnl_statement[group_name].map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
    }
    if (first_scenario) {
    //   return 0;
      return result;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  }

  const getProjectCy = (BrowsFsData, project_data, sub_index) => {
    try {
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData, sub_index);
      const projectCy = project_data.cy_amt ?? 0;
      const browseFsCy = BrowsFsData.map(browse_item => {
        if(browse_item.group === project_data.group){
          return browse_item.cy_amt;
        }else{
          return 0;
        }
      }).reduce((num, acc)=> num + acc, 0);
      if(first_scenario){
        return projectCy - browseFsCy;
      }else if(second_scenario){
        return projectCy;
      }else{
        return projectCy
      }
    } catch (error) {
      
    }
  }

  const ProjectIdPnlCy = (sub_index, note_group, note_index, total_tax=false) => {
    try {
      if(note_group){
        let note_no = getNoteNumber(consoleNotes, note_index);
        if(note_no === "--"){return "...";}
        const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
        const result = Projects[sub_index].flat().map((project_data) => {
          return getProjectCy(BrowsFsData[sub_index].flat(), project_data, sub_index);
        }).reduce((num, acc)=> num + acc, 0);
        return result;
      }
      return "loading..."
    } catch (error) {
      console.log("error project pnl", error.message)
      return '00'
    }
  }

  const grandTotalProjectPnlCy = (group, sub_index, total_tax=false) => {
    if(group === "Income" || group === "Exceptional items" || group === "Profit / (loss) from discontinued operations" || group === "Other Comprehensive Income"){
      const result = currentProjectPnl[group].map((noteGroup)=>{
        return ProjectIdPnlCy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
      }).reduce((num, acc)=>num + acc, 0);
      return result;
    }else if(group === "Expenses"){
      const result = currentProjectPnl[group].map((noteGroup)=>{
        if(total_tax){
          if(noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax"){
            return ProjectIdPnlCy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
          }else{
            return 0;
          }
        }else{
          if(noteGroup.notes_grp === "Current tax" || noteGroup.notes_grp === "Deferred tax"){
            return 0;
          }else{
            return ProjectIdPnlCy(sub_index, noteGroup.notes_grp, getNoteNoIndex(noteGroup.notes_no))
          }
        }
        
      }).reduce((num, acc)=>num + acc, 0);
      return result;
    }
    return "loading..."
  }

  const ProjectIdPreviousYearProfit = (project_index) => {
    try{
        const project_pnl = projectIdsPnl[project_index];
        const profitBeforeExceptionalItem = (ProjectIDPy(project_pnl, "Income", project_index) - ProjectIDPy(project_pnl, "Expenses", project_index));
        const TotalTax = ProjectIDPy(project_pnl, "Expenses", project_index, true)
        return (profitBeforeExceptionalItem - TotalTax)
    }catch(error){
        console.log("ErrProjectIdPreviousYearProfit", error.message)
        return 0
    }
    
  }

  const ProjectIdCurrentYearProfit = (project_index) => {
    try{
      return ((grandTotalProjectPnlCy("Income", project_index) - grandTotalProjectPnlCy("Expenses", project_index))-(grandTotalProjectPnlCy("Expenses", project_index, true)));
    }catch(error){
        console.log("ErrProjectIdPreviousYearProfit", error.message)
        return 0
    }
  }
  const OpeningValue = ()=>{
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => { return {
      scenario: getScenario(currentCompany, subsiaryData,sub_index),
      brows_fs:groupingData?.response?.data?.browse_fs_grouping[sub_index]?.fs_grp[9]?.notes_grp[2]?.sub_grp[0],
      brows_fs_equity:groupingData?.response?.data.browse_fs_grouping[sub_index]?.fs_grp[9]?.notes_grp[3]?.sub_grp,

   }});
   const browsDataRes = scenarioResult.filter(data=>data.brows_fs_equity !== undefined);
  }

  const getPnlValue = (project_index, note_index, group, key="cy_amt") => {
    try {
      let note_no = getNoteNumber(consoleNotes, note_index);
      if(note_no === "--"){return "...";}
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData,project_index);
      const { Projects, BrowsFsData } = groupDataCal(groupingData, note_no);
      const project_data = Projects[project_index].flat().find(item => item.group === group)[key] ?? 0;
      const brows_fs_data = BrowsFsData[project_index].flat().find(item => item.group === group)[key] ?? 0;
      if(first_scenario){
        return (brows_fs_data - project_data);
      }else if(second_scenario){
        if(key === "py_amt"){
          return (brows_fs_data - project_data);
        }
        return project_data;
      }else{
        return project_data;
      }
    } catch (error) {
      console.error("pnl err", error)
      return 0
    }
    
  }

  const adjustmentIr = (group_name,scenario,BrowsFsData) =>{
    try {
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => { return {
        scenario: getScenario(currentCompany,subsiaryData,sub_index),
        brows_fs: BrowsFsData[sub_index].flat().find(ele => ele.group === group_name)
      }});
      const browsDataRes = scenarioResult.filter(data=>data.brows_fs !== undefined);
      const browsDataRes_other = scenarioResult.filter(data=>data);
      if(browsDataRes.length === 0){
        return 0
      }
      return getPreAdjustment(scenarioResult, browsDataRes, browsDataRes_other, scenario);
    } catch (error) {
      console.log("err",error.message);
      return 0
    }
  }

  const getPreAdjustment = (scenarioResult, browsDataRes, browsDataRes_other, scenario) => {
      let first_scenario_data = 0;
      let second_scenario_data = 0;
      let third_scenario_data = 0;
      if(preAdjustment.includes(browsDataRes[0].brows_fs.group)){
        const first_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario === true);
        const first_result =  first_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
        first_scenario_data += first_result;
      
        const second_scenario_res = scenarioResult.filter((data)=>data.scenario.second_scenario === true);
        const second_result = second_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
        second_scenario_data += second_result;
        first_scenario_data += second_result;
      
        const third_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
        const third_result = third_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
        third_scenario_data += third_result;
        second_scenario_data += third_result;
        first_scenario_data += third_result;
      }else if (preAdjustmentOther.map(item=>item.sub_group).includes(browsDataRes[0].brows_fs.group)){
        const cal_data = preAdjustmentOther.find(item=>item.sub_group === browsDataRes[0].brows_fs.group).cal_data;
        const first_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario === true);
        const first_result = first_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
        first_scenario_data += first_result;
      
        const second_scenario_res = scenarioResult.filter((data)=>data.scenario.second_scenario === true);
        const second_result = second_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
        second_scenario_data += second_result;
        first_scenario_data += second_result;
      
        const third_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
        const third_result = third_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
        third_scenario_data += third_result;
        second_scenario_data += third_result;
        first_scenario_data += third_result;
      }
      switch(scenario){
        case 1:{
          if(browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted"){
            return first_scenario_data;
            }
            else{
              return first_scenario_data * -1;
            }
        }
        case 2:{
          if(browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted"){
              return second_scenario_data;
            }else{
              return second_scenario_data * -1;
            }
        }          
        case 3:{
          if(browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted"){
              return third_scenario_data;
            }else{
              return third_scenario_data * -1;;
            }
        }
        default:
          return 0
      }
  }

  const BalAtEndOfPrevReportPy = (SoccieProject, keyname, project_index, showAdjustment) => {
    try {
      return (Number(RestatedBalanceCalPy(SoccieProject, 2, keyname)) +
        Number(ComprehensiveCalPy(SoccieProject, 4, keyname)) +
        Number(TotalCalPy(SoccieProject, 9, keyname, project_index, showAdjustment))
      )
    } catch (error) {
      console.log("err BalAtEndOfPrevReportPy", error.message)
      return 0
    }
  }
  return (
    <>
      {getSoccie.loading ? (
        <CircularProgress disableShrink />
      ) : getSoccie.isSuccess ? (
        <div class="row">
          <div
            className="col"
            style={{ whiteSpace: "nowrap", }}
          >
              
            <div style={{display:"flex"}}>
            <div style={{ marginLeft: "1rem" }}>
                <p style={heading}>{currentProjectName}</p>
            <table style={{ marginLeft:"10px", display:"inline-block", width:"auto"}}>
              <TableHeader showTotal={false}/>
              <tbody>
                <div style={{ marginLeft: "1rem" }}>
                  <p style={heading}>Previous Period</p>
                </div>
                {currentProjectSoccie[0]?.previous_row_header.map((soccie, pr_index) => (
                  <PYTableData 
                    soccie={soccie}
                    pr_index={pr_index} 
                    SoccieProject={currentProjectSoccie[0]} 
                    RestatedBalanceCalPy={RestatedBalanceCalPy} 
                    ComprehensiveCalPy={ComprehensiveCalPy}
                    TotalCalPy={TotalCalPy}
                    showTotal={false}
                    showAdjustment = {false}
                    BalAtEndOfPrevReportPy={BalAtEndOfPrevReportPy}
                  />
                ))}

                {/* current year */}
                <div style={{ marginLeft: "1rem" }}>
                  <p style={heading}>Current Period</p>
                </div>
                {currentProjectSoccie[0]?.current_row_header.map((soccie, cr_index) => (
                  <CYTableData 
                    soccie={soccie}
                    cr_index={cr_index} 
                    SoccieProject={currentProjectSoccie[0]} 
                    RestatedBalanceCalCy={RestatedBalanceCalCy} 
                    ComprehensiveCalCy={ComprehensiveCalCy}
                    TotalCalCy={TotalCalCy}
                    showTotal={false}
                    showAdjustment = {false}
                  />
                ))}
              </tbody>
            </table>
            </div>

            { projectIdsSoccie.length > 0 && projectIdsSoccie.map((projectSocie, project_index)=>(
              <>
              <div style={{ marginLeft: "1rem" }}>
                {/* <p style={heading}>{browse_fs_data?browse_fs_data[project_index][0]?.company:""}</p> */}
                 <table style={{ marginLeft:"10px", display:"inline-block", width:"auto"}}>
                    <TableHeader showTotal={false}/>
                    <tbody>
                      <div style={{ marginLeft: "1rem" }}>
                        <p style={heading}>Previous Period</p>
                      </div>
                      {projectSocie[0]?.previous_row_header.map((soccie, pr_index) => (
                        <PYTableData 
                          project_index={project_index}
                          soccie={soccie}
                          pr_index={pr_index} 
                          SoccieProject={projectSocie[0]} 
                          RestatedBalanceCalPy={RestatedBalanceCalPy} 
                          ComprehensiveCalPy={ComprehensiveCalPy}
                          TotalCalPy={TotalCalPy}
                          AcquisitionCalPy={AcquisitionSubsidiaryPy}
                          BalAtEndOfPrevReportPy={BalAtEndOfPrevReportPy}
                          showTotal={false}
                          showAdjustment = {false}
                        />
                      ))}
      
                      <div style={{ marginLeft: "1rem" }}>
                        <p style={heading}>Current Period</p>
                      </div>
                      {projectSocie[0]?.current_row_header.map((soccie, cr_index) => (
                        <CYTableData 
                          project_index={project_index}
                          soccie={soccie}
                          cr_index={cr_index} 
                          SoccieProject={projectSocie[0]} 
                          RestatedBalanceCalCy={RestatedBalanceCalCy} 
                          ComprehensiveCalCy={ComprehensiveCalCy}
                          TotalCalCy={TotalCalCy}
                          AcquisitionCalCy={AcquisitionSubsidiaryCy}
                          showTotal={false}
                          showAdjustment = {false}
                        />
                      ))}
                    </tbody>
                </table>
                </div>
              </>
                
            )) }
        
            {/* adjustment Table */}
            <div style={{ marginLeft: "1rem" }}>
                <p style={heading}>{"Adjustment"}</p>
                <table style={{ marginLeft:"10px", display:"inline-block", width:"auto"}}>
                  <TableHeader showTotal={false}/>
                  <tbody>
                    <div style={{ marginLeft: "1rem" }}>
                      <p style={heading}>Previous Period</p>
                    </div>
                    {adjustmentSoccie[0]?.previous_row_header.map((soccie, pr_index) => (
                      <PYTableData 
                        soccie={soccie}
                        pr_index={pr_index} 
                        SoccieProject={adjustmentSoccie[0]} 
                        RestatedBalanceCalPy={RestatedBalanceCalPy} 
                        ComprehensiveCalPy={ComprehensiveCalPy}
                        TotalCalPy={TotalCalPy}
                        AcquisitionCalPy={AcquisitionSubsidiaryPy}
                        BalAtEndOfPrevReportPy={BalAtEndOfPrevReportPy}
                        showTotal={false}
                        showAdjustment = {true}
                      />
                    ))}
                    <div style={{ marginLeft: "1rem" }}>
                      <p style={heading}>Current Period</p>
                    </div>
                    {adjustmentSoccie[0]?.current_row_header.map((soccie, cr_index) => (
                      <CYTableData 
                        soccie={soccie}
                        cr_index={cr_index} 
                        SoccieProject={adjustmentSoccie[0]} 
                        RestatedBalanceCalCy={RestatedBalanceCalCy} 
                        ComprehensiveCalCy={ComprehensiveCalCy}
                        TotalCalCy={TotalCalCy}
                        AcquisitionCalCy={AcquisitionSubsidiaryCy}
                        showTotal={false}
                        showAdjustment = {true}
                      />
                    ))}
                  </tbody>
                </table>
            </div>
            {/* total table */}
              <div style={{ marginLeft: "1rem" }}>
                  <p style={heading}>{"Total"}</p>
                <table style={{ marginLeft:"10px", display:"inline-block", width:"auto"}}>
                  <TableHeader showTotal={true}/>
                  <tbody>
                    <div style={{ marginLeft: "1rem" }}>
                      <p style={heading}>Previous Period</p>
                    </div>
                    {soccieTotalData[0]?.previous_row_header.map((soccie, pr_index) => (
                      <PYTableData 
                      soccie={soccie}
                      pr_index={pr_index} 
                      SoccieProject={soccieTotalData[0]} 
                      RestatedBalanceCalPy={RestatedBalanceCalPy} 
                      ComprehensiveCalPy={ComprehensiveCalPy}
                      TotalCalPy={TotalTableTotalCalPy}
                      BalAtEndOfPrevReportPy={BalAtEndOfPrevReportPy}
                      showTotal={true}
                      TotalRestatedBalanceCalPy={TotalRestatedBalanceCalPy}
                      TotalRowCalPy={TotalRowCalPy}
                      TotalComprehensiveCalPy={TotalComprehensiveCalPy}
                      AcquisitionCalPy = {TotalAcquisitionSubsidiaryPy}
                      TotalAcquisitionCalPy={GrossAcquisitionSubsidiaryPy}
                      TotalOfTotalPy={TotalOfTotalPy}
                      showAdjustment = {false}
                      />
                    ))}

                    <div style={{ marginLeft: "1rem" }}>
                      <p style={heading}>Current Period</p>
                    </div>
                    {soccieTotalData[0]?.current_row_header.map((soccie, cr_index) => (
                      <CYTableData 
                      soccie={soccie}
                      cr_index={cr_index} 
                      SoccieProject={soccieTotalData[0]} 
                      RestatedBalanceCalCy={RestatedBalanceCalCy} 
                      ComprehensiveCalCy={ComprehensiveCalCy}
                      TotalCalCy={TotalTableTotalCalCy}
                      TotalRestatedBalanceCalCy={TotalRestatedBalanceCalCy}
                      TotalRowCalCy={TotalRowCalCy}
                      TotalComprehensiveCalCy={TotalComprehensiveCalCy}
                      AcquisitionCalCy = {TotalAcquisitionSubsidiaryCy}
                      TotalAcquisitionCalCy={GrossAcquisitionSubsidiaryCy}
                      TotalOfTotalCy={TotalOfTotalCy}
                      showTotal={true}
                      showAdjustment = {false}
                      />
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </div>
      ) : (
        <p className="text-danger">
          {getSoccie.error_message || "Something went wrong!!!"}
        </p>
      )}
    </>
  );
};
export default NewConsoleSoccie;
