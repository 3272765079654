import moment from "moment";

export const dateTimeMapping = (projectDetails, type) => {
 try {
  if(type === 'cy') {
    return `As at ${moment(projectDetails.current_year).format("DD MMMM YYYY")}`;
  }else if(type === 'py') {
    return `As at ${moment(projectDetails.previous_year).format("DD MMMM YYYY")}`;
  }
 } catch (error) {
  console.log("err date time mapp", error)
  return `As at 10 November 2023`
 }
}

export const dateTimeMappingCashflow = (projectDetails, type) => {
    try {
     if(type === 'cy') {
       return `FOR THE PERIOD ENDED ${moment(projectDetails.current_year).format("DD MMMM YYYY")}`;
     }else if(type === 'py') {
       return `FOR THE PERIOD ENDED ${moment(projectDetails.previous_year).format("DD MMMM YYYY")}`;
     }
    } catch (error) {
     console.log("err date time mapp", error)
     return `FOR THE PERIOD ENDED 10 November 2023`
    }
}

export const dateTimeMappingNotes = (projectDetails, type) => {
    try {
     if(type === 'cy') {
       return `${moment(projectDetails.current_year).format("DD MMMM YYYY")}`;
     }else if(type === 'py') {
       return `${moment(projectDetails.previous_year).format("DD MMMM YYYY")}`;
     }
    } catch (error) {
     console.log("err date time mapp", error)
     return `10 November 2023`
    }
}

export const dateTimeMappingEPS = (projectDetails, type) => {
    try {
     if(type === 'cy') {
       return `Year ended ${moment(projectDetails.current_year).format("DD MMMM YYYY")}`;
     }else if(type === 'py') {
       return `Year ended ${moment(projectDetails.previous_year).format("DD MMMM YYYY")}`;
     }
    } catch (error) {
     console.log("err date time mapp", error)
     return `Year ended 10 November 2023`
    }
}