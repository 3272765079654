import { Box, Typography } from '@mui/material';
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from "react-select";
import { filterByCategory, setTabValue } from '../../../redux/actions/BookClosure/actionBookClosure';
import PropTypes from "prop-types";
import CategoryBsAndPnl from './CategoryBsAndPnl';


function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography> {children} </Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const TemplateTab = () => {
    const dispatch = useDispatch();
    const {
        filters,
        tabValue,
        groupings,
        catItems
    } = useSelector((initialState) => initialState.BookClosureTbcReducer);

    return (
        <>
            <div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ width: "60%", height: "60px" }}>
                                    <Select
                                        as="select"
                                        options={filters}
                                        placeholder="Select the notes -"
                                        className="react-abc"
                                        isClearable
                                        onChange={(e) => { }}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: "6px",
                                            zIndex: "0",
                                            colors: {
                                                ...theme.colors,
                                                borderColor: "white",
                                                backgroundColor: "white",
                                                primary25: "#03565a98",
                                                primary: "#03565A",
                                            },
                                            spacing: {
                                                ...theme.spacing,
                                            },
                                        })}
                                    />
                                </th>
                                <th>
                                    <div className='d-flex align-items-center ml-3 '>
                                        <div
                                            onClick={() => {
                                                dispatch(setTabValue(0))
                                                dispatch(filterByCategory({groupings, type:"BS"}))
                                            }}
                                            style={{ height:"40px", width:"40px" }}
                                            className={`rounded-circle custom-border cursor-pointer d-flex align-items-center justify-content-center ${tabValue === 0 ? "custom-common-theme-bg-color text-white" : "bg-white"}`}
                                        >
                                            BS
                                        </div>
                                        <div
                                            onClick={() => {
                                                dispatch(setTabValue(1))
                                                dispatch(filterByCategory({groupings, type:"PNL"}))
                                            }}
                                            style={{ height:"40px", width:"40px" }}
                                            className={`rounded-circle ml-3 custom-border cursor-pointer d-flex align-items-center justify-content-center ${tabValue === 1 ? "custom-common-theme-bg-color text-white" : "bg-white"}`}
                                        >
                                            PNL
                                        </div>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>

                <Box className="MuiBox-rootTab" sx={{ width: "100%" }}>
                    <TabPanel value={tabValue} index={0}>
                        <CategoryBsAndPnl
                            headers={["Particulars", "Notes", "As at 01 June 2024", "As at 01 June 2023"]}
                            rows={catItems}
                            // setTempValue={setValue}
                            // setGroup={setGroup}
                            // projectData={projectData}
                            // setHideData={setHideData}
                            // hideData={hideData}
                            // hideChildData={hideChildData}
                            // setAddNoteGrp={setAddNoteGrp}
                        />
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        {/* <PnlStatement
                            setTempValue={setValue}
                            rows={pnlData?.response?.pnl_statement}
                            projectData={projectData}
                            setHideData={setHideData}
                            hideData={hideData}
                            hideChildData={hideChildData}
                            setAddNoteGrp={setAddNoteGrp}
                        /> */}
                    </TabPanel>
                    {/* {
                        notes.map((data, key) => (
                            <TabPanel key={key} value={value} index={data.note_index}>
                                <Pattern2
                                    SetNewSg={props.SetNewSg}
                                    setAddGrouping={props.setAddGrouping}
                                    note_no={data.note_number}
                                    note_name={data.note_name}
                                />
                            </TabPanel>
                        ))
                    } */}
                </Box>
            </div>
        </>
    )
}

export default TemplateTab