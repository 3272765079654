import React, { useEffect, useRef, useState } from "react";
import "./library.css";
import { Sidebar, Menu, SubMenu } from "react-pro-sidebar";
import Tabs from "@mui/material/Tabs";
import { libraryData } from "../../data/library";
import { useDispatch, useSelector } from "react-redux";

const IndAs1Content = (props) => {
    const dispatch = useDispatch();
    const reduxIndNoteNo = useSelector((initialState) => initialState.LibraryReducer.indAs1NoteNo);
    const reduxCatData = useSelector((initialState) => initialState.LibraryReducer.indAs1CateData);
    const [value, setValue] = useState(0);

    const FilterCategory = (event, dx) => {
        dispatch({ type: "IND_AS_1_FROM_BS_PNL", payload: event.target.innerText })
    }

    // Active section code 
    const [activeSection, setActiveSection] = useState('section-0');
    const sections = useRef([]);

    const handleScroll = () => {
        const pageYOffset = window.pageYOffset;
        let newActiveSection = null;

        sections.current.forEach((section) => {
            const sectionOffsetTop = section.offsetTop;
            const sectionHeight = section.offsetHeight;

            if (pageYOffset >= sectionOffsetTop && pageYOffset < sectionOffsetTop + sectionHeight) {
                newActiveSection = section.id;
            }
        });

        setActiveSection(newActiveSection);
    };

    useEffect(() => {
        sections.current = document.querySelectorAll('[data-section]');
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSection]);

    const handleAnchorClick = (event, index) => {
        const sectionId = `section-${index}`;
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            const targetScrollPosition = sectionElement.offsetTop;
            event.preventDefault();
            window.scrollTo({
                top: targetScrollPosition,
                behavior: 'smooth',
            });
        }
    };

    return (
        <>
            <div className="d-flex">
                <Sidebar className="content-sidebar">
                    <Menu>
                        {
                            libraryData.map((data, dx) =>
                            (
                                <>
                                    <SubMenu
                                        {...props}
                                        label={data.name}
                                        style={{ backgroundColor: "#B8D0D0" }}
                                        className="fs-inter custom-font-600 font-size-18"
                                        onClick={(e) => FilterCategory(e, dx)}
                                        defaultOpen={dx === reduxIndNoteNo}
                                        key={dx}
                                    >
                                        <Tabs
                                            orientation="vertical"
                                            variant="scrollable"
                                            value={value}
                                            onChange={(event, newValue) => setValue(newValue)}
                                            aria-label="Vertical tabs example"
                                            style={{ backgroundColor: "#ECF8F8" }}
                                            className="font-sans"
                                        >
                                            <div >
                                                <div className="ml-0" style={{ maxHeight: "25rem" }}>
                                                    {
                                                        reduxCatData.map((itm, i) => {
                                                            return (<>
                                                                {
                                                                    itm?.heading &&
                                                                    <div className="d-flex ml-3">
                                                                        {activeSection === `section-${i}` && <span className="arrow-right"></span>}
                                                                        <a href={`#section-${i}`} onClick={(e) => handleAnchorClick(e, i)} style={{whiteSpace:'pre-line'}} className={`mt-0 ml-2 lib-data-section ${activeSection === `section-${i}` ? "custom-common-theme-text-color text-700" : "ml-3"} `}>{itm?.heading}</a>
                                                                    </div>
                                                                }
                                                            </>)
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Tabs>
                                    </SubMenu>
                                </>
                            )
                            )
                        }
                    </Menu>
                </Sidebar>

                <div className="content-children" >
                    <div>
                        {
                            reduxCatData.map((categorie, ix) => (
                                <>
                                    <div key={ix} id={`section-${ix}`} data-section>
                                        <div style={{ marginLeft: "7rem" }}><p className="scope m-0">{categorie?.heading}</p></div>
                                        {
                                            categorie?.data && categorie?.data.length > 0 && categorie?.data.map((ele, ex) => (
                                                <div className="d-flex" key={ex} >
                                                    <div className="table_culmn pt-3">
                                                        <p className="m-0" style={{ fontSize: "14px" }}>{ele?.reference}</p>
                                                    </div>
                                                    <div className="ml-2 p-2">
                                                        <div>
                                                            {
                                                                ele?.content && ele?.content.length > 0 && ele?.content.map((cnt, cx) => (
                                                                    <p className="intro_title m-0 mt-1" key={cx}>
                                                                        {cnt}
                                                                    </p>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </>
                            ))
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default IndAs1Content;
