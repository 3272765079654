import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { logout } from "../../../authentication/authUtilities";
import moment from "moment";

const TableHeaderAdjustment = (props) => {
  const {
    handleSave,
    handleChange,
    allsubdata,
    adjustment,
    dataRowOpen,
    currentCompany,
    adjCompanyData,
    handleDelete,
    scenario,
    subsiaryData,
    handleChangeDropDown,
    hideNewMOdal
  } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
    useEffect(() => {
    if (hideNewMOdal === false) {
        setOpen(false)
    }
    }, [hideNewMOdal])

    const particlarTab = { 
      width: "300px",
      height: "50px",
      textAlign: "left",
      paddingLeft: "2rem"
    }

  return (
    <>
      <thead style={{ fontSize: "2rem" }}>
        <button class="btn btn-primary1" onClick={handleOpen}>
          + New Adjustment
        </button>
        <tr>
          <th style={{ width: "5%", maxWidth: "5%" }}>Sl.No</th>
          <th
            style={{
              width: "4%",
              maxWidth: "8%",
              height: "62px",
              textAlign: "left",
            }}
          >
            Date input
          </th>
          <th style={particlarTab}>Comapany Code</th>
          <th style={particlarTab}>Comapany Name</th>
          <th style={particlarTab}>Ledger Name</th>
          <th style={particlarTab}>Adjustments Value</th>
          <th style={particlarTab}>Entry</th>
          <th style={particlarTab}>Remark</th>
          <th
          style={particlarTab}
          >
            Action
          </th>
        </tr>
      </thead>

      <NewAdjustMentModal
        open={open}
        handleClose={handleClose}
        handleChange={handleChange}
        allsubdata={allsubdata}
        handleSave={handleSave}
        adjustment={adjustment}
        dataRowOpen={dataRowOpen}
        currentCompany={currentCompany}
        adjCompanyData={adjCompanyData}
        handleDelete={handleDelete}
        subsiaryData={subsiaryData}
        scenario={scenario}
        handleChangeDropDown={handleChangeDropDown}
        hideNewMOdal={hideNewMOdal}
      />
    </>
  );
};

export default TableHeaderAdjustment;

const NewAdjustMentModal = (props) => {
  const {
    open,
    handleClose,
    handleChange,
    allsubdata,
    handleSave,
    adjustment,
    dataRowOpen,
    currentCompany,
    adjCompanyData,
    handleDelete,
    subsiaryData,
    scenario,
    handleChangeDropDown,
    hideNewMOdal
  } = props;

  const [ subData, setSubData ] = useState([]);

  const getScenario = (project_index) => {
    const currentDate = moment(currentCompany.current_financial_year).format(
      "YYYY-04-01"
    );
    const previousDate = moment(currentCompany.current_financial_year)
      .subtract(1, "year")
      .format("YYYY-03-31");
    const testDate = moment(
      subsiaryData[project_index]?.all_data?.date_Of_investment
        ? subsiaryData[project_index]?.all_data?.date_Of_investment
        : ""
    ).format("YYYY-MM-DD");
    //2nd scenario
    const py_date = moment(previousDate)
      .subtract(1, "year")
      .format("YYYY-03-31");
      const previousDate_2nd = moment(currentCompany.current_financial_year).subtract(1, "year").format('YYYY-04-01');
    const first_scenario = moment(testDate).isBetween(
      previousDate,
      currentDate
    );
    const second_scenario = moment(testDate).isBetween(py_date, previousDate_2nd);
    switch(scenario){
        case "current_yr":
            return first_scenario;
        case "previous_yr":
            return second_scenario;
        case "opening":
            if(!first_scenario || !second_scenario){
                return false
            }else{
                return true
            }
        default:
            return false
    }   
  };


  useEffect(()=>{
    if(subsiaryData.length > 0){
        const subData_ = subsiaryData.map((data, index)=>{
            if(getScenario(index)){
                return data
            }
        })
        setSubData(subData_.filter(data=>data !== undefined));
    }
  }, [])

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
   
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <table>
                {/* <thead style={{ fontSize: "2rem" }}>
                    <tr>
                        <th style={particlarTab}>Sl.No</th>
                        <th
                            style={{
                            width: "4%",
                            maxWidth: "8%",
                            height: "62px",
                            textAlign: "left",
                            }}
                        >
                            Date input
                        </th>
                        <th style={particlarTab}>Comapany Code</th>
                        <th style={particlarTab}>Comapany Name</th>
                        <th style={particlarTab}>Ledger Name</th>
                        {dataRowOpen === true &&
                            <th style={particlarTab}>Socie Data</th>
                        }
                        <th style={particlarTab}>Adjustments Value</th>
                        <th style={particlarTab}>Entry</th>
                        <th style={particlarTab}>Remark</th>
                        <th
                            style={{
                            width: "15%",
                            maxWidth: "15%",
                            textAlign: "left",
                            paddingLeft: "3rem",
                            }}
                        >
                            Action
                        </th>
                    </tr>
                </thead> */}
              <tbody>
                {/* <input type="hidden" value={scenario}></input> */}
                <tr>
                  <td style={{ width: "10%" }}>1</td>
                  <td style={{ textAlign: "center" }}>
                    <input
                      type="date"
                      onChange={(e) => handleChange("adjustment_date", e)}
                    ></input>
                  </td>
                  <td>Company_code</td>
                <td>
                  <div className="col-6 form-group">
                    <select
                      className="from-control"
                      style={{
                        width: "200px",
                        height: "50px",
                        overflow: "scroll",
                      }}
                      onChange={(e) => handleChangeDropDown("company_id", e.target.value)}
                      // defaultValue={adjDt.company_id}
                    >
                      <option value="">Select...</option>
                      {subsiaryData.map((data, index) => (
                        <option key={index} value={data?.all_data?._id}>
                          {data?.all_data?.company}
                        </option>
                      ))}
                    </select>
                  </div>
                </td>
                  {/* rest data */}
                  <AddNewRow
                    adjCompanyData={adjCompanyData}
                    dataRowOpen={dataRowOpen}
                    handleChange={handleChange}
                    adjustment={adjustment}
                    allsubdata={allsubdata}
                    handleDelete={handleDelete}
                    subsiaryData={subData}
                    handleChangeDropDown={handleChangeDropDown}
                  />
                  <td>
                    <div className="col-6 form-group">
                      <select
                        className="from-control"
                        style={{
                          width: "180px",
                          height: "50px",
                          overflow: "scroll",
                        }}
                        onChange={(e) => handleChangeDropDown("scenario", e.target.value)}
                        // defaultValue={adjDt.scenario}
                      >
                        <option>Select...</option>
                        <option value="temporary">Temporary Entry</option>
                        <option value="permanent">Permanent Entry</option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div className="col-6 form-group">
                      <textarea
                        onChange={(e) => handleChange("remark", e)}
                      ></textarea>
                    </div>
                  </td>
                  <td style={{ height: "150px", textAlign: "center" }}>
                    <button class="btn btn-primary1 mt-3"onClickCapture={()=>handleSave(scenario)} 
                    // onClick={handleSave}
                    >
                      Save
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </Typography>
        </Box>
      </Modal>
      
    </>
  );
};

const AddNewRow = (props) => {
  const {
    adjCompanyData,
    dataRowOpen,
    handleChange,
    adjustment,
    allsubdata,
    handleDelete,
    subsiaryData,
    handleChangeDropDown,
  } = props;

  return (
    <>
      {adjCompanyData.map((adjDt, i) => {
        return (
          <>
            <tr>

              <td style={{ width: "10%" }}>
                <div className=" col-8 form-group">
                  <Autocomplete
                    options={allsubdata}
                    sx={{ width: 500 }}
                    onChange={(e, value) =>
                        handleChangeDropDown("ledger_name", value, i)
                    }
                    defaultValue={allsubdata.find(data=>data.sub_grp===adjDt.ledger_name)}
                    renderInput={(params) => (
                      <TextField {...params} label="Ledger Name" />
                    )}
                    getOptionLabel={(option) => option.sub_grp}
                    renderOption={(props, option, index) => {
                        return (
                          <li {...props} key={index.index}>
                            {option.sub_grp}
                          </li>
                        );
                    }}
                  />
                </div>
                { i !== 0 ? <button
                  type="button"
                  class="btn-close float-end"
                  style={{ color: "red" }}
                  onClick={() => {
                    handleDelete(i);
                  }}
                >
                  X
                </button> : "" }
                
              </td>
              {dataRowOpen === true && (
                
                <td>
                  <div className="col-6 form-group">
                    <select
                      className="from-control"
                      style={{
                        width: "200px",
                        height: "50px",
                        overflow: "scroll",
                      }}
                      onChange={(e) => handleChangeDropDown("socie_name", e.target.value, i)}
                    >
                      <option>Select Socie....</option>
                      <option value="Balance at the beginning of the previous reporting period">Balance at the beginning of the previous reporting period</option>
                      <option value="Changes in accounting policy or prior period errors">Changes in accounting policy or prior period errors</option>
                      <option value="Profit for the year">Profit for the year</option>
                      <option value="Other comprehensive income">Other comprehensive income</option>
                      <option value="Issue of shares">Issue of shares</option>
                      <option value="Dividends">Dividends</option>
                      <option value="Employee stock options">Employee stock options</option>
                      <option value="Transfer to retained earnings">Transfer to retained earnings</option>
                      <option value="Any other change (to be specified)">Any other change (to be specified)</option>
                    </select>
                  </div>
                </td>
              )}
              <td
                style={{
                  height: "150px",
                  textAlign: "right",
                  paddingRight: "10px",
                }}
              >
                <input
                  type="number"
                  style={{
                    maxWidth: "140px",
                    border: "none",
                    textAlign: "right",
                  }}
                  onChange={(e) => handleChangeDropDown("adjustment_value", e.target.value, i)}
                  defaultValue={adjDt.adjustment_value}
                />
              </td>
             
            </tr>
          </>
        );
      })}
    </>
  );
};
