import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1050,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow:'scroll',
    height:'100%'
};

const AdditionalSubsidiariData = (props) => {
  const { openconsoleentry,
     handleClose,
     comapnyId ,
     handleInvestChnage,
     investcalculate,
     calculationtwo,
     handleValuationChnage,
     valuationcalculate,
     calculatefour,
     eqitysharedata,
     otherequitysharedata,
     otherreservesurplusdata,
     othereservedata,
     investvalue,
     interest,
     setfinalsumdata,
     handleAdditionalData,
     handleAdditional,
     eqityshare,
     datavalue,
     setFinalConsoleEntry,
     setOpenConsoleEntry
    } = props;

    const InvestData = ()=>{
        const result = Number(investvalue / datavalue) * Number(comapnyId.final_no_of_shares)
        return result
    }
    const setInterest = (shareData)=>{
        try {
            let res = shareData.map((data,i)=>(data.sub_grp.map((ele,i)=>ele.cy_amt)));
            return (res[0].reduce((data, acc) => data + acc, 0) + res[1].reduce((data, acc) => data + acc, 0) + res[2].reduce((data, acc) => data + acc, 0) +res[3].reduce((data, acc) => data + acc, 0)) * -1;

        } catch (error) {
            return error
        }
      }
        let totalAmountAdditional =
        Number(setInterest(eqityshare) * -(interest/100)) + Number(InvestData()?(InvestData()+investvalue)*-1:(investvalue * -1)) + setfinalsumdata
        const setEquity =(shareData,totaldata)=>{
            try {
                let res = shareData.map((data,i)=>(data.sub_grp.map((ele,i)=>ele.cy_amt)))
                console.log(res,"222112344444444444");
                return totaldata;
            } catch (error) {
                return error
            }
          }
    const handleAddSubsiaryData=()=>{
        let dataentry ={
            non_controlling_interest: Number(setInterest(eqityshare) * -(interest/100)).toFixed(2),
            investment: Number(InvestData()?(InvestData()+investvalue)*-1:(investvalue * -1)),
            otherinputs: Number(setfinalsumdata),
            goodwill: Number(setEquity(eqityshare,totalAmountAdditional) * -1).toFixed(2),
            GainOnFairValuation:InvestData()
        }
        setFinalConsoleEntry(dataentry);
        setOpenConsoleEntry(false);
      }
  return (

    
    <>
      <Modal
        open={openconsoleentry}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {comapnyId.final_holdings > 50 && (
            <div className="col-12">
              <button
                type="button"
                onClick={handleClose}
                class="btn-close float-end"
              ></button>
              <div class="table-responsive table-bordered">
                <table class="table">
                  <thead>
                    <tr>
                      <td>A</td>
                      <td>B</td>
                      <td>C</td>
                      <td>D</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Particular</strong>
                      </td>
                      <td>
                        <strong>Calculation</strong>
                      </td>
                      <td>
                        <strong>Calculation</strong>
                      </td>
                      <td>
                        <strong>Amount</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <strong>Investment</strong>
                      </td>
                      <td>{investvalue}</td>
                      <td>* -1</td>
                      <td>{investvalue * -1}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Non controlling interest</strong>
                      </td>
                      <td>{interest}</td>
                      <td>* -1</td>
                      <td>{interest * -1}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Retained earnings</strong>
                      </td>
                      <td>Balancing Figure</td>
                      <td>* -1</td>
                      <td>{investvalue + interest}</td>
                    </tr>
                  </tbody>
                </table>
                <input
                  type="button"
                  className="btn btn-primary mt-3"
                  style={{ float: "right", background: "rgb(3, 86, 90)" }}
                  value="Submit"
                  onClick={handleAdditional}
                />
              </div>
            </div>
          )}
          {comapnyId.final_holdings <= 50 && (
            <>
              <div className="col-12">
                <button
                  type="button"
                  onClick={handleClose}
                  class="btn-close float-end"
                ></button>
                <div class="table-responsive table-bordered">
                  <table class="table">
                    <thead>
                      <tr>
                        <td>A</td>
                        <td>B</td>
                        <td>C</td>
                        <td>D</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Particular</strong>
                        </td>
                        <td>
                          <strong>Calculation</strong>
                        </td>
                        <td>
                          <strong>Calculation</strong>
                        </td>
                        <td>
                          <strong>Amount</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong>Investment</strong>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="calculation1"
                            // onChange={(e) =>
                            //   handleInvestChnage(e, "calculation1")
                            // }
                            defaultValue={InvestData()}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            name="calculation2"
                            // onChange={(e) =>
                            //   handleInvestChnage(e, "calculation2")
                            // }
                            defaultValue={-1}
                          />
                        </td>
                        <td>{InvestData() * -1}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Gain on fair valuation of Investment</strong>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            // onChange={(e) =>
                            //   handleValuationChnage(e, "calculation3")
                            // }
                            defaultValue={InvestData() * -1}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            // onChange={(e) =>
                            //   handleValuationChnage(e, "calculation4")
                            // }
                            defaultValue={-1}
                          />
                        </td>
                        <td>{InvestData()}</td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table">
                    <thead>
                      <tr>
                        <td>A</td>
                        <td>B</td>
                        <td>C</td>
                        <td>D</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Particular</strong>
                        </td>
                        <td>
                          <strong>Calculation</strong>
                        </td>
                        <td>
                          <strong>Calculation</strong>
                        </td>
                        <td>
                          <strong>Amount</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <tr>
                        <td>
                          <strong>Share Capital</strong>
                        </td>
                        <td>{eqitysharedata ? eqitysharedata : "0"}</td>
                        <td>* -1</td>
                        <td>{eqitysharedata * -1}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>
                            Level 3 notes (inside level 2- Other equity)
                          </strong>
                        </td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>
                          equity component of compound financial instruments
                        </td>
                        <td>
                          {otherequitysharedata ? otherequitysharedata : "0"}
                        </td>
                        <td>* -1</td>
                        <td>{otherequitysharedata * -1}</td>
                      </tr>
                      <tr>
                        <td>reserves and surplus</td>
                        <td>
                          {otherreservesurplusdata
                            ? otherreservesurplusdata
                            : "0"}
                        </td>
                        <td>* -1</td>
                        <td>{otherreservesurplusdata * -1}</td>
                      </tr>
                      <tr>
                        <td>other reserves</td>
                        <td>{othereservedata ? othereservedata : "0"}</td>
                        <td>* -1</td>
                        <td>{othereservedata * -1}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Investment</strong>
                        </td>
                        <td>{investvalue ? investvalue : "0"}</td>
                        <td>* -1</td>
                        <td>{investvalue * -1}</td>
                      </tr> */}
                       {eqityshare.slice(0,-1).map((data,i)=>{
                                    return(
                                       
                                        <>
                                            {data.sub_grp.map((ele,i)=>{
                                                return(
                                                    <>
                                                        <tr>
                                                            <td><strong>{ele.sub_grp}</strong></td>
                                                            <td>{ele.cy_amt* -1}</td>
                                                            <td>* -1</td>
                                                            <td>{ele.cy_amt}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })}
                                        </>
                                        
                                    )
                                })}
                      <tr>
                        <td>
                          <strong>Non-controlling interest</strong>
                        </td>
                        <td>{setInterest(eqityshare)}</td>
                        <td>*{-(interest / 100)}</td>
                        <td>{Number(setInterest(eqityshare) * -(interest/100))}</td>
                      </tr>
                      <tr>
                            <td><strong>Investment</strong></td>
                            <td>{Number(InvestData()?(InvestData()+investvalue)*-1:(investvalue * -1))* -1}</td>
                            <td>* -1</td>
                            <td>{Number(InvestData()?(InvestData()+investvalue)*-1:(investvalue * -1))}</td>
                        </tr>
                      <tr>
                        <td>
                          <strong>Other inputs</strong>
                        </td>
                        <td>{setfinalsumdata}</td>
                        <td>* 1</td>
                        <td>{setfinalsumdata * -1}</td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Goodwill</strong>
                        </td>
                        <td>Balancing Figure</td>
                        <td>* -1</td>
                        <td>{(setEquity(eqityshare,totalAmountAdditional) * -1).toLocaleString("en-IN")}</td>
                      </tr>
                    </tbody>
                  </table>
                  <input
                    type="button"
                    className="btn btn-primary mt-3"
                    style={{ float: "right", background: "rgb(3, 86, 90)" }}
                    value="Submit"
                    onClick={() => {handleAddSubsiaryData()}}
                  />
                </div>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};
export default AdditionalSubsidiariData;
