import { getSubsidiaryConsole } from "../../Console/Component/services/apiHandler";


export const fetchSubsidiaryConsoleData = (link_id) => {
    return dispatch => {
        dispatch({  type:"SubsidiaryConsole_ACTION_START" })
        getSubsidiaryConsole(link_id).then((result)=>{
            dispatch({
                type: "SubsidiaryConsole_FETCH_DATA",
                payload: {data: result.data}
            })
        }).catch((err)=>{
            console.log(err,"error");
            dispatch({
                type:"SubsidiaryConsole_FETCH_DATA_FAILED",
                payload: err?.response?.data?.error || err.message
            })
        })        
    }
}