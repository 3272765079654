const presetType = {
    TBC_DATA_ACTION_START: "TBC_DATA_ACTION_START",
    TBC_DATA_FETCH_DATA: "TBC_DATA_FETCH_DATA",
    TBC_DATA_FETCH_DATA_FAILED: "TBC_DATA_FETCH_DATA_FAILED",
    SET_TAB_VALUE: "SET_TAB_VALUE",
    CATEGORY_ITEMS: "CATEGORY_ITEMS",
}

const Initial_state = {
    lineItems: [],
    groupings: [],
    filters: [],
    loading: false,
    error: false,
    errorMessage: null,
    tabValue: 0,
    catItems: []
};

const BookClosureTbcReducer = (state = Initial_state, action) => {
    switch (action.type) {
        case presetType.TBC_DATA_ACTION_START:
            return {
                ...state,
                loading: true,
                lineItems: [],
                error: false,
                errorMessage: null
            }
        case presetType.TBC_DATA_FETCH_DATA:
            return {
                ...state,
                lineItems: action.payload?.bookClosureTB?.ledgers ?? [],
                groupings: action.payload?.bookClosureTB?.grouping ?? [],
                filters: action.payload?.filters ?? [],
                loading: false,
                error: false,
                errorMessage: null,
            }
        case presetType.TBC_DATA_FETCH_DATA_FAILED:
            return {
                ...state,
                lineItems: [],
                errorMessage: action.payload,
                error: true
            }
        case presetType.SET_TAB_VALUE:
            return {
                ...state,
                tabValue: action.payload,
            }
        case presetType.CATEGORY_ITEMS:
            return {
                ...state,
                catItems: action.payload || [],
            }
        default:
            return state;
    }
};

export default BookClosureTbcReducer;
