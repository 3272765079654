import { useState, useEffect } from "react";
import {
  DeleteAdjustment,
  GetAdjustment,
  getCompanybyId,
} from "../services/apiHandler";
import swal from "sweetalert";
import UpdateAdjustment from "../modal/updateAdjustmentModal";
import { useDispatch } from "react-redux";
import { delAdjustment } from "../../../redux/actions/actionAdjustment";
// import DatePickerData from "../services/datePicker";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
const AdjustmentData = (props) => {
  const { allsubdata, adjustData, index, adjCompanyData, setAdjustmentData } =
    props;
  const dispatch = useDispatch();
  const [adjustmentValue, setAdjustmentValue] = useState([]);
  const [openModalUpdate, setoOpenModal] = useState(false);
  const handleClose = () => setoOpenModal(false);
  useEffect(() => {
    if (adjustData) {
      setAdjustmentValue(adjustData.adjustment_other_data);
    }
  }, [adjustData]);

  const handleDeleteAdj = async (adj_id) => {
    try {
      const result = await DeleteAdjustment(adj_id);
      if (result.data.status === true) {
        swal("", "Adjustments Deleted", "success");
        dispatch(delAdjustment(adj_id));
      } else {
        swal("", "Adjustments Not Found", "danger");
      }
    } catch (error) {
      swal("Please try again later", "Something went wrong", "danger");
    }
  };

  const getRandomKey = () => {
    const min = 10000;
    const max = 99999;
    const num = Math.floor(Math.random() * (max - min + 1)) + min;
    return num;
  };

  return (
    <>
      <tr key={getRandomKey()}>
        <td rowSpan={adjustmentValue.length}>{index}</td>
        <td style={{ textAlign: "center" }} rowSpan={adjustmentValue.length}>
          <input
            type="date"
            defaultValue={adjustData?.date_of_adjustment}
          ></input>
        </td>
        <td rowSpan={adjustmentValue.length}>{"company_code"}</td>
        <td rowSpan={adjustmentValue.length}>{adjustmentValue[0]?.company_id}</td>
        <td>{adjustmentValue[0]?.ledger_name}</td>
        <td>{adjustmentValue[0]?.adjustment_value}</td>
        <td rowSpan={adjustmentValue.length}>{adjustmentValue[0]?.scenario}</td>
        <td rowSpan={adjustmentValue.length}>
          {adjustData?.remark}
        </td>
        <td
          style={{ height: "150px", textAlign: "center" }}
          rowSpan={adjustmentValue.length}
        >
          <EditIcon
            onClick={() => {
              setoOpenModal(true);
            }}
            style={{ cursor: "pointer", color: "blue" }}
          />
          &nbsp;&nbsp;&nbsp;
          <DeleteIcon
            style={{ cursor: "pointer", color: "red" }}
            onClick={() => {
              handleDeleteAdj(adjustData._id);
            }}
          />
        </td>
      </tr>
      {adjustmentValue.map((item, key) => (
        <>
          {key !== 0 ? (
            <tr key={getRandomKey()}>
              <td>{item?.ledger_name}</td>
              <td>{item?.adjustment_value}</td>
            </tr>
          ) : (
            ""
          )}
        </>
      ))}
      <UpdateAdjustment
        openModalUpdate={openModalUpdate}
        handleClose={handleClose}
        adjustData={adjustData}
        allsubdata={allsubdata}
        setAdjustmentData={setAdjustmentData}
      />
    </>
  );
};

export default AdjustmentData;
