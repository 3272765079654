import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { toast } from 'react-toastify';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Navbar } from '../../Components/Navbar';
import Section from '../Components/Section';
import AccordionItem from '../Components/AccordionItem';
import "./../../Bookclosure/Styles/Bookclosure.css"
import { getLibraryList, getOneBookClosure, importTasksFromLibrary } from '../Services/ApiHandler';

const BookClosureLibrary = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { workspaceId, companyId } = useParams();

  const {
    bookClosureId,
  } = useSelector((state) => state.BookClosureReducer);

  const [activeSection, setActiveSection] = useState('');
  const [bookClosureLibrary, setBookClosureLibrary] = useState([]);
  const [taskList, setTaskList] = useState({});
  const [libraryCheckedTasks, setLibraryCheckedTasks] = useState([]);

  useEffect(() => {
    getBookClosureData();
    if (bookClosureId) {
      GetLibraryList(bookClosureId, companyId);
    }
  }, []);

  const getBookClosureData = () => {
    const data = {
      // companies: [],
      // assignees: [],
      // approvers: [userId]
    }
    if (!bookClosureId) {
      getOneBookClosure(workspaceId, data).then(response => {
        dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
        const book_closure_id = response.data.bookClosure._id;
        GetLibraryList(book_closure_id, companyId);
      }).catch(error => {
        swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
      })
    }
  }
  const GetLibraryList = (bookClosureId, companyId) => {
    getLibraryList(bookClosureId, companyId).then(response => {
      setBookClosureLibrary(response.data.bookClosureLibrary);
      setTaskList(response.data.bookClosureLibrary[0] ?? {});
      setActiveSection(response.data.bookClosureLibrary[0].module ?? "")
    }).catch(error => {
      swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
    })
  }

  const ImportLibraryTasks = () => {
    const data = {
      companyID: companyId,
      libraryTaskData: libraryCheckedTasks
    }
    if(libraryCheckedTasks.length > 0){
      importTasksFromLibrary(bookClosureId, data).then(response => {
        toast.success("Tasks Imported Successfully.");
        GetLibraryList(bookClosureId, companyId);
      }).catch(error =>
        swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error')
      )
    }
  }

  const HandleStatusChange = (event, moduleName, ix, task) => {
    const { name } = event.target;

    const updateTaskStatus = (tasks, ix, name) => {
      return tasks.map((task, index) =>
        index === ix ? { ...task, [name]: !task[name] } : task
      );
    };

    if(name === 'template'){
      setLibraryCheckedTasks(prevUpdateList => {
        if (task) {
          const oldTask = { libraryTaskID: task._id, template: task?.template};
          const newTask = {...oldTask, [name]: !task[name]}
  
          const taskIndex = prevUpdateList.findIndex(ele => ele.libraryTaskID === task._id);
          if (taskIndex !== -1) {
            return prevUpdateList.filter((taskIn, index) => 
              index !== taskIndex
            );
          } else {
            return [...prevUpdateList, newTask];
          }
        }
        return prevUpdateList;
      });
    }
    
    setBookClosureLibrary(prevLibrary => {
      return prevLibrary.map(module => {
        if (module.module === moduleName && module.tasks && module.tasks[ix]) {
          return {
            ...module,
            tasks: updateTaskStatus(module.tasks, ix, name)
          };
        }
        return module;
      });
    });

    setTaskList(prevTaskList => {
      if (prevTaskList.module === moduleName && prevTaskList.tasks && prevTaskList.tasks[ix]) {
        return {
          ...prevTaskList,
          tasks: updateTaskStatus(prevTaskList.tasks, ix, name)
        };
      }
      return prevTaskList;
    });
  };
  
  return (
    <>
      <div>
        <Navbar />
        <div className='px-4 p-2'>
          <button
            className="d-flex align-items-center border-0 bg-color-inherit"
            onClick={() => history.push(`/bookclosure/${workspaceId}`)}
          >
            <span><ArrowBackIcon className='custom-common-theme-text-color' /></span>
            <p className="m-0 custom-common-theme-text-color font-size-16 custom-font-600 ml-1">Back</p>
          </button>

          <div>
            <p className='custom-font-600 font-size-24'>Book Closure Library</p>
            <p className='custom-font-500 font-size-16 text-secondary'>Some sub text to explain about book closure.</p>
          </div>

          <div className='mb-3'>
            <Card className='radius-10px'>
              <div className='px-3 mb-2'>
                <div className='d-flex justify-content-between align-items-center p-2'>
                  <p className='font-size-18 custom-font-600 mt-0 mb-0'>{`Modules (${bookClosureLibrary.length})`}</p>
                  <div className='mt-2 task-btn'>
                    {/* <Button
                      variant="outlined"
                      className='font-size-16 custom-font-600 custom-common-theme-text-color primary-border btn-task font-sans radius-6px text-capitalize font-sans'
                      onClick={handleOpenGeneral}
                    >
                      Save Selection
                    </Button> */}
                    <Button
                      variant="contained"
                      className='custom-common-theme-bg-color font-size-16 custom-font-600 btn-task font-sans ml-3 radius-6px text-capitalize text-white font-sans'
                      onClick={() => ImportLibraryTasks()}
                    >
                      Import tasks
                    </Button>
                  </div>
                </div>

                <div className="row p-4 mr-1">
                  <div className="col-3 section-style">
                    {bookClosureLibrary && bookClosureLibrary.length > 0 && bookClosureLibrary.map((section, index) => (
                      <Section
                        key={index}
                        title={section.module}
                        isActive={section.module === activeSection}
                        onClick={() => {
                          setActiveSection(section.module);
                          const findLibData = bookClosureLibrary.find(ele => ele.module === section.module);
                          setTaskList(findLibData);
                        }}
                      />
                    ))}
                  </div>

                  <div className="col-9 radius-10px custom-bg-white-150" style={{ height: "500px" }}>
                    <div className='d-flex p-1'>
                      <table>
                        <thead>
                          <tr>
                            <th className='text-capitalize text-left pl-1 border-0' >{taskList?.module}</th>
                            <th className='border-0'>Tasks</th>
                            <th className='border-0'>Templates</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            taskList?.tasks && taskList.tasks.length > 0 &&
                            taskList.tasks.map((task, ix) => (
                              <tr key={ix}>
                                <td className='w-75 border-0 '>
                                  <AccordionItem
                                    task={task}
                                    index={ix + 1}
                                  />
                                </td>
                                <td className='border-0'>
                                  <input
                                    checked={task.exist}
                                    name='exist'
                                    onChange={(event) => HandleStatusChange(event, taskList?.module, ix, task)}
                                    type="checkbox"
                                    style={{ width: "25px", height: "25px" }}
                                  />
                                </td>
                                <td className='border-0'>
                                  <div className='d-flex justify-content-center align-items-center' >
                                    <input
                                      checked={task.template}
                                      name='template'
                                      onChange={(event) => HandleStatusChange(event, taskList?.module, ix, task)}
                                      type="checkbox"
                                      style={{ width: "25px", height: "25px" }}
                                    />
                                    <div className='oneline-icon'></div>
                                    <VisibilityIcon className='ml-1' style={{ width: "30px", height: "30px" }} />
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookClosureLibrary;
