import { getSubGrpFs } from "../../Console/Component/services/apiHandler";

export const fetchSubGroups = (project_id) => {
    return dispatch => {
        dispatch({type:"SG_ACTION_START"})
        getSubGrpFs(project_id).then((result)=>{
            const subGroup = result.data.fs_grp.map(data=>{
                return data.notes_grp.map(item=>{ 
                  return item.sub_grp.flat().map(dt=> { 
                    return {"fs_grp":data.fs_grp, "notes_no":item.notes_no, "sub_grp":dt.sub_grp} 
                  }) 
                })
              })
            dispatch({
                type: "SG_FETCH_DATA",
                payload: {data: subGroup.flat(2), project_id: project_id, response: result.data}
            }) 
        }).catch((error)=>{
            dispatch({
                type: "SG_FETCH_DATA_FAILED",
                payload: error.response.data.error
            }) 
        });
    }
}