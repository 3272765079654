import { Box, Modal } from "@mui/material";
import { APModal } from "../Styles/style";
import {
  getProjects,
  updateCompanyData,
  getProjectDetailById,
  UpdatePreset,
  updateAssociateData,
} from "../services/apiHandler";
import { useState, useEffect } from "react";
import moment from "moment";
import swal from "sweetalert";
const AssociateBrowseModal = (props) => {
  const {
    open,
    handleClose,
    selectedAssociateId,
    projectsData,
    setPresetHandler,
    prepareConsoleId,
    consoldata,
    workspaceData,
    nature,
    setSelectProject,
    selectedProject,
  } = props;

  const [projects, setProjects] = useState({
    loading: false,
    isSuccess: false,
    response: [],
    error_message: null,
  });

  useEffect(() => {
    (async () => {
      if (open) {
        if (projectsData) {
          setProjects({
            ...projects,
            loading: false,
            isSuccess: true,
            response: projectsData,
          });
        } else {
          fetchProjects();
        }
      }
    })();
  }, [open]);

  const fetchProjects = async () => {
    if (projects.response.length === 0) {
      setProjects({ ...projects, loading: true });
      try {
        const response = await getProjects();
        console.log("all projects", response);
        setProjects({
          ...projects,
          loading: false,
          isSuccess: true,
          response: response.data.project,
        });
      } catch (err) {
        console.log("error in modal", err);
        setProjects({
          ...projects,
          loading: false,
          isSuccess: false,
          response: err?.response?.data || "Something went Wrong",
        });
      }
    }
  };

  const SubmitPresetValue = async (data) => {
    try {
      if (!selectedProject) return alert("Please select an option");
      const checkProjectValid = await getProjectDetailById(selectedProject);
      console.log(checkProjectValid, "checkProjectValid", consoldata);
      if (data === "Subsidiary Company") {
        if (workspaceData[0]) {
          const validCheck =
            moment(checkProjectValid?.data?.project.current_year).format(
              "DD-MM-YYYY"
            ) === moment(workspaceData[0]?.console_cy).format("DD-MM-YYYY") &&
            moment(checkProjectValid?.data?.project?.previous_year).format(
              "DD-MM-YYYY"
            ) === moment(workspaceData[0]?.console_py).format("DD-MM-YYYY");
          if (validCheck) {
            const response = await updateCompanyData(
              prepareConsoleId,
              selectedProject,
              selectedAssociateId
            );
            if (response.status === 200) {
              const { _id } = projects.response.find(
                (data) => data._id === selectedProject
              );
              setPresetHandler(selectedAssociateId, _id,"Subsidiary Company");
              handleClose();
            }
          } else {
            swal("", "Upload Correct Preset", "error");
            handleClose();
          }
        } else {
          const validCheck =
            moment(checkProjectValid?.data?.project?.current_year).format(
              "DD-MM-YYYY"
            ) ===
              moment(consoldata?.current_financial_year).format("DD-MM-YYYY") &&
            moment(checkProjectValid?.data?.project?.previous_year).format(
              "DD-MM-YYYY"
            ) ===
              moment(consoldata?.previous_financial_year).format("DD-MM-YYYY");
          if (validCheck) {
            const response = await updateCompanyData(
              prepareConsoleId,
              selectedProject,
              selectedAssociateId
            );
            if (response.status === 200) {
              const { _id } = projects?.response?.find(
                (data) => data._id === selectedProject
              );
              setPresetHandler(selectedAssociateId, _id,"Subsidiary Company");
              handleClose();
            }
          } else {
            swal("", "Upload Correct Preset", "error");
            handleClose();
          }
        }
      } else {
        if (workspaceData[0]) {
          const validCheck =
            moment(checkProjectValid?.data?.project.current_year).format(
              "DD-MM-YYYY"
            ) === moment(workspaceData[0]?.console_cy).format("DD-MM-YYYY") &&
            moment(checkProjectValid?.data?.project?.previous_year).format(
              "DD-MM-YYYY"
            ) === moment(workspaceData[0]?.console_py).format("DD-MM-YYYY");
          if (validCheck) {
            const response = await updateCompanyData(
              prepareConsoleId,
              selectedProject,
              selectedAssociateId
            );
            if (response.status === 200) {
              const { _id } = projects.response.find(
                (data) => data._id === selectedProject
              );
              setPresetHandler(selectedAssociateId, _id,"Associate Company");
              handleClose();
            }
          } else {
            swal("", "Upload Correct Preset", "error");
            handleClose();
          }
        } else {
          const validCheck =
            moment(checkProjectValid?.data?.project?.current_year).format(
              "DD-MM-YYYY"
            ) ===
              moment(consoldata?.current_financial_year).format("DD-MM-YYYY") &&
            moment(checkProjectValid?.data?.project?.previous_year).format(
              "DD-MM-YYYY"
            ) ===
              moment(consoldata?.previous_financial_year).format("DD-MM-YYYY");
          if (validCheck) {
            const response = await updateAssociateData(
              prepareConsoleId,
              selectedProject,
              selectedAssociateId
            );
            if (response.status === 200) {
              const { _id } = projects?.response?.find(
                (data) => data._id === selectedProject
              );
              setPresetHandler(selectedAssociateId, _id,"Associate Company");
              handleClose();
            }
          } else {
            swal("", "Upload Correct Preset", "error");
            handleClose();
          }
        }
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={APModal}>
        <div class="card-body">
          <button
            class="btn-close float-end"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>

          <div class="col-md-12">
            {/* <label>Upload Preset </label> */}
            {projects.loading ? (
              "loading..."
            ) : (
              <>
                {projects.isSuccess ? (
                  <>
                    <select
                      className="form-control mt-2"
                      onChange={(e) => setSelectProject(e.target.value)}
                      value={selectedProject}
                    >
                      <option>select</option>
                      {projects.response.length > 0 &&
                        projects.response.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.project_name}
                          </option>
                        ))}
                      ;
                    </select>
                    <button
                      class="btn btn-primary mt-3"
                      style={{ background: "rgb(3, 86, 90)", float: "right" }}
                      onClick={() => SubmitPresetValue(nature)}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <p className="text-danger">
                    {projects?.error_message ?? "Something went wrong"}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AssociateBrowseModal;
