import Header from "./header";
import { Link, useParams } from "react-router-dom";
import logo from "../assets/images/logo/Logo.svg";
import Select from "react-select";
import { Submit } from "../../Components/Button";
import arrowLeftGreen from "../../assets/arrowLeftGreen.svg";
import BSModalView from "../Component/modal/bsModalView";
import { getPrepareConsole, getProjects } from "./services/apiHandler";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { consoleNotesData, filterOptionsData } from "./services/mockHandler";
import { fetchGroupData } from "../../redux/actions/actionGrouping";
import { fetchBSData } from "../../redux/actions/actionBS";
import { fetchPNLData } from "../../redux/actions/actionPNL";
import { fetchSocieData } from "../../redux/actions/actionSocie";
import { getAllIR, getCompany } from "../../WorkSpace/Components/ApiServices/apiHandler";
import { fetchPCData } from "../../redux/actions/actionPreset";
import { CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router";
import { fetchAssociateData } from "../../redux/actions/actionAssociate";
import ExportExcel from "../../helper/excelexport";
import { Navbar } from "../../Components/Navbar";

const header = {
  height: "80px",
  width: "100%",
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  padding: "0rem 1rem",
  borderRadius: "3px",
  marginBottom: "3rem",
  marginTop: "40px",
};
const name = {
  fontSize: "15px",
  fontWeight: "500",
  paddingLeft: "1%",
  paddingRight: "2%",
};
const button = {
  padding: "8px",
  width: "10rem",
  // marginLeft:'auto'
};
const button3 = {
  padding: "8px",
  width: "15rem",
  marginLeft: "283px",
};
const button2 = {
  padding: "8px",
  width: "15em",
  marginLeft: "auto",
};
const backNav = {
  fontSize: "1.2rem",
  fontWeight: "600",
  color: "#03565A",
  cursor: "pointer",
};

const mainTag = {
  fontSize: "1.15rem",
  fontWeight: "700",
};
const line = {
  height: "70%",
  width: "2px",
  backgroundColor: "rgb(229, 229, 229)",
  marginRight: "2%",
};
const ViewFS = (props) => {
  const id = useParams();
  const [consoldata, setConsolData] = useState("");
  const [projectPreset, setProjectPreset] = useState(null);
  const [projectsData, setProjectsData] = useState([]);
  const [allcheckdata, setConsolidate] = useState([]);
  const [allAssociateCheckData, setAssociateConsolidate] = useState([]);

  
  const [selectedPresetProject, setSelectedPresetProject] = useState(null);
  //   const AllCheckData = useSelector((state) => state.APAReducer);
  const [filterData, setFilterData] = useState([]);
  const [notes, setNotes] = useState([]);
  const balanceSheet = useSelector((state) => state.BSReducer);
  const pnlStatement = useSelector((state) => state.PNLReducer);
  const associateStatement = useSelector((state) => state.ASSOCIATEReducer);
  const getSocie = useSelector((state) => state.SoccieReducer);
  const groupingData = useSelector((state) => state.GroupingReducer);
  const dispatch = useDispatch();
  const currentCompProjectId = selectedPresetProject?.project_id?selectedPresetProject?.project_id:projectPreset;
  const [allIrList, setAllIr] = useState([]);
  const [allCompany, setAllCompany] = useState([]);
  const fetchPrepareConsole = useSelector((state) => state.PCReducer);
  let history = useHistory();
  useEffect(() => {
    // dispatch(fetchPCData(id.id));
    AllIrData();
    fetchPreConsole();
    getCompanyData();
    GetCompany();
  }, []);

  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
    }
  };
  const GetCompany = async () => {
    const res = await getCompany();
    if (res?.status === 200) {
      setAllCompany(res.data.getCompany);
    }
  };
  const FindIr = (ir_id) => {
    const IrData = allIrList.filter((data) => data._id === ir_id);
    return IrData;
  };

  useEffect(() => {
    (async () => {
      if (allcheckdata && allIrList) {
        if (filterData.length === 0) {
          const filter_res = await filterOptionsData();
          setFilterData(filter_res.data);
        }
        if (notes.length === 0) {
          const notes_data = await consoleNotesData();
          setNotes(notes_data.data);
        }
        if (allcheckdata.length > 0) {
          const project_ids = allcheckdata?.map((data) => data.project_id);
          const browse_fs = allcheckdata?.map((data) => FindIr(data.Ir_id));
          const browse_fs_ids = browse_fs?.map(
            (data) => data[0]?.browse_control_dateFS
          );
          if (
            !groupingData.isSuccess ||
            groupingData.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchGroupData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          if (
            !balanceSheet.isSuccess ||
            balanceSheet.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchBSData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          if (
            !pnlStatement.isSuccess ||
            pnlStatement.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchPNLData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
          const payload = {
            project_ids: project_ids,
            browse_fs_ids: browse_fs_ids,
            current_comp_project_id: currentCompProjectId,
          };
          if (
            !getSocie.isSuccess ||
            getSocie.payload !== JSON.stringify(payload)
          ) {
            dispatch(fetchSocieData(payload));
          }
        } 

        if (allAssociateCheckData.length > 0) {
          const project_ids = allAssociateCheckData.map((data) => data.project_id);
          const browse_fs = allAssociateCheckData.map((data) => FindIr(data.Ir_id));
          const browse_fs_ids = browse_fs.map(
            (data) => data[0]?.browse_control_dateFS
          );
          if (
            !associateStatement.isSuccess ||
            associateStatement.set_payload !==
              JSON.stringify({
                project_ids,
                browse_fs_ids,
                currentCompProjectId,
              })
          ) {
            dispatch(
              fetchAssociateData(project_ids, browse_fs_ids, currentCompProjectId)
            );
          }
        }
      }
    })();
  }, [allcheckdata,allIrList]);

  useEffect(() => {
    if (projectPreset) {
      getProjectName(projectPreset);
    }
  }, [projectPreset, projectsData]);

  const getCompanyData = async () => {
    try {
      const response = await getProjects();
      setProjectsData(response.data.project);
    } catch (err) {
      console.log("err", err.response);
    }
  };

  const getProjectName = async (p_id) => {
    try {
      const project = projectsData.find((data) => data._id === p_id);
      setSelectedPresetProject({
        project_name: project.project_name,
        project_id: project._id,
      });
    } catch (error) {
      console.log("error", error.message);
      return "None";
    }
  };
  const fetchPreConsole = async () => {
    try {
      const response = await getPrepareConsole(id.id);
      
      if (response.status === 200) {
        dispatch(fetchPCData(id.id))
        setConsolData(response.data.getOnePreCompany[0]);
        setProjectPreset(response.data.getOnePreCompany[0]?.upload_preset);
        setConsolidate(response.data.getOnePreCompany[0]?.consolidation);
        setAssociateConsolidate(response?.data?.getOnePreCompany[0]?.AssociateConsolidate)
      }
    } catch (err) {
      console.log("err", err.response);
    }
  };

  const PopulateFS=()=>{
    history.push(`/populate-fs/${id.id}`);
    window.location.reload();

  }

  const CompanyName = (c_id, field) => {
    const result = allCompany.filter((adj) => adj._id === c_id);
    if (field === "parent") {
      return result[0]?.company_name ? result[0]?.company_name : "-";
    } else {
      return result[0]?.company_name ? result[0]?.company_name : "-";
    }
  };

  const ExcelExportData = [
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "XXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Standlone Balance Sheet as at XXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "(In INR Lakhs)",
    },

    {
      Col1: "",
      Col2: "Note No",
      Col3: "As at Dec 31 2017",
      Col4: "As at march 31 2017",
    },
   


    {
      Col1: "ASSETS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Non-current assets",
      Col2: "",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Property,Plant and Equipment",
      Col2: "1.1",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Capital Work in progress",
      Col2: "1.1",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Other Intangible assets",
      Col2: "1.2",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Financial assets",
      Col2: "",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Investments",
      Col2: "1.3",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Loans",
      Col2: "1.4",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Security Deposits",
      Col2: "1.5",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other financial assets",
      Col2: "1.5",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Non-Current tax assets(Net)",
      Col2: "1.6",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other non-current assets",
      Col2: "1.7",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "Current assets",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Inventories",
      Col2: "1.8",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Financial assets",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Invenstments",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Trade receivables",
      Col2: "1.9",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash and cash equivalents",
      Col2: "1.10",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Security Deposits",
      Col2: "1.11",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other financial assets",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Current tax assets(Net)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other Current assets",
      Col2: "1.12",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "Total Assets",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "EQUITY AND LIABILITIES",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "EQUITY",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Equity Share capital",
      Col2: "1.13",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other Equity",
      Col2: "1.14",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "LIABILITIES",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Non-current liabilities",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Financial liabilities",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Borrowings",
      Col2: "1.15",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other-Non-Current Financial Liabilities",
      Col2: "1.16",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Provisions",
      Col2: "1.17",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Deferred tax liabilities(Net",
      Col2: "1.18",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other Non current liability",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "Current Liabilities",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Finance Liabilities",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Borrowings",
      Col2: "1.19",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Trade payables",
      Col2: "1.20",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other current Financial Liabilities",
      Col2: "1.21",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other current liabilities",
      Col2: "1.22",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Provisions",
      Col2: "1.23",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "Total Equity and Liabilities",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
  ];

  const ExcelExportData2 = [
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "XXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Standlone Balance Sheet as at XXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "(In INR Lakhs)",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "Period Ended",
      Col4: "Year Ended",
    },
    {
      Col1: "",
      Col2: "Note No",
      Col3: "Dec 31 2017",
      Col4: "March 31 2017",
    },
    {
      Col1: "INCOME",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Revenue From Operations",
      Col2: "2.1",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other Income",
      Col2: "2.2",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total Income",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "EXPENSES",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cost of materials consumed",
      Col2: "2.3",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Changes in inventories of finished goods and Work-in-progress",
      Col2: "2.4",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Excise duty on sale of goods",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Employee benefits expense",
      Col2: "2.5",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Depreciation and amortization expense",
      Col2: "2.6",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other expense",
      Col2: "2.7",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Finance costs",
      Col2: "2.8",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total expenses",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Profit before tax",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Tax expense",
      Col2: "3.1",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "(1)Current tax",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Less:MAT Credit Entitlement",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Net Current Tax",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "(2)Deferred tax",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Profit for the year",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "Other Comprehensive Income",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "A (i) Items that will not be reclassified to profit or loss",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "- Remeasurement of defined benefit plans",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "- Equity Instruments through OCI",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "(ii) Income tax relating to items that will not be reclassified to profit or loss",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "B (i) Items that will be reclassified  to profit or loss",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "- The effective portion of gains and loss on hedging instruments in a cash flow hedge",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "(ii) Income tax relating to items that will be reclassified to profit or loss",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other Comprehensive Income for the year,net of tax",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total Comprehensive Income for the year (Comprising Profit and Other  Comprehensive Income for the Year)",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "Earnings per equity share",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "(1) Basic",
      Col2: "",
      Col3: "#REF!",
      Col4: "#REF!",
    },
    {
      Col1: "(2) Diluted",
      Col2: "",
      Col3: "#REF!",
      Col4: "#REF!",
    },
    {
      Col1: "The Footer that the user enters in My Finalyst app",
      Col2: "",
      Col3: "",
      Col4: "",
    },
  ];

  const ExcelExportData3 = [
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "XXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Standlone Balance Sheet as at XXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "(INR in Lakhs",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "a. Equity Share Capital",
      Col2: "Balance the beginning of April 1,2017",
      Col3: "Changes in equity share capital during the period",
      Col4: "Balance as at December 31,2017",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Current year",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "Reserve",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "",
      Col2: "Share application money pending allotment",
      Col3: " Equity component of compounded financial instruments",
      Col4: "Capital reserve",
      Col5: "Securities Premium",
      Col6: "Other reserves(Specify nature",
      Col7: "Retained Earnings",
      Col8: "Debt instruments through Other Comprehensive Income",
      Col9: "Equity Instruments through Other Comprehensive Income",
      Col10: "Effective portion of Cash Flow Hedges",
      Col11: "Revalution Surplus",
      Col12:
        "Exchange differences on translating the financial statements of a foreign operation",
      Col13: "Money received against share warrants",
      Col14: "Totall",
    },
    {
      Col1: "Balance  at the beginning of the previous reporting period",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Changes in accounting policy or prior period errors",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Restated balance at the beginning of the current reporting period",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Profit for the year",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Other comprehesive income",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Total Comprehensive Income for the current year",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Issue of shares",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Dividends",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Employee stock options",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Transfer to retained earings",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Any other change (to be specific d)",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
    {
      Col1: "Balance at the end of the current reporting period",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Previous period/Year",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "Reserve and surplus",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "",
      Col2: "Share application money pending allotment",
      Col3: "Equity component of compounded financial instruments",
      Col4: "Capital reserve",
      Col5: "Securities premium",
      Col6: "Other reserves(Specify nature",
      Col7: "Retained Earnings",
      Col8: "Debit instruments through Other Comprehensive Incomr",
      Col9: "Equity Instruments through Other Comprehensive Income",
      Col10: "Effective portion Cash Flow Hedges ",
      Col11: "Revalution Surplus",
      Col12:
        "Exchange difference on translating the financial statements of a foreign operation",
      Col13: "Money received against share warrants",
      Col14: "Total",
    },

    {
      Col1: "Balance at the beginning of the previous reporting period",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Changes in accounting policy or prior period errors",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Restated balance at the beginning of the current reporting period ",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Profit for the year",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Other comprehesive income ",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Issue of shares",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Dividends",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Employee stock options",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },

    {
      Col1: "Balance at the end of the current reporting period",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
      Col10: "",
      Col11: "",
      Col12: "",
      Col13: "",
      Col14: "",
    },
  ];
  const ExcelExportData4 = [
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "XXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Standalone Cashflow Statement for the Period ended Dec 31 2017",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: " (INR in Lakhs) ",
    },
    {
      Col1: "",
      Col2: "",
      Col3: " Period ended Dec 31 2017 ",
      Col4: " Year Ended March 31 2017 ",
    },
    {
      Col1: "Cash flows from operating activities",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Profit before taxation ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Adjustments for:",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Depreciation ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Gain on sale of assets",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Exchange difference on transaction/translation (loss/(gain))",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "MTM Gain / (Loss) - Derivative -( Net)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Interest receipt on loan to WOS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Interest received",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Investment income ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Interest expense ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Change in trade and other receivables",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Increase in inventories",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Change in Trade Payables and other Payables",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash generated from operations ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Income taxes paid ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Net cash from operating activities - A",
      Col2: "",
      Col3: "0",
      Col4: "-0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash flows from investing activities ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Purchase of property, plant and equipment ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Purchase of Intangible",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Purchase of shares",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Proceeds from sale of equipment ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Interest received ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Interest receipt on loan to WOS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Net cash used in investing activities - B",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash flows from financing activities",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Proceeds from long-term borrowings ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Repayment of long term borrowings",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Proceeds from short-term borrowings ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Interest (paid) /received",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Dividend Paid",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Net cash used in financing activities- C",
      Col2: "",
      Col3: "0",
      Col4: "-0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Net increase in cash and cash equivalents - (A+B+C)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash and cash equivalents at beginning of year",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash and cash equivalents at end of year",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash & Cash Equivalents as per Note 1.10",
      Col2: "",
      Col3: "0",
      Col4: "0",
    },
    {
      Col1: "The Footer that the user enters in My Finalyst app.",
      Col2: "",
      Col3: "",
      Col4: "",
    },
  ];

  const ExcelExportData5 = [
    {
      Col1: "XXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Notes to Standard Financial for the Period Dec 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "1.1 PROPERTY,PLANT & EQUIPMENT",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "(INR in Lakhs",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Asset Category ",
      Col2: "Freehold land",
      Col3: "Freehold Building",
      Col4: "Plant & Machinery",
      Col5: "Office Equipments",
      Col6: "Furniture & Fixtures",
      Col7: "Vehicles",
      Col8: "Property,Plant & Equipment",
      Col9: "Capital Work in Progress",
    },
    {
      Col1: "Gross Block",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At April 1 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Reclassifications to assets held for sale",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Disposals",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Exchange differences on translation of foreignoperations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At Dec 1 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Reclassfications on translation of foreignoperations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At Dec 31 2017",
      Col2: "-",
      Col3: "-",
      Col4: "-",
      Col5: "-",
      Col6: "-",
      Col7: "-",
      Col8: "-",
      Col9: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Depreciation",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At April 1 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Disposalas",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Exchange difference on translation of foreignoperations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At Dec 31 2017",
      Col2: "-",
      Col3: "-",
      Col4: "-",
      Col5: "-",
      Col6: "-",
      Col7: "-",
      Col8: "-",
      Col9: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At Dec 31 2017",
      Col2: "-",
      Col3: "-",
      Col4: "-",
      Col5: "-",
      Col6: "-",
      Col7: "-",
      Col8: "-",
      Col9: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "1.2 INTANGIBLE ASSETS",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "(INR in Lakhs)",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Asset Category",
      Col2: "Computer Softure",
      Col3: "Product",
      Col4: "Intangible Assets Total",
      Col5: "Capital Work in Progress",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Gross Block",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At April 1 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Disposals",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At Dec 31 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Depreciation",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At April 1 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "-",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "-",
    },
    {
      Col1: "Disposals",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "-",
    },
    {
      Col1: "At Dec 31 2017",
      Col2: "-",
      Col3: "-",
      Col4: "-",
      Col5: "-",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "At Dec 31 2017",
      Col2: "-",
      Col3: "-",
      Col4: "-",
      Col5: "-",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
  ];

  const ExcelExportData6 = [
    {
      Col1: "FINANCIAL ASSETS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No 1.3",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NON CURRENT INVESTMENTS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Investment in Equity of Subsidiaries",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Investment in Equity of Joint Venture",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Investment in Equity instruments(at fair Value through OCI)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Quoted",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Unquoted",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other Investments at fair value",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.4",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "LOANS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017 ",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Loan to Wholly Owned Subsidiaries",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "XXXXXXXXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "XXXXXXXXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "XXXXXXXXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Add:Interest Accrued",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Less: Provision forDoubtful Advances",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.5",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "SECURITY DEPOSITS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },

    {
      Col1: "Particulars ",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "Rent Deposit-Non Current",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.6",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NON CURRENT TAX ASSETS(NET)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: " As at March 31 2017",
    },
    {
      Col1: "Advance Payment of income tax less provisions",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Note No.1.7",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "OTHER NON CURRENT ASSETS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Unsecured,considered good",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "Prepayments under operating lease",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Capital Advances",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.8",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "INVENTORIES",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "-",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "-",
    },
    {
      Col1: "Raw Materials Components and Consumables",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Consumable Stores",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Machinery Spares",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Packing Materials",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Note No 1.9",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "TRADE RECEIVABLES",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "Unsecured",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Considered good **",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Less: Provision for doubtful debts",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Note No 1.10",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "CASH AND EQUIVALENTS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particular",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "a.Balance with banks",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "b.Cash on hand",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "c.Other Bank balances",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "i)Margin money against LC",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "ii)Margin against Guaranted",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Note No. 1.11",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "SECURITY DEPOSIT-CURRENT",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec  31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.12",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "OTHER CURRENT ASSETS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "(Unsecured,considered good unles otherwise stated",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Advance to Suppliers(Other then stated)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Others",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "Note No. 1.13",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "EQUITY SHARE CAPITAL",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars  As at  Dec 31 2017  ",
      Col2: "",
      Col3: "As at March 31 2017",
      Col4: "",
    },
    {
      Col1: "Nos. Amount",
      Col2: "",
      Col3: "Nos.",
      Col4: "Amount",
    },
    {
      Col1: "Authorised",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Equity Share of Rs. 100 and 20,00,000",
      Col2: "2000",
      Col3: "10,00,000",
      Col4: "1,000",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Issued,called,subscribed &paid Up",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Equity Shares of Rs.100 10,06,080",
      Col2: "1,006",
      Col3: "5,74,903",
      Col4: "575",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total 10,06,080",
      Col2: "1,006",
      Col3: "5,74,903",
      Col4: "575",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.14",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "OTHER EQUITY",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "Securities Premium Account",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "General Reserves",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Cash Flow Hedge Reserve",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Change in fair value of FVOCI equity insruments",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Retanined Earnings",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "NOTE No. 1.15",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "LONG TERM BORROWINGS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars As at Dec 31 Non-Current",
      Col2: "Current",
      Col3: "As at March 31 2017 Non-Current",
      Col4: "Current",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Secured",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "From Banks",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Rupee Term Loans 17,498",
      Col2: "",
      Col3: "5,045 22,868",
      Col4: "3,048",
    },
    {
      Col1: "Foreign Currency Term Loans 22,791",
      Col2: "",
      Col3: "8,089 15,903",
      Col4: "8,557",
    },
    {
      Col1: "Total 44,402",
      Col2: "",
      Col3: "13,885 43,411",
      Col4: "11,938",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Notes:",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "1.These are carried at amortised cost",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "*Taken to other current financial liabilities(Note 1.20)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.6",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NON-CURRENT FINANCIAL LIABILITIES(Note 1.20)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars ",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "Currency swap & IRS Derivative",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Rent Advance",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Notes.",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "1.Currency swaps are principal only are cash flow hedge and hence are carried at fair value through OCI  ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "2. IRS derivatives are carried at fair value through Statement of Profit & Loss",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "3. Rent advance is carried at amorized cost as edit is not material to be fair valu",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.17",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "LONG TERM PROVISIONS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "Other Provisions",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Provision for Warranty Cost",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.18",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "DEFERRED TAX LIABILITIES(NET)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars ",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Deferred Tax Liabilities  ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Deferred Tax Assets",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Defrred Tax Liabilities(net)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "MAT Credit Entitlement-Unused tax credit",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total ",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.19",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "SHORT TERM BORROWINGS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31 20117",
    },
    {
      Col1: "Secured",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Loans repayable on demand from banks",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Rupee Loans",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Foregin Currency Loans",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "A",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "Total  A+B",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "Short Term Borrowings from banks are secured by a.first pari passu charge on current assets of the company.b.second pari passu charge on fixed assets of the company in addition to the above ,loans guaranted of the Chairman and Managing Director ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars ",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: "As at March 31  2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Due to Micro and small Eterprises-(MSME)",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Due to Suppliers",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Accrued Expenses and others",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "NOTE No. 1.21",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "OTHER CURRENT FINANCIAL LIABILITIES",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec  31 2017",
      Col4: " As at MArch 31 2017",
    },
    {
      Col1: "Current maturities of long-term debt",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Interest accrued but not due on borrowings",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Employess related liabilitied",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "* Other dues to directors",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "NOTE No. 1.22",
      Col2: "",
      Col3: "As at Dec 31 2017",
      Col4: " As at MArch 31 2017",
    },
    {
      Col1: "OTHER CURRENT LIABILITIES",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Advance from customers",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Defered Renvenue-EPCG benefit ",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "statutory Dues",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Others",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
    {
      Col1: "NOTE No. 1.23",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "SHORT TERM PROVISIONS",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Particulars",
      Col2: "",
      Col3: "As at Dec 2017",
      Col4: "As at March 31 2017",
    },
    {
      Col1: "Provision for employee benefits",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Gratuity",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Other Previsions",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Provision for Warrenty Cost",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Provision for Rejection",
      Col2: "",
      Col3: "",
      Col4: "",
    },
    {
      Col1: "Total",
      Col2: "",
      Col3: "-",
      Col4: "-",
    },
  ];

  const ExcelExportData7 = [
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "NOTE NO. 2.1",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "REVENUE FROM OPERATIONS",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Particulars",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "Sale of products ",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Domestic Sales",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Export Sales",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "A",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Sale of services",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Machining Charges",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Service Charges",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "B.",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "Other operating revenues",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Sale of swarf & others",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Duty Drawback",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "EPCG income on fulfilling obligation",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Export Incentive under MEIS",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "C.",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Revenue from operations (Gross)(A+B+C)",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "NOTE No. 2.2",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "OTHER INCOME",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Particulars",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Intrest Income form deposits",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Intrest Income due to unwinding of fair valued assets",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "-Rent Advance",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "-Deemed Equity",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Net gain on sale of assets",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Rental income",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Intrest on Loan to WOS",
      Col2: "",
      Col3: "",
    },

    {
      Col1: "Exchange rate Gain/(Loss)on Transction & Translation",
      Col2: "",
      Col3: "",
    },

    {
      Col1: "MTM Gain /(Loss)- Derivative-(Net)",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Others",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Total",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "NOTE No. 2.3",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "COST OF RAW MATERIALS AND COMPONENTS CONSUMED",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Particulars",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Cost of goods sold",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Carrage inward",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Sub Control Charges",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Total",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "NOTE No. 2.4",
      Col2: "",
      Col3: "",
    },

    {
      Col1: "CHANGES IN INVENTORIES OF FG & WIP",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Particulars ",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Inventory at the end of the period",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Work in progress",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Sub total",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Inventory at the beginning of the period",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Work in Progress",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Sub total",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "(Increase)/ decraese in inventory",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "NOTE No. 2.5",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "EMPLOYEE BENEFITS EXPENSE",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Particulars",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "Salaries and wages",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Contributions to PF & ESI",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Contribution to Gratuity fund",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Staff welfare expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Total",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "NOTE No. 2.6",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "DEPRECIATION AND AMORTISATION EXPENSE",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "0",
    },
    {
      Col1: "Particulars",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "Depreciation on Property Plant & Equipment",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Amortisation of Intangible Assets(Softure & Dev.Exp)",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Total",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Note No. 2.7",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "OTHERS EXPENSES",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Particulars",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "Manufacturing Expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Stores, Spares & tool consumed",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Power & Fuel",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Repairs & Maintenance",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "- Machinery",
      Col2: "- Building",
      Col3: "- Others",
    },
    {
      Col1: "A.",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "Administrative Expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Professioonal and Consultancy charges",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Insurance Charges",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Rates and Taxes",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Softure Licenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "General Administrative Expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Printing & Stationary",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Rent",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Telephone Expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Directors' Sitting Fees",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "CSR Expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Bad debts",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Tender deposits",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Doubtful advances",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Warranty",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Doubtful debts",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "B.",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Selling expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Carriage OutWard",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "C.",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "Total (A+B+C)",
      Col2: "0",
      Col3: "0",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "NOTE No. 2.8",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "FINANCE COST",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Particulars",
      Col2: "Dec 31 2017",
      Col3: "March 31 2017",
    },
    {
      Col1: "Intrest expenses",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "On Long Term Borrowing at Amortised Cost ",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Other Borrowing costs",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Other bank charges",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Net(gain)/Loss on foregion currency and translation",
      Col2: "",
      Col3: "",
    },
    {
      Col1: "Total",
      Col2: "0",
      Col3: "0",
    },
  ];

  const ExcelExportData8 = [
    {
      Col1: "XXXXXXXXXX",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Notes to Standalone Financial Statements for the Period Dec 2017",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Right of use assets",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "[Descriotion of leases ]",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Set out below are the carring amounts of right of use assets recognized and the movements during the period",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      col1: "",
      Col2: "Land",
      Col3: "Building",
      Col4: "Plant and equipment",
      Col5: "Furniture and fixtures",
      Col6: "Computer equipment ",
      Col7: "Office equipment",
      Col8: "Vehicles",
      Col9: "Total",
    },
    {
      Col1: "Net carrying amount",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Balance as at 01 April 2022",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Reversals(less)",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Depreciation",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Exchange difference on translation of foreign operations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Balance as at 30 june 2022",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Balance as at 01 April 2022",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Reversals(less)",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Deprecition(less",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Exchange differences on translation of foreign operations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Balance As at  31 March 2023",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Reversals(less)",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Depreciation(less)",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Exchange difference on translation of foreign operations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Balance as at 30 june 2023 ",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Set out below are the carrying of lease liabilities and the movement during the period",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "30 june 2023",
      Col8: "31 March 2023",
      Col9: "30 june 2022",
    },
    {
      Col1: "Balance at the beginning of the period ",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Additions",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Accretion of interest",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Payments",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Reversals",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Exchange difference on translation of foreign operations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Balance at the end of the period ",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Current",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },

    {
      Col1: "Non-Current ",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Dilapidations",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
    {
      Col1: "Difference",
      Col2: "",
      Col3: "",
      Col4: "",
      Col5: "",
      Col6: "",
      Col7: "",
      Col8: "",
      Col9: "",
    },
  ];

  return (
    <>
      <div>
        {/* <div className="tap-top">
          <i data-feather="chevrons-up"></i>
        </div> */}
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          {/* <Header /> */}
        <Navbar />

          {/* <div className="page-body-wrapper">
            <div className="sidebar-wrapper">
              <div>
                <div className="logo-wrapper">
                  <Link href="index.html">
                    <img className="img-fluid for-light" src={logo} alt="" />
                  </Link>
                </div>
                <div className="logo-icon-wrapper">
                  <Link href="index.html"></Link>
                </div>
              </div>
            </div>
          </div> */}
          <div style={header}>
            <div style={name}>
              <div
                style={backNav}
                // onClick={() => {
                //   history.push(`/preview`);
                // }}
              >
                <img src={arrowLeftGreen} style={{ marginRight: "10px" }} />
              </div>
            </div>
            <div style={line}></div>
            <div style={mainTag}>
              <input
                placeholder="Consolidated Financial Statement"
                style={{ border: "0", width: "20rem" }}
                // defaultValue={headerdata}
                // onChange={(e) => handleHeader(e)}
              />
            </div>
            <div style={button3} title="units conversion of the tb amount">
              <Select
                placeholder=""
                as="select"
                // options={units}
                isClearable
                // onChange={(e) => {
                //   setPrecision(e.value);
                // }}
                defaultInputValue="Units"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: "6px",
                  cursor: "pointer",
                  colors: {
                    ...theme.colors,
                    backgroundColor: "white",
                    primary25: "#03565a98",
                    primary: "#03565A",
                  },
                  spacing: {
                    ...theme.spacing,
                    controlHeight: 50,
                  },
                })}
              />
            </div>
            <div style={button2}>
              <Select
                placeholder="0"
                as="select"
                // options={decimal}
                isClearable
                // onChange={(e) => {
                //   setFloat(e.value);
                // }}
                defaultInputValue="2"
              />
            </div>
            <div style={button}>
              <div onClick={PopulateFS}>
                <Submit value="Populate FS" sm />
              </div>
            </div>
            <div style={button}>
              <div>
              <ExportExcel
                excelData={ExcelExportData}
                excelData2={ExcelExportData2}
                excelData3={ExcelExportData3}
                excelData4={ExcelExportData4}
                excelData5={ExcelExportData5}
                excelData6={ExcelExportData6}
                excelData7={ExcelExportData7}
                excelData8={ExcelExportData8}
                fileName="PFS-report"
              />
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="col-sm-12">
              {balanceSheet.loading ? (
                <CircularProgress disableShrink />
              ) : balanceSheet.isSuccess ? (
                <div class="card">
                  <div style={{ maxHeight: "77vh", overflowY: "scroll" }}>
                    <div style={{ minHeight: "77vh", width: "100vw" }}>
                      {balanceSheet.isSuccess ? (
                        <BSModalView
                          balanceSheetData={balanceSheet}
                          pnlStatementData={pnlStatement}
                          groupingData={groupingData}
                          filterData={filterData}
                          consoleData={consoldata}
                          allcheckdata={ allcheckdata}
                          currentCompProjectId={currentCompProjectId}
                          consoleNotes={notes}
                          id={id.id}
                          allIrListdata={allIrList}
                          allAssociateCheckData={allAssociateCheckData}
                          associateStatement={associateStatement}
                          CompanyName={CompanyName}
                          FindIr={FindIr}
                        />
                      ) : (
                        "-------"
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <p className="text-danger">{balanceSheet.error_message}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewFS;
