//Updated console balance sheet

import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import moment from "moment";
import {
  lineGroupStyle,
  totalStyle,
  totaledStyle,
  NoteStyle,
  tableDataCSs,
} from "../Styles/style";
import CircularProgress from "@mui/material/CircularProgress";
import {getProjectDetailById} from "../services/apiHandler";
import { useSelector, useDispatch } from "react-redux";
import { fetchAdjustment } from "../../../redux/actions/actionAdjustment";
import { getRandomKey, getCyPyData, getGroupName, setNoteNumber, prepareAdjustmentData, prepareAutoPopulationData, getNoteNumber, getPatternType, getScenario, manualAutoAdjustment } from "../../../helper/calculation";
import { FetchAutoPopulationData } from "../../../redux/actions/actionAutoPopulation";
import { getAllIR } from "../../../WorkSpace/Components/ApiServices/apiHandler";


const NewConsoleBalanceSheet = (props) => {
  const {
    balanceSheetData,
    setTempValue,
    currentCompany,
    subsiaryData,
    groupingData,
    currentCompProjectId,
    notes,
    setKey,
    setCurrentTab,
    CompanyName
  } = props;
  const [no, setNo] = useState([]);
  const [currentProjectName,setCurrentProjectName] = useState("");
  const dispatch = useDispatch();
  const AdjData = useSelector((state)=>state.ADJReducer);
  const preAdjustment = useSelector((state)=>state.PADJReducer.response);
  const preAdjustmentOther = useSelector((state)=>state.PADJReducer.otherResponse);
  const APAdjustment = useSelector((state) => state.APAReducer);
  const { loading, isSuccess, response, error_message } = balanceSheetData;
  const currentProjectBs = response?.data?.balance_sheet?.balance_sheet ?? [];
  const subsiary = response?.data?.balance_sheet?.balance_sheet ?? [];
  const ProjectIdsBalanceSheet =
    response?.data?.project_ids_bs?.map((data) => data.balance_sheet) ?? [];
  const fsRows = Object.keys(currentProjectBs);
  const fsSubRows = Object.keys(subsiary);
  const [allIrList, setAllIr] = useState([]);
  
  useEffect(()=>{
    if(!AdjData.isSuccess || AdjData.adjustment_id !== currentCompany._id){
      dispatch(fetchAdjustment(currentCompany._id));
    }
    if(!APAdjustment.isSuccess || APAdjustment.adjustment_id !== currentCompany._id){
      dispatch(FetchAutoPopulationData(currentCompany._id));
    }
  },[])

  useEffect(() => {
    getCurrentDataName(currentCompProjectId)
    setNo(setNoteNumber(fsRows, currentProjectBs));
    AllIrData();
  }, []);

  const getCurrentDataName=async(currentCompProjectId)=>{
    const res = await getProjectDetailById(currentCompProjectId);
    // console.log(res,"e33e333");
    setCurrentProjectName(res.data.project.project_name);
  }
  const AllIrData = async () => {
    const res = await getAllIR();
    if (res.status === 200) {
      setAllIr(res.data.fetchIR);
    }
  };
  const FindIr = (ir_id) => {
    const IrData = allIrList.filter((data) => data._id === ir_id);
    return IrData;
  };
  const browse_fs = subsiaryData.map((data) => FindIr(data.Ir_id));
          const browse_fs_ids = browse_fs.map(
            (data) => data[0]?.company
          );
  function handleClick(key) {
    setTempValue(key);
    setKey("Notes");
    setCurrentTab("bs");
  }

  const CurrentProjectCy = (group_name) => {
    return currentProjectBs[group_name].map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
  }
  const CurrentProjectPy = (group_name) => {
    return currentProjectBs[group_name].map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
  }

  const ProjectIDCy = (balance_sheet, group_name, project_index) => {
    const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData,project_index,FindIr);
    const result =  balance_sheet[group_name]?.map((data)=>data.cy).reduce((num, acc)=>num + acc, 0)
    if (first_scenario) {
      return result;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  }

  const ProjectIDPy = (balance_sheet, group_name, project_index) => {
    const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData,project_index,FindIr);
    const result = balance_sheet[group_name].map((data)=>data.py).reduce((num, acc)=>num + acc, 0)
    if (first_scenario) {
      return 0;
    } else if (second_scenario) {
      return result;
    } else {
      return result;
    }
  }
  
  const CurrentProjectTotalBsCy = (group_name, cy) => {
    try {
      const final_cy = ProjectIdsBalanceSheet.map((balance_sheet, project_index) =>
      ProjectIDCy(balance_sheet, group_name, project_index)
      );
      final_cy.push(cy);
      return final_cy.reduce((num, acc) => num + acc, 0);
    } catch (err) {
      console.log("total cy sum error", err);
      return "error";
    }
  };
  
  const CurrentProjectTotalBsPy = (group_name, py) => {
    try {
      const final_py = ProjectIdsBalanceSheet.map((balance_sheet, project_index) =>
      ProjectIDPy(balance_sheet, group_name, project_index)
      );
      final_py.push(py);
      return final_py.reduce((num, acc) => num + acc, 0);
    } catch (err) {
      console.log("total py sum error", err);
      return "error";
    }
  };

  const CalculateTotalNCACA = (type) => {
    try {
      const group_name_nca = "Non Current Assets";
      const group_name_ca = "Current Assets";
      let nca_result = 0
      let ca_result = 0
      if (type === "cy"){
        nca_result =  CurrentProjectCy(group_name_nca)
        ca_result =   CurrentProjectCy(group_name_ca)
      }else if(type==="py"){
        nca_result = CurrentProjectPy(group_name_nca)
        ca_result = CurrentProjectPy(group_name_nca)
      }
      return nca_result + ca_result
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }

  const CalculateTotalNCLCL = (type) => {
    try {
      const group_name_ncl = "Non Current Liabilities";
      const group_name_cl = "Current Liabilities";
      let ncl_result = 0;
      let cl_result = 0;
      if (type === "cy"){
        ncl_result = CurrentProjectCy(group_name_ncl)
        cl_result = CurrentProjectCy(group_name_cl)
      }else if(type==="py"){
        ncl_result = CurrentProjectPy(group_name_ncl)
        cl_result = CurrentProjectPy(group_name_cl)
      }
      return ncl_result + cl_result
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }


  const CalculateProjectIDsNCACA = (balance_sheet, project_index, type) => {
    try {
      const group_name_nca = "Non Current Assets";
      const group_name_ca = "Current Assets";
      let nca_result = 0
      let ca_result = 0
      if (type === "cy"){
        nca_result =  ProjectIDCy(balance_sheet, group_name_nca, project_index)
        ca_result =   ProjectIDCy(balance_sheet, group_name_ca, project_index)
      }else if(type==="py"){
        nca_result = ProjectIDPy(balance_sheet, group_name_nca, project_index)
        ca_result = ProjectIDPy(balance_sheet, group_name_ca, project_index)
      }
      return nca_result + ca_result
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }

  const CalculateProjectIDsNCLCL = (balance_sheet, project_index, type) => {
    try {
      const group_name_ncl = "Non Current Liabilities";
      const group_name_cl = "Current Liabilities";
      let ncl_result = 0
      let cl_result = 0
      if (type === "cy"){
        ncl_result =  ProjectIDCy(balance_sheet, group_name_ncl, project_index)
        cl_result =   ProjectIDCy(balance_sheet, group_name_cl, project_index)
      }else if(type==="py"){
        ncl_result = ProjectIDPy(balance_sheet, group_name_ncl, project_index)
        cl_result = ProjectIDPy(balance_sheet, group_name_cl, project_index)
      }
      return ncl_result + cl_result
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }

  const TotalNCACA = (type, current_project_ncaca) => {
    try {
      const result = ProjectIdsBalanceSheet.map((balance_sheet, project_index) => CalculateProjectIDsNCACA(balance_sheet, project_index, type)).reduce((num, acc)=>num+acc,0)
      return result + current_project_ncaca 
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }

  const TotalNCLCL = (type, current_project_ncaca) => {
    try {
      const result = ProjectIdsBalanceSheet.map((balance_sheet, project_index) => CalculateProjectIDsNCLCL(balance_sheet, project_index, type)).reduce((num, acc)=>num+acc,0)
      return result + current_project_ncaca 
    } catch (error) {
      console.log("error", error.message)
      return 0
    }
  }

  const ProjectIdBsCy = (
    project_index,
    project_bs,
    group_name,
    note_index
  ) => {
    try {
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData,project_index,FindIr);
      const result = project_bs[group_name][note_index].cy;
      if (first_scenario) {
        return result;
      } else if (second_scenario) {
        return result;
      } else {
        return result;
      }
    } catch (error) {
      console.log("error", error.message)
      return 0;
    }
    
  };
  
  const ProjectIdBsPy = (
    project_index,
    project_bs,
    group_name,
    note_index
  ) => {
    try {
      const { first_scenario, second_scenario } = getScenario(currentCompany, subsiaryData,project_index,FindIr);
      const result = project_bs[group_name][note_index].py;
      if (first_scenario) {
        return 0;
      } else if (second_scenario) {
        return result;
      } else {
        return result;
      }
    } catch (error) {
      console.log("error", error.message)
      return 0;
    }
    
  };
  
  const ProjectIdTotalBsCy = (group_name, row_index, line_cy) => {
    try {
      const res = ProjectIdsBalanceSheet.map((data, index) =>
        ProjectIdBsCy(index, data, group_name, row_index)
      );
      return res.reduce((data, acc) => data + acc, 0) + line_cy;
    } catch (err) {
      // console.log("total cy sum error", err);
      return "0 err";
    }
  };
  
  const ProjectIdTotalBsPy = (group_name, row_index, line_py) => {
    try {
      const res = ProjectIdsBalanceSheet.map((data, index) =>
        ProjectIdBsPy(index, data, group_name, row_index)
      );
      return res.reduce((data, acc) => data + acc, 0) + line_py;
    } catch (err) {
      console.log("total cy sum error", err);
      return "0 err";
    }
  };
  
  const getIndexNumber = (line) => {
    try{
      let index = no.findIndex((note) => note?.notes_grp.toLowerCase() === line?.notes_grp.toLowerCase());
      return index += 2

    }catch(err){
      console.log("error", err.message)
      return "00"
    }
    
  }
  //new

 const groupDataCal = (note_no) =>{
    if(groupingData.isSuccess === true){
      const GroupData = groupingData.response.data;
      const result = getCyPyData(GroupData.current_company_grouping.fs_grp, note_no);
      const projectsGroup = GroupData.project_ids_grouping.map((data)=> getCyPyData(data.fs_grp, note_no))
      const browsFsDAta = GroupData.browse_fs_grouping.map((data)=> getCyPyData(data.fs_grp, note_no))
      return {
        currentProject: result,
        Projects: projectsGroup,
        BrowsFsData: browsFsDAta
      }
    }
  }

  const  adjustmentResult=(groupDataCal, adjustmentData, autoPopulateData, group_index, scenario, project_index, showManualOnly, pattern_type, groupName)=>{
    try {
     const { BrowsFsData } = groupDataCal;
      const scenarioResult = subsiaryData.map((sub_data, sub_index) => { return {scenario: getScenario(currentCompany, subsiaryData,sub_index,FindIr),
          brows_fs: BrowsFsData[sub_index][group_index][project_index],
          goodwill:sub_data?.all_data?.console_entry[0]?.goodwill?sub_data?.all_data?.console_entry[0]?.goodwill:0,
          non_controlling_interest:sub_data?.all_data?.console_entry[0]?.non_controlling_interest?sub_data?.all_data?.console_entry[0]?.non_controlling_interest:0,
          investment:sub_data?.all_data?.console_entry[0]?.investment?sub_data?.all_data?.console_entry[0]?.investment:0,
          otherinputs:sub_data?.all_data?.console_entry[0]?.otherinputs?sub_data?.all_data?.console_entry[0]?.otherinputs:0,
          adjustment: adjustmentData?.filter((adj)=>adj.company_id === sub_data?.all_data?._id),
          autoPopulate: autoPopulateData?.filter((adj)=> adj.company_id === sub_data?.all_data?._id)
      }});
      const browsDataRes = scenarioResult.filter(data=>data.brows_fs !== undefined);
      const browsDataRes_other = scenarioResult.filter(data=>data);
      if(browsDataRes.length === 0){
        return 0
      }
      const pre_adjustment =  getPreAdjustment(scenarioResult, browsDataRes, browsDataRes_other, scenario, pattern_type, groupName);
      const manual_auto_adjustment = manualAutoAdjustment(scenarioResult, browsDataRes_other, scenario);
      const signChangeGroup = ["Equity", "Non Current Liabilities", "Current Liabilities", "Income"];
      // console.log("groupname", groupName, manual_auto_adjustment)
      if(signChangeGroup.includes(groupName)){
        return pre_adjustment + (manual_auto_adjustment  * -1);
      }
      return pre_adjustment + manual_auto_adjustment;
    } catch (error) {
      // console.log("err adjustmentResult",error.message);
      return 0
    }
  }

  const getPreAdjustment = (scenarioResult, browsDataRes, browsDataRes_other, scenario, pattern_type, groupName) => {
    let first_scenario_data = 0;
      let second_scenario_data = 0;
      let third_scenario_data = 0;

      if(preAdjustment.includes(browsDataRes[0].brows_fs.group)){
        const first_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario === true);
        const first_result =  first_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
        first_scenario_data += first_result;
      
        const second_scenario_res = scenarioResult.filter((data)=>data.scenario.second_scenario === true);
        const second_result = second_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
        second_scenario_data += second_result;
        first_scenario_data += second_result;
      
        const third_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
        const third_result = third_scenario_res.map(data=>data.brows_fs.cy_amt).reduce((data, num)=>data + num, 0);
        third_scenario_data += third_result;
        second_scenario_data += third_result;
        first_scenario_data += third_result;
      }else if (preAdjustmentOther.map(item=>item.sub_group).includes(browsDataRes[0].brows_fs.group)){
        const cal_data = preAdjustmentOther.find(item=>item.sub_group === browsDataRes[0].brows_fs.group).cal_data;
        const first_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario === true);
        const first_result = first_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
        first_scenario_data += first_result;
      
        const second_scenario_res = scenarioResult.filter((data)=>data.scenario.second_scenario === true);
        const second_result = second_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
        second_scenario_data += second_result;
        first_scenario_data += second_result;
      
        const third_scenario_res = scenarioResult.filter((data)=>data.scenario.first_scenario !== true && data.scenario.second_scenario !== true);
        const third_result = third_scenario_res.map(data=>Number(data[cal_data])).reduce((data, num)=>data + num, 0);
        third_scenario_data += third_result;
        second_scenario_data += third_result;
        first_scenario_data += third_result;
      }
      switch(scenario){
        case 1:{
          if(pattern_type === "pattern_2"){
            return first_scenario_data
          }else{
            if(browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted"){
              return first_scenario_data;
            }
            else{
                return first_scenario_data * -1;
            }
          }
        }  
        case 2:{
          if(pattern_type === "pattern_2"){
            return second_scenario_data
          }else{
            if(browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted"){
              return second_scenario_data;
            }else{
              return second_scenario_data * -1;
            }
          }
        }
        case 3:{
          if(pattern_type === "pattern_2"){
            return third_scenario_data
          }else{
            if(browsDataRes_other[0].brows_fs.group === "Goodwill" || browsDataRes_other[0].brows_fs.group === "Brands/trademarks" || browsDataRes_other[0].brows_fs.group === "Investments in Equity Instruments - Unquoted"){
              return third_scenario_data;
            }else{
              return third_scenario_data * -1;;
            }
          }
        }
        default:
          return 0
      }
  }

  const totalAdjustment = (note_group_index, note_group, scenario, note_index)=>{
    try{
      const GroupData = groupingData.response.data;
      if(note_group_index === 2){
        let note_no = getNoteNumber(notes, note_index);
        if(note_no === "--"){return "...";}
        const groupResult = groupDataCal(note_no);
        const Groups = getGroupName(GroupData.current_company_grouping.fs_grp, note_no);
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(APAdjustment, note_no);
        const { currentProject } = groupResult;
        const finalResult = currentProject.map((projectGroup, group_index)=> projectGroup.map((data, project_index)=> 
          adjustmentResult(groupResult, adjustmentData, autoPopulateData, group_index, scenario, project_index, false, getPatternType(notes, note_no), Groups[0])
        ).reduce((data,  num) => data + num, 0))
        return finalResult.reduce((num, acc)=> Number(num) + acc, 0);
        //note_group === "Other Intangible Assets" || note_group === "Goodwill" ||
      }
      else if(note_group_index === 0){
        let note_no = getNoteNumber(notes, note_index);
        if(note_no === "--"){return "...";}
        const Groups = getGroupName(GroupData.current_company_grouping.fs_grp, note_no);
        const groupResult = groupDataCal(note_no);
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(APAdjustment, note_no);
        const { currentProject } = groupResult;
        const finalResult = currentProject.map((projectGroup, group_index)=>
        {
          const nca = Groups[group_index];
          if(nca === "Non Current Assets"){
            return projectGroup.map((data, project_index)=> {
              const result = adjustmentResult(groupResult, adjustmentData, autoPopulateData, group_index, scenario, project_index,false, getPatternType(notes, note_no), nca);
              return result
            }).reduce((data, num)=> data + num, 0)
          }else{
            return 0;
          }
        })
        return finalResult.reduce((num, acc)=> num + acc, 0) 
      }
      else if(note_group_index === 1){
        let note_no = getNoteNumber(notes, note_index);
        if(note_no === "--"){return "...";}
        const Groups = getGroupName(GroupData.current_company_grouping.fs_grp, note_no);
        const groupResult = groupDataCal(note_no);
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(APAdjustment, note_no);
        const { currentProject } = groupResult;
        const finalResult = currentProject.map((projectGroup, group_index)=>
        {
          const nca = Groups[group_index];
          if(nca === "Current Assets"){
            return projectGroup.map((data, project_index)=> {
              const result = adjustmentResult(groupResult, adjustmentData, autoPopulateData, group_index, scenario, project_index,true, getPatternType(notes, note_no), nca);
              return result
            }).reduce((data, num)=> data + num, 0)
          }else{
            return 0;
          }
        })
        return finalResult.reduce((num, acc)=> num + acc, 0)
      }else if(note_group_index === 4){
        let note_no = getNoteNumber(notes, note_index);
        if(note_no === "--"){return "...";}
        const Groups = getGroupName(GroupData.current_company_grouping.fs_grp, note_no);
        const groupResult = groupDataCal(note_no);
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(APAdjustment, note_no);
        const { currentProject } = groupResult;
        const finalResult = currentProject.map((projectGroup, group_index)=>
        {
          const nca = Groups[group_index];
          if(nca === "Current Liabilities"){
            return projectGroup.map((data, project_index)=> {
              const result = adjustmentResult(groupResult, adjustmentData, autoPopulateData, group_index, scenario, project_index,true, getPatternType(notes, note_no), nca);
              return result
            }).reduce((data, num)=> data + num, 0)
          }else{
            return 0;
          }
        })
        return finalResult.reduce((num, acc)=> num + acc, 0)
      }
      else if(note_group_index === 3){
        let note_no = getNoteNumber(notes, note_index);
        if(note_no === "--"){return "...";}
        const Groups = getGroupName(GroupData.current_company_grouping.fs_grp, note_no);
        const groupResult = groupDataCal(note_no);
        const adjustmentData = prepareAdjustmentData(AdjData, note_no);
        const autoPopulateData = prepareAutoPopulationData(APAdjustment, note_no);
        const { currentProject } = groupResult;
        const finalResult = currentProject.map((projectGroup, group_index)=>
        {
          const nca = Groups[group_index];
          if(nca === "Non Current Liabilities"){
            return projectGroup.map((data, project_index)=> {
              const result = adjustmentResult(groupResult, adjustmentData, autoPopulateData, group_index, scenario, project_index,true, getPatternType(notes, note_no), nca);
              return result
            }).reduce((data, num)=> data + num, 0)
          }else{
            return 0;
          }
        })
        return finalResult.reduce((num, acc)=> num + acc, 0)
      }
      else{
        return "--"
      }
    }catch(err){
      console.log("err total adj", err.message);
      return "!!"
    }
  }

  const totalAdjustmentNCA = (group, scenario) => {
    const GroupSetData = [
      {"index":0, "group":"Non Current Assets"}, 
      {"index":1, "group":"Current Assets"}, 
      {"index":2, "group":"Equity"},
      {"index":3, "group":"Non Current Liabilities"},
      {"index":4, "group":"Current Liabilities"},
    ]
    try {
      if(GroupSetData?.map(item=>item.group).includes(group)){
        const GroupData = groupingData?.response?.data?.current_company_grouping?.fs_grp;
        const NCAGroupOnly = GroupData?.filter(data => data?.fs_grp === group);
        const finalResult = NCAGroupOnly[0]?.notes_grp.map((ncaGroup) => {
          const res = totalAdjustment(GroupSetData.find(item=>item.group===group).index, ncaGroup.notes_grp, scenario, getIndexNumber(ncaGroup))
          if(isNaN(res))return 0;
          else return res; 
        })
        return finalResult.reduce((num, acc) => Number(num) + acc, 0)
      }else{
        return 0;
      }
    } catch (error) {
      console.log("error", error.message);
      return 0;
    }
  }

  const totalEquityValue =(group_name,type)=>{
    const group_name_ncl = "Equity";
    if (type === "cy") {
      const ncl_result = CurrentProjectCy(group_name_ncl)
      return ncl_result;
    }else if(type === "py"){
      const ncl_result = CurrentProjectPy(group_name_ncl)
      return ncl_result;
    }
   
  }

  const totalEquityIDs =(balance_sheet, project_index,type)=>{
    const group_name_ncl = "Equity";
    if (type === "cy") {
      const nca_result =  ProjectIDCy(balance_sheet, group_name_ncl, project_index)
      return nca_result;
    }else if(type === "py"){
      const nca_result =  ProjectIDPy(balance_sheet, group_name_ncl, project_index)
      return nca_result;
    }
   
  }

  const TotalEquityLiability = (type,current_project_ncaca,totalValue)=>{
    const projectIds = ProjectIdsBalanceSheet.map((balance_sheet, project_index) => CalculateProjectIDsNCLCL(balance_sheet, project_index, type)).reduce((num, acc)=>num+acc,0)
    const TotalEquity = ProjectIdsBalanceSheet.map((balance_sheet, project_index) => totalEquityIDs(balance_sheet, project_index, type)).reduce((num, acc)=>num+acc,0)
    return current_project_ncaca + projectIds + TotalEquity + totalValue;
  }
 
  const handleNan = (res) => {
    if(isNaN(res)){
      return 0;
    }else{
      return res;
    }
  }

  return (
    <>
      {loading ? (
        <CircularProgress disableShrink />
      ) : isSuccess ? (
        <>
          <div className="">
            <table style={{ width: "100%", tableLayout:"fixed" }}>
            <TableHeader currentCompany={currentCompany} subsiaryData={subsiaryData} currentProjectName={currentProjectName} browse_fs_ids={browse_fs_ids} CompanyName={CompanyName}/>
              <tbody>
                { fsRows.map((group, groupIndex) => (
                  <React.Fragment>
                    <tr style={totaledStyle}>
                      <td>{group}</td>
                      <td>{}</td>
                      <td>{CurrentProjectCy(group).toLocaleString("en-IN")}</td>
                      <td>{CurrentProjectPy(group).toLocaleString("en-IN")}</td>
                      {ProjectIdsBalanceSheet.length > 0 &&
                        ProjectIdsBalanceSheet.map((balance_sheet, project_index) => (
                          <>
                          <td>{ProjectIDCy(balance_sheet, group, project_index).toLocaleString("en-IN")}</td>
                          <td>{ProjectIDPy(balance_sheet, group, project_index).toLocaleString("en-IN")}</td>
                        </>
                        ))
                      }
                      <td>{totalAdjustmentNCA(group, 1).toLocaleString("en-IN")}</td>
                      <td>{totalAdjustmentNCA(group, 2).toLocaleString("en-IN")}</td>
                      <td>{totalAdjustmentNCA(group, 3).toLocaleString("en-IN")}</td>
                      <td>{(CurrentProjectTotalBsCy(group, CurrentProjectCy(group)) + totalAdjustmentNCA(group, 1)).toLocaleString("en-IN")}</td>
                      <td>{(CurrentProjectTotalBsPy(group, CurrentProjectPy(group)) + totalAdjustmentNCA(group, 2)).toLocaleString("en-IN")}</td>
                      <td></td>
                    </tr>
                   {currentProjectBs[group].map((noteGroup, noteGroupIndex) => (
                    <tr style={lineGroupStyle}>
                      <td>{noteGroup.notes_grp}</td>
                      <td style={NoteStyle} onClick={()=>handleClick(Number(getIndexNumber(noteGroup)))}>{ getIndexNumber(noteGroup) }</td>
                      <td>{noteGroup?.cy.toLocaleString("en-IN")}</td>
                      <td>{noteGroup?.py.toLocaleString("en-IN")}</td>
                      {ProjectIdsBalanceSheet.length > 0 &&
                        ProjectIdsBalanceSheet.map((balance_sheet, project_index) => (
                          <>
                          <td>{ProjectIdBsCy(project_index, balance_sheet, group, noteGroupIndex).toLocaleString("en-IN")}</td>
                          <td>{ProjectIdBsPy(project_index, balance_sheet, group, noteGroupIndex).toLocaleString("en-IN")}</td>
                        </>
                        ))
                      }
                      <td>{totalAdjustment(groupIndex,noteGroup.notes_grp, 1, getIndexNumber(noteGroup)).toLocaleString("en-IN")} </td>
                      <td>{totalAdjustment(groupIndex,noteGroup.notes_grp, 2, getIndexNumber(noteGroup)).toLocaleString("en-IN")} </td>
                      <td>{totalAdjustment(groupIndex,noteGroup.notes_grp, 3, getIndexNumber(noteGroup)).toLocaleString("en-IN")}</td>
                      <td>{(
                        ProjectIdTotalBsCy(group, noteGroupIndex, noteGroup?.cy) 
                        + 
                        handleNan(totalAdjustment(groupIndex,noteGroup.notes_grp, 1, getIndexNumber(noteGroup))) 
                        ).toLocaleString("en-IN")}</td>
                      <td>{(
                            ProjectIdTotalBsPy(group, noteGroupIndex, noteGroup?.py)
                             + 
                            handleNan(totalAdjustment(groupIndex,noteGroup.notes_grp, 2, getIndexNumber(noteGroup)))
                          ).toLocaleString("en-IN")}</td>
                      <td></td>
                    </tr>
                  ))}
                 { groupIndex === 1 
                  ? <React.Fragment>
                    <tr style={totalStyle}>
                      <td>{"Total Non Current & Current Assets"}</td>
                      <td></td>
                      <td>{CalculateTotalNCACA("cy").toLocaleString("en-IN")}</td>
                      <td>{CalculateTotalNCACA("py").toLocaleString("en-IN")}</td>
                      {ProjectIdsBalanceSheet.length > 0 &&
                        ProjectIdsBalanceSheet.map((balance_sheet, project_index) => (
                          <>
                          <td>{CalculateProjectIDsNCACA(balance_sheet, project_index, "cy").toLocaleString("en-IN")}</td>
                          <td>{CalculateProjectIDsNCACA(balance_sheet, project_index, "py").toLocaleString("en-IN")}</td>
                        </>
                        ))
                      }
                      <td>{(totalAdjustmentNCA("Non Current Assets", 1) + totalAdjustmentNCA("Current Assets", 1)).toLocaleString("en-IN")}</td>
                      <td>{(totalAdjustmentNCA("Non Current Assets", 2) + totalAdjustmentNCA("Current Assets", 2)).toLocaleString("en-IN")}</td>
                      <td>{(totalAdjustmentNCA("Non Current Assets", 3) + totalAdjustmentNCA("Current Assets", 3)).toLocaleString("en-IN")}</td>
                      <td>
                        {
                        (TotalNCACA("cy", CalculateTotalNCACA("cy")) 
                          + 
                        (totalAdjustmentNCA("Non Current Assets", 1) + totalAdjustmentNCA("Current Assets", 1))
                        ).toLocaleString("en-IN")
                        }
                      </td>
                      <td>{
                        (TotalNCACA("py", CalculateTotalNCACA("py"))
                        +
                        (totalAdjustmentNCA("Non Current Assets", 2) + totalAdjustmentNCA("Current Assets", 2))
                        ).toLocaleString("en-IN")
                        }
                      </td>
                      <td></td>
                    </tr>
                  </React.Fragment>
                  : groupIndex === 4 
                  ? <React.Fragment>
                  <tr style={totalStyle}>
                    <td>{"Total Non Current & Current Liabilities"}</td>
                    <td></td>
                    <td>{CalculateTotalNCLCL("cy").toLocaleString("en-IN")}</td>
                    <td>{CalculateTotalNCLCL("py").toLocaleString("en-IN")}</td>
                    {ProjectIdsBalanceSheet.length > 0 &&
                      ProjectIdsBalanceSheet.map((balance_sheet, project_index) => (
                        <>
                        <td>{CalculateProjectIDsNCLCL(balance_sheet, project_index, "cy").toLocaleString("en-IN")}</td>
                        <td>{CalculateProjectIDsNCLCL(balance_sheet, project_index, "py").toLocaleString("en-IN")}</td>
                      </>
                      ))
                    }
                    <td>{(totalAdjustmentNCA("Non Current Liabilities", 1) + totalAdjustmentNCA("Current Liabilities", 1)).toLocaleString("en-IN")}</td>
                    <td>{(totalAdjustmentNCA("Non Current Liabilities", 2) + totalAdjustmentNCA("Current Liabilities", 2)).toLocaleString("en-IN")}</td>
                    <td>{(totalAdjustmentNCA("Non Current Liabilities", 3) + totalAdjustmentNCA("Current Liabilities", 3)).toLocaleString("en-IN")}</td>
                    <td>
                      {(TotalNCLCL("cy", CalculateTotalNCLCL("cy"))
                        +
                        (totalAdjustmentNCA("Non Current Liabilities", 1) + totalAdjustmentNCA("Current Liabilities", 1))
                      ).toLocaleString("en-IN")}
                    </td>
                    <td>
                      {
                      (TotalNCLCL("py", CalculateTotalNCLCL("py"))
                      +
                      (totalAdjustmentNCA("Non Current Liabilities", 2) + totalAdjustmentNCA("Current Liabilities", 2))
                      ).toLocaleString("en-IN")
                      }
                    </td>
                    <td></td>
                  </tr>

                  <tr style={totalStyle}>
                    <td>{"Total Equity & Liabilities"}</td>
                    <td></td>
                    <td>{(CalculateTotalNCLCL("cy") + totalEquityValue(group,"cy")).toLocaleString("en-IN") }</td>
                    <td>{(CalculateTotalNCLCL("py") + totalEquityValue(group,"py")).toLocaleString("en-IN") }</td>
                    {ProjectIdsBalanceSheet.length > 0 &&
                      ProjectIdsBalanceSheet.map((balance_sheet, project_index) => (
                        <>
                        <td>{(CalculateProjectIDsNCLCL(balance_sheet, project_index, "cy") + totalEquityIDs(balance_sheet, project_index, "cy")).toLocaleString("en-IN")}</td>
                        <td>{(CalculateProjectIDsNCLCL(balance_sheet, project_index, "py") + totalEquityIDs(balance_sheet, project_index, "py")).toLocaleString("en-IN")}</td>
                      </>
                      ))
                    }
                    <td>{( totalAdjustmentNCA("Equity", 1) + totalAdjustmentNCA("Non Current Liabilities", 1) + totalAdjustmentNCA("Current Liabilities", 1)).toLocaleString("en-IN")}</td>
                    <td>{( totalAdjustmentNCA("Equity", 2) + totalAdjustmentNCA("Non Current Liabilities", 2) + totalAdjustmentNCA("Current Liabilities", 2)).toLocaleString("en-IN")}</td>
                    <td>{( totalAdjustmentNCA("Equity", 3) + totalAdjustmentNCA("Non Current Liabilities", 3) + totalAdjustmentNCA("Current Liabilities", 3)).toLocaleString("en-IN")}</td>
                    <td>{(TotalEquityLiability("cy",CalculateTotalNCLCL("cy"),totalEquityValue(group,"cy")) + 
                      ( totalAdjustmentNCA("Equity", 1) + totalAdjustmentNCA("Non Current Liabilities", 1) + totalAdjustmentNCA("Current Liabilities", 1))
                    ).toLocaleString("en-IN")}</td>
                    <td>
                      {
                      (TotalEquityLiability("py",CalculateTotalNCLCL("py"),totalEquityValue(group,"py"))
                      +
                      ( totalAdjustmentNCA("Equity", 2) + totalAdjustmentNCA("Non Current Liabilities", 2) + totalAdjustmentNCA("Current Liabilities", 2))
                      ).toLocaleString("en-IN")
                      }
                      </td>
                    <td></td>
                  </tr>
                </React.Fragment>
                  :
                  ""
                   }
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </>
      ) : (
        <p className="text-danger">
          {error_message || "Something went wrong!!!"}
        </p>
      )}
    </>
  );
}

export default NewConsoleBalanceSheet;


const TableHeader = (props) => {
  const { currentCompany, subsiaryData,currentProjectName,browse_fs_ids,CompanyName } = props;
  
  const colStyle = { 
    width: "25%",
    textTransform: "capitalize" 
  }
  const particlarTab = { 
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }
  const noteCol = { 
    width: "100px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }
  const thBgColor = { backgroundColor: "#A2C0C2" }

  return (
    <thead>
      <tr style={thBgColor}>
        <th
          rowspan="2"
          style={particlarTab}
        >
          <strong>Particulars </strong>
        </th>
        <th rowspan="2" style={noteCol}>
          <strong>Notes</strong>
        </th>
        <th colspan="2" style={particlarTab}>
          <strong className="text-center">{currentProjectName}</strong>
        </th>

        {browse_fs_ids.length > 0 &&
          browse_fs_ids.map((subData, index) => (
            <th colSpan={2} style={particlarTab}>
              <strong>{CompanyName(subData) ?? "Name Not found"}</strong>
            </th>
          ))}
        <th colspan="3" style={particlarTab}>
          <strong>ADJUSTMENT</strong>
        </th>
        <th colspan="3" style={particlarTab}>
          <strong>TOTAL</strong>
        </th>
      </tr>

      <tr style={thBgColor}>
        <th style={{ width: "15%" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ Width: "25%" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        {subsiaryData.length > 0 &&
          subsiaryData.map((row, i) => (
            <>
              <th style={{ width: "15%" }}>
                {dateFormat(
                  currentCompany.current_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ Width: "25%" }}>
                {dateFormat(
                  currentCompany.previous_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
            </>
          ))}

        <th style={{ width: "25%" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ Width: "25%" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ Width: "25%" }}>OPENING</th>
        <th style={{ width: "25%" }}>
          {dateFormat(currentCompany.current_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ Width: "25%" }}>
          {dateFormat(currentCompany.previous_financial_year, "mmmm dS, yyyy")}
        </th>
        <th style={{ Width: "25%" }}>OPENING</th>
      </tr>
    </thead>
  );
}