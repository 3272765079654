import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableHeaderAdjustment from "./adjustmentTableHeader";
import AdjustmentData from "./adjustmentData";
import moment from "moment";
import AutoPopulate from "./adjustmentAutoPopulate";

function TabPanel(props) {
  const {
    adjustmentData,
    allsubdata,
    handleSave,
    handleChange,
    adjustment,
    children,
    value,
    index,
    ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs(props) {
  const {
    adjustmentData,
    allsubdata,
    handleSave,
    adjustment,
    handleChange,
    dataRowOpen,
    currentCompany,
    adjCompanyData,
    handleDelete,
    subsiaryData,
    handleChangeDropDown,
    hideNewMOdal,
    setAdjustmentData,
    groupingData,
    notes,
    id,
  } = props;
  const [value, setValue] = React.useState(0);


  const heading = {
    color: "var(--clr-accent)",
    fontWeight: "700",
  };

  const handleChange_data = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange_data}
          aria-label="basic tabs example"
        >
          <Tab label="Current Year" {...a11yProps(0)} />
          <Tab label="Previous Year" {...a11yProps(1)} />
          <Tab label="Opening" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <table>
          <TableHeaderAdjustment
            handleSave={handleSave}
            handleChange={handleChange}
            allsubdata={allsubdata}
            adjustment={adjustment}
            button={true}
            dataRowOpen={dataRowOpen}
            currentCompany={currentCompany}
            adjCompanyData={adjCompanyData}
            handleDelete={handleDelete}
            subsiaryData={subsiaryData}
            handleChangeDropDown={handleChangeDropDown}
            hideNewMOdal={hideNewMOdal}
            scenario="current_yr"
          />
          <tbody>
            {adjustmentData[0] !== undefined && adjustmentData[0]?.map((adjustData, adjustindex) => {
              return (
                <>
                {adjustData.status === "current_yr" ?
                  <AdjustmentData
                    allsubdata={allsubdata}
                    adjustData={adjustData}
                    index={adjustindex + 1}
                    adjCompanyData={adjCompanyData}
                    currentCompany={currentCompany}
                    setAdjustmentData={setAdjustmentData}
                  />
                :
                  ""
                }
                </>
                
              );
            })}
          </tbody>
        </table>
          <div style={{ marginLeft: "1rem" }}>
            <p style={heading}>AutoPopulation</p>
          </div>
          <AutoPopulate key={1}
            currentCompany={currentCompany}
            subsiaryData={subsiaryData}
            groupingData={groupingData}
            notes={notes}
            scenario="current_yr"
          />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <table>
          <TableHeaderAdjustment
            handleSave={handleSave}
            handleChange={handleChange}
            allsubdata={allsubdata}
            adjustment={adjustment}
            button={true}
            dataRowOpen={dataRowOpen}
            currentCompany={currentCompany}
            adjCompanyData={adjCompanyData}
            handleDelete={handleDelete}
            subsiaryData={subsiaryData}
            handleChangeDropDown={handleChangeDropDown}
            hideNewMOdal={hideNewMOdal}
            scenario="previous_yr"
          />
          <tbody>
            {adjustmentData[0] !== undefined && adjustmentData[0].map((adjustData, adjustindex) => {
              return (
                <>
                {adjustData.status === "previous_yr" ?
                <AdjustmentData
                  allsubdata={allsubdata}
                  adjustData={adjustData}
                  index={adjustindex + 1}
                  adjCompanyData={adjCompanyData}
                  currentCompany={currentCompany}
                  setAdjustmentData={setAdjustmentData}
                />
                :
                ""}
                </>
                
              );
            })}
          </tbody>
        </table>
          <div style={{ marginLeft: "1rem" }}>
            <p style={heading}>AutoPopulation</p>
          </div>
          <AutoPopulate key={2}
            currentCompany={currentCompany}
            subsiaryData={subsiaryData}
            groupingData={groupingData}
            notes={notes}
            scenario="previous_yr"

          />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <table>
          <TableHeaderAdjustment
            handleSave={handleSave}
            handleChange={handleChange}
            allsubdata={allsubdata}
            adjustment={adjustment}
            button={true}
            dataRowOpen={dataRowOpen}
            currentCompany={currentCompany}
            adjCompanyData={adjCompanyData}
            handleDelete={handleDelete}
            subsiaryData={subsiaryData}
            handleChangeDropDown={handleChangeDropDown}
            hideNewMOdal={hideNewMOdal}
            scenario="opening"
          />
          <tbody>
            {adjustmentData[0] !== undefined && adjustmentData[0].map((adjustData, adjustindex) => {
              return (
                <>
                 {adjustData.status === "opening" ?
                  <AdjustmentData
                  allsubdata={allsubdata}
                  adjustData={adjustData}
                  index={adjustindex + 1}
                  adjCompanyData={adjCompanyData}
                  currentCompany={currentCompany}
                  setAdjustmentData={setAdjustmentData}
                  subsiaryData={subsiaryData}
                />
                :
                ""}
                </>
                
              );
            })}
          </tbody>
        </table>
          <div style={{ marginLeft: "1rem" }}>
            <p style={heading}>AutoPopulation</p>
          </div>
          <AutoPopulate key={3}
            currentCompany={currentCompany}
            subsiaryData={subsiaryData}
            groupingData={groupingData}
            notes={notes}
            scenario="opening"
            id={id}
          />
      </TabPanel>
    </Box>
  );
}
