import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import moment from "moment";
import { lineGroupStyle, totalStyle, totaledStyle, NoteStyle } from "../Styles/style";
import { CircularProgress } from "@mui/material";


function ConsolePnlStatement(props) {
  const  { pnlStatementData, subsiaryData, currentCompany, headingData, setCurrentProfit, setCurrentYearProfit, setComYearProfit, currentProfit, setComProfit } = props;
  const { loading, isSuccess, response, error_message  } = pnlStatementData;

  // const subsiary= response?.data?.pnl_statement?.pnl_statement ?? []
  const sybsidiary=[response?.data?.pnl_statement?.pnl_statement] ?? []
  const finalSubsidiaryData=response?.data?.project_ids_pnl?.map((data) => data.pnl_statement) ?? []
  const finalBrowsFsData= response?.data?.browse_fs_pnl?.map((data) => data.pnl_statement) ?? []
  const browsFs= response.data?.pnl_statement?.pnl_statement ?? [];
  const rows = response.data?.pnl_statement?.pnl_statement ?? [];
  const rowssubsidiary =response?.data?.pnl_statement?.pnl_statement ?? [];
  const browseData = browsFs;
  const [no, setNo] = useState([]);
  const fsRows = Object.keys(rows);
 
  useEffect(() => {
    let noArr = [];
    fsRows.map((row, i) => {
      rows[row].map((line, ix) => {
        if (
          noArr.length > 0 &&
          noArr.some(
            (user) =>
              user.notes_grp.toLowerCase() === line.notes_grp.toLowerCase()
          )
        ) {
        } else {
          noArr.push({ notes_grp: line.notes_grp });
        }
      });
    });
    setNo(noArr);
  }, [rows]);

  function handleSelect(key) {
    // props.setKey('Notes2')
    // props.scrollToTop()
    // props.setNote(key)
    props.setTempValue(key + 46);
    // dispatch({type: actionTypes.SET_PLNO,payload:key})
  }

  const calculatePnlCy = (subsiary_index, subsiary_ele, final_sub_index, row_index) => {
    const { first_scenario, second_scenario } = getScenario(subsiary_index)
    const result_sub = subsiary_ele[final_sub_index][row_index].cy;
    const result_brows = finalBrowsFsData[subsiary_index][final_sub_index][row_index].cy;
    if(first_scenario){
      return result_sub-result_brows
    }else if(second_scenario){
      return result_sub
    }else{
      return result_sub
    }
  }


  const calculatePnlPy = (subsiary_index, subsiary_ele, final_sub_index, row_index) => {
    const { first_scenario, second_scenario } = getScenario(subsiary_index)
    const result_sub = subsiary_ele[final_sub_index][row_index].py;
    const result_brows = finalBrowsFsData[subsiary_index][final_sub_index][row_index].cy;
    if(first_scenario){
      return 0
    }else if(second_scenario){
      return result_sub - result_brows
    }else{
      return result_sub
    }
  }

  const totalSubCyPnl = (currentData, final_sub_index, row_index)=>{
    try {
      const final_cy = finalSubsidiaryData.map((ele, index) => calculatePnlCy(index, ele, final_sub_index, row_index))
      final_cy.push(currentData)
      return final_cy.reduce((num, acc) => num + acc, 0)
    } catch (err) {
      console.log("total cy sum error", err);
      return "error"
    }
  }

  const totalSubPyPnl = (currentData, final_sub_index, row_index)=>{
    try {
      const final_py = finalSubsidiaryData.map((ele, index) => calculatePnlPy(index, ele, final_sub_index, row_index))
      final_py.push(currentData)
      return final_py.reduce((num, acc) => num + acc, 0)
    } catch (err) {
      console.log("total cy sum error", err);
      return "error"
    }
  }
  


  const getScenario = (index) => {
    let currentDate = moment(
      currentCompany.current_financial_year
    ).format("YYYY-04-01");
    let previousDate = moment(
      currentCompany.current_financial_year
    )
      .subtract(1, "year")
      .format("YYYY-03-31");
    let testDate = moment(
      subsiaryData[index].all_data.date_Of_investment
        ? subsiaryData[index].all_data.date_Of_investment
        : ""
    ).format("YYYY-MM-DD");
    let py_date = moment(previousDate)
                          .subtract(1, "year")
                          .format("YYYY-03-31");
    let first_scenario = moment(testDate).isBetween(
      previousDate,
      currentDate
    );
    let previousDate_2nd = moment(currentCompany.current_financial_year).subtract(1, "year").format('YYYY-04-01');
    let second_scenario = moment(testDate).isBetween(
      py_date,
      previousDate_2nd
    );
    return { first_scenario: first_scenario, second_scenario:second_scenario }
  }

  const particlarTab = { 
    width: "300px",
    height: "50px",
    textAlign: "left",
    paddingLeft: "2rem"
  }

  return (
    <>
     {loading ? <CircularProgress disableShrink/>
    : isSuccess ? <>Previous Period


      <div className="tableSheet">
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th
                rowspan="2"
                style={particlarTab}
              >
                <strong>Particulars </strong>
              </th>
              <th rowspan="2" style={particlarTab}>
                <strong>Notes</strong>
              </th>
              <th
                colspan="2"
                style={particlarTab}
              >
                <strong>
                  {currentCompany.prepare_consol_company_name}
                </strong>
              </th>

              {headingData.length > 0 &&
                headingData.map((company, i) => (
                  <th
                    colSpan={2}
                    style={particlarTab}
                  >
                    <strong>{company}</strong>
                  </th>
                ))}
              <th colspan="3" style={particlarTab}>
                <strong>ADJUSTMENT</strong>
              </th>
              <th colspan="3" style={particlarTab}>
                <strong>TOTAL</strong>
              </th>
            </tr>

            <tr style={{ backgroundColor: "#A2C0C2" }}>
              <th style={{ width: "15%" }}>
                {dateFormat(
                  currentCompany.current_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ Width: "25%" }}>
                {dateFormat(
                  currentCompany.previous_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              {headingData.length > 0 &&
                headingData.map((row, i) => (
                  <>
                    <th style={{ width: "15%" }}>
                      {dateFormat(
                        currentCompany.current_financial_year,
                        "mmmm dS, yyyy"
                      )}
                    </th>
                    <th style={{ Width: "25%" }}>
                      {dateFormat(
                        currentCompany.previous_financial_year,
                        "mmmm dS, yyyy"
                      )}
                    </th>
                  </>
                ))}

              <th style={{ width: "25%" }}>
                {dateFormat(
                  currentCompany.current_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ Width: "25%" }}>
                {dateFormat(
                  currentCompany.previous_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ Width: "25%" }}>OPENING</th>
              <th style={{ width: "25%" }}>
                {dateFormat(
                  currentCompany.current_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ Width: "25%" }}>
                {dateFormat(
                  currentCompany.previous_financial_year,
                  "mmmm dS, yyyy"
                )}
              </th>
              <th style={{ Width: "25%" }}>OPENING</th>
            </tr>
          </thead>
          
          <tbody
          >
            {fsRows.map((row, i) => {
              console.log(row, "fsrows");
              const cy = rows[row]
                ? rows[row].reduce((totalCy, i) => totalCy + i.cy, 0)
                : "";
              const py = rows[row]
                ? rows[row].reduce((totalPy, i) => totalPy + i.py, 0)
                : "";

              console.log(cy, "CY");
              const index = i + 1;
              let cy_0 = 0;
              let py_0 = 0;
              if (rows["Revenue"]) {
                cy_0 = rows["Revenue"].reduce(
                  (totalCy, i) => totalCy + i.cy,
                  0
                );
                py_0 = rows["Revenue"].reduce(
                  (totalPy, i) => totalPy + i.py,
                  0
                );
              }

              let cy_1 = 0;
              let py_1 = 0;
              if (rows["Expenses"]) {
                cy_1 = rows["Expenses"].reduce(
                  (totalCy, i) => totalCy + i.cy,
                  0
                );
                py_1 = rows["Expenses"].reduce(
                  (totalPy, i) => totalPy + i.py,
                  0
                );
              }

              let cy_tax = 0;
              let py_tax = 0;
              let deferred_tax_cy = 0;
              let deferred_tax_py = 0;
              if (rows["Expenses"]) {
                cy_tax = rows["Expenses"][8].cy;
                py_tax = rows["Expenses"][8].py;
                deferred_tax_cy = rows["Expenses"][11].cy;
                deferred_tax_py = rows["Expenses"][11].py;
              }

              const profit_py = py_0 - py_1;
              const profit_cy = cy_0 - cy_1;
              {/* console.log(cy_tax, "cy_tax row eow ");
              console.log(py_tax, "py_tax row eow "); */}
              setCurrentProfit(profit_py - (py_tax + deferred_tax_py));
              setCurrentYearProfit(
                profit_cy - (cy_tax + deferred_tax_cy)
              );
              setComProfit(py);
              setComYearProfit(cy);
              // subsidiary

              const cyS = rowssubsidiary[row]
                ? rowssubsidiary[row].reduce(
                    (totalCys, i) => totalCys + i.cy,
                    0
                  )
                : "";
              const pyS = rowssubsidiary[row]
                ? rowssubsidiary[row].reduce(
                    (totalPys, i) => totalPys + i.py,
                    0
                  )
                : "";

              // browsfs data

              const cyB = browseData[row]
                ? browseData[row].reduce((totalCyb, i) => totalCyb + i.cy, 0)
                : "";
              const pyB = browseData[row]
                ? browseData[row].reduce((totalPyb, i) => totalPyb + i.py, 0)
                : "";

              let totalprofit = 0;

              let totaldatas = 0;
              let totaldatas_py = 0;

              let cy_data = 0;
              let py_data = 0;
              return (
                <>
                  <tr key={i}>
                    <td style={totaledStyle}>
                      {i + 1}.{row}
                    </td>
                    <td style={totaledStyle}></td>
                    <td style={totaledStyle}>
                      {cy ? cy.toLocaleString("en-IN") : 0} (TI - TE)
                    </td>
                    <td style={totaledStyle}>
                      {py ? py.toLocaleString("en-IN") : 0}
                    </td>
                    {finalSubsidiaryData.length > 0 &&
                      finalSubsidiaryData.map((ele, ind) => {
                        const {first_scenario, second_scenario} =  getScenario(ind) 
                        if (first_scenario === true) {
                          totaldatas =
                            totaldatas +
                            (ele[row].reduce(
                              (totalCys, i) => totalCys + i.cy,
                              0
                            ) -
                              finalBrowsFsData[ind][row].reduce(
                                (totalCyb, i) => totalCyb + i.cy,
                                0
                              ));
                          totaldatas_py = totaldatas_py + 0;
                          return (
                            <>
                              <td style={totaledStyle}>
                                {(
                                  ele[row].reduce(
                                    (totalCys, i) => totalCys + i.cy,
                                    0
                                  ) -
                                  finalBrowsFsData[ind][row].reduce(
                                    (totalCyb, i) => totalCyb + i.cy,
                                    0
                                  )
                                ).toLocaleString("en-IN")} 
                              </td>
                              <td style={totaledStyle}>0</td>
                            </>
                          );
                        } else if (second_scenario === true) {
                          totaldatas = totaldatas + 0;
                          totaldatas_py =
                            totaldatas_py +
                            (ele[row].reduce(
                              (totalPys, i) => totalPys + i.py,
                              0
                            ) -
                              finalBrowsFsData[ind][row].reduce(
                                (totalPyb, i) => totalPyb + i.py,
                                0
                              ));
                          return (
                            <>
                              <td style={totaledStyle}>
                                {ele[row]
                                  .reduce((totalCys, i) => totalCys + i.cy, 0)
                                  .toLocaleString("en-IN")}
                              </td>
                              <td style={totaledStyle}>
                                {(ele[row]
                                  .reduce((totalPys, i) => totalPys + i.py, 0)
                                  -
                                  finalBrowsFsData[ind][row].reduce(
                                    (totalPyb, i) => totalPyb + i.py,
                                    0
                                  )).toLocaleString("en-IN")}
                              </td>
                            </>
                          );
                        } else {
                          totaldatas =
                            totaldatas +
                            ele[row].reduce(
                              (totalCys, i) => totalCys + i.cy,
                              0
                            );
                          totaldatas_py =
                            totaldatas_py +
                            ele[row].reduce(
                              (totalPys, i) => totalPys + i.py,
                              0
                            );
                          return (
                            <>
                              <td style={totaledStyle}>
                                {ele[row]
                                  .reduce((totalCys, i) => totalCys + i.cy, 0)
                                  .toLocaleString("en-IN")}
                              </td>
                              <td style={totaledStyle}>
                                {ele[row]
                                  .reduce((totalPys, i) => totalPys + i.py, 0)
                                  .toLocaleString("en-IN")}
                              </td>
                            </>
                          );
                        }
                      })}

                    <td style={totaledStyle}></td>
                    <td style={totaledStyle}></td>
                    <td style={totaledStyle}></td>
                    <td style={totaledStyle}>
                      {(totaldatas + cy).toLocaleString("en-IN")}
                    </td>
                    <td style={totaledStyle}>
                      {(py + totaldatas_py).toLocaleString("en-IN")}
                    </td>
                    <td style={totaledStyle}></td>
                  </tr>
                  {rows[row].map((line, ix) => {
                    if (
                      no.length > 0 &&
                      no.some(
                        (user) =>
                          user.notes_grp.toLowerCase() ===
                          line.notes_grp.toLowerCase()
                      )
                    ) {
                      const index = no.findIndex(
                        (x) =>
                          x.notes_grp.toLowerCase() ===
                          line.notes_grp.toLowerCase()
                      );
                      const number = index + 36;
                      const length = rows[row].length - 1;
                      console.log(length, "length length length");

                      let finalprofit_cy = 0;
                      let finalprofit_py = 0;

                      return (
                        <>
                          <tr key={ix}>
                            <td style={lineGroupStyle}>
                              {/* <input
                              type="checkbox"
                              checked={line?.isChecked}
                              value="child"
                              onChange={(e) =>
                                handleChildClick(e, line.notes_grp, row)
                              }
                            /> */}
                              {line.notes_grp}
                            </td>
                            <td
                              onClick={() => {
                                handleSelect(number - 37);
                              }}
                              style={NoteStyle}
                            >
                              {Number(index + 38)}
                            </td>
                            <td>
                              {line.cy ? line.cy.toLocaleString("en-IN") : 0}
                            </td>
                            <td>
                              {line.py ? line.py.toLocaleString("en-IN") : 0}
                            </td>

                            {finalSubsidiaryData.length > 0 &&
                              finalSubsidiaryData.map((ele, ind) => {
                                return (
                                  <>
                                    <td>
                                      {calculatePnlCy(ind, ele, row, ix).toLocaleString("en-IN")}
                                    </td>
                                    <td>
                                      {calculatePnlPy(ind, ele, row, ix).toLocaleString("en-IN")}
                                    </td>
                                  </>
                                )
                              })}
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              {totalSubCyPnl(line.cy,row,ix).toLocaleString("en-IN")}
                            </td>
                            <td>
                              {totalSubPyPnl(line.py,row,ix).toLocaleString("en-IN")}
                            </td>
                            <td></td>
                          </tr>
                          {i === 1 && ix === 9 && (
                            <tr>
                              <td style={totalStyle}>
                                Profit before exceptional items and tax
                              </td>
                              <td style={totalStyle}></td>
                              <td style={totalStyle}>
                                {profit_cy
                                  ? profit_cy.toLocaleString("en-IN")
                                  : 0}
                              </td>
                              <td style={totalStyle}>
                                {profit_py
                                  ? profit_py.toLocaleString("en-IN")
                                  : 0}
                              </td>

                              {finalSubsidiaryData.length > 0 &&
                                finalSubsidiaryData.map((ele, ind) => {
                                  let cyb_0 = 0;
                                  let pyb_0 = 0;
                                  if (browseData["Revenue"]) {
                                    cyb_0 = browseData["Revenue"].reduce(
                                      (totalCyb, i) => totalCyb + i.cy,
                                      0
                                    );
                                    pyb_0 = browseData["Revenue"].reduce(
                                      (totalPyb, i) => totalPyb + i.py,
                                      0
                                    );
                                  }

                                  let cyb_1 = 0;
                                  let pyb_1 = 0;
                                  if (browseData["Expenses"]) {
                                    cyb_1 = browseData["Expenses"].reduce(
                                      (totalCyb, i) => totalCyb + i.cy,
                                      0
                                    );
                                    pyb_1 = browseData["Expenses"].reduce(
                                      (totalPyb, i) => totalPyb + i.py,
                                      0
                                    );
                                  }

                                  const profit_pyb = pyb_0 - pyb_1;
                                  const profit_cyb = cyb_0 - cyb_1;

                                  // browsfs

                                  let cys_0 = 0;
                                  let pys_0 = 0;
                                  if (rowssubsidiary["Revenue"]) {
                                    cys_0 = rowssubsidiary["Revenue"].reduce(
                                      (totalCys, i) => totalCys + i.cy,
                                      0
                                    );
                                    pys_0 = rowssubsidiary["Revenue"].reduce(
                                      (totalPys, i) => totalPys + i.py,
                                      0
                                    );
                                  }

                                  let cys_1 = 0;
                                  let pys_1 = 0;
                                  if (rowssubsidiary["Expenses"]) {
                                    cys_1 = rowssubsidiary["Expenses"].reduce(
                                      (totalCys, i) => totalCys + i.cy,
                                      0
                                    );
                                    pys_1 = rowssubsidiary["Expenses"].reduce(
                                      (totalPys, i) => totalPys + i.py,
                                      0
                                    );
                                  }

                                  let cys_tax = 0;
                                  let pys_tax = 0;
                                  if (rowssubsidiary["Expenses"]) {
                                    cys_tax = rowssubsidiary["Expenses"][8].cy;
                                    pys_tax = rowssubsidiary["Expenses"][8].py;
                                  }

                                  const profit_pys = pys_0 - pys_1;
                                  const profit_cys = cys_0 - cys_1;

                                  let currentDate = moment(
                                    currentCompany.current_financial_year
                                  ).format("YYYY-04-01");
                                  let previousDate = moment(
                                    currentCompany.current_financial_year
                                  )
                                    .subtract(1, "year")
                                    .format("YYYY-03-01");
                                  let testDate = moment(
                                    subsiaryData[ind].all_data.date_Of_investment
                                      ? subsiaryData[ind].all_data.date_Of_investment
                                      : ""
                                  ).format("YYYY-MM-DD");

                                  //2nd scenario
                                  let py_date = moment(previousDate)
                                    .subtract(1, "year")
                                    .format("YYYY-03-01");
                                  let first_scenario = moment(
                                    testDate
                                  ).isBetween(previousDate, currentDate);
                                  let second_scenario = moment(
                                    testDate
                                  ).isBetween(py_date, previousDate);

                                  if (first_scenario === true) {
                                    finalprofit_cy += profit_cys - profit_cyb;
                                    finalprofit_py += +0;

                                    return (
                                      <>
                                        <td style={totalStyle}>
                                          {/* {profit_cyb
                                      ? profit_cyb.toLocaleString("en-IN")
                                      : "-" - profit_cys
                                      ? profit_cys.toLocaleString("en-IN")
                                      : "-"} */}
                                          {(
                                            (profit_cys ? profit_cys : 0) -
                                            (profit_cyb ? profit_cyb : 0)
                                          ).toLocaleString("en-IN")} 
                                        </td>
                                        <td style={totalStyle}>0</td>
                                      </>
                                    );
                                  } else if (second_scenario === true) {
                                    finalprofit_cy += profit_cys - profit_cyb;
                                    finalprofit_py += profit_pys - profit_pyb;

                                    return (
                                      <>
                                        <td style={totalStyle}>
                                          {/* {profit_cyb
                                      ? profit_cyb.toLocaleString("en-IN")
                                      : "-" - profit_cys
                                      ? profit_cys.toLocaleString("en-IN")
                                      : "-"} */}
                                          {(profit_cys
                                            ? profit_cys.toLocaleString("en-IN")
                                            : 0) -
                                            (profit_cyb
                                              ? profit_cyb.toLocaleString(
                                                  "en-IN"
                                                )
                                              : 0)}
                                        </td>
                                        <td style={totalStyle}>
                                          {profit_pyb
                                            ? profit_pyb.toLocaleString("en-IN")
                                            : "-" - profit_pys
                                            ? profit_pys.toLocaleString("en-IN")
                                            : "-"}
                                        </td>
                                      </>
                                    );
                                  } else {
                                    finalprofit_cy += profit_cys;
                                    finalprofit_py += profit_pys;
                                    return (
                                      <>
                                        <td style={totalStyle}>
                                          {profit_cys
                                            ? profit_cys.toLocaleString("en-IN")
                                            : "-"}
                                        </td>
                                        <td style={totalStyle}>
                                          {" "}
                                          {profit_pys
                                            ? profit_pys.toLocaleString("en-IN")
                                            : "-"}
                                        </td>
                                      </>
                                    );
                                  }
                                })}

                              <td style={totalStyle}></td>
                              <td style={totalStyle}></td>
                              <td style={totalStyle}></td>
                              <td style={totalStyle}>
                                {(profit_cy + finalprofit_cy).toLocaleString(
                                  "en-IN"
                                )}
                              </td>
                              <td style={totalStyle}>
                                {profit_py + finalprofit_py}
                              </td>
                              <td style={totalStyle}></td>
                            </tr>
                          )}
                        </>
                      );
                    }
                    const ins = ix + 1;
                    return (
                      <div key={ix}>
                        <tr>
                          <td style={lineGroupStyle}>{line.notes_grp}</td>
                          <td
                            onClick={() => {
                              // handleSelect(index + 39 - 37)
                            }}
                            style={NoteStyle}
                          >
                            {index + 38}
                          </td>
                          <td>{line.cy.toLocaleString("en-IN")}</td>
                          <td>{line.py.toLocaleString("en-IN")}</td>

                          <td>5</td>
                          <td>6</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                        {i === 1 && ix === 9 && (
                          <tr>
                            <td style={totalStyle}>
                              Profit before exceptional items and tax
                            </td>
                            <td style={totalStyle}></td>
                            <td style={totalStyle}>
                              {profit_cy
                                ? profit_cy.toLocaleString("en-IN")
                                : "-"}
                            </td>
                            <td style={totalStyle}>
                              {profit_py
                                ? profit_py.toLocaleString("en-IN")
                                : "-"}
                            </td>

                            <td style={totalStyle}>ee</td>
                            <td style={totalStyle}>dd</td>
                            <td style={totalStyle}></td>
                            <td style={totalStyle}></td>
                            <td style={totalStyle}></td>
                            <td style={totalStyle}></td>
                            <td style={totalStyle}></td>
                            <td style={totalStyle}></td>
                          </tr>
                        )}
                      </div>
                    );
                  })}
                  {i === 1 && (
                    <tr>
                      <td style={totalStyle}>Profit for the year</td>
                      <td style={totalStyle}></td>
                      <td style={totalStyle}>
                      {profit_cy
                            ? (profit_cy - (cy_tax + deferred_tax_cy)).toLocaleString("en-IN")
                            : "-"}
                      </td>
                      <td style={totalStyle}>
                      {profit_py
                            ? (profit_py - (py_tax + deferred_tax_py)).toLocaleString("en-IN")
                            : "-"}
                      </td>

                      {finalSubsidiaryData.length > 0 &&
                        finalSubsidiaryData.map((ele, ind) => {
                          let cyb_0 = 0;
                          let pyb_0 = 0;
                          if (browseData["Revenue"]) {
                            cyb_0 = browseData["Revenue"].reduce(
                              (totalCyb, i) => totalCyb + i.cy,
                              0
                            );
                            pyb_0 = browseData["Revenue"].reduce(
                              (totalPyb, i) => totalPyb + i.py,
                              0
                            );
                          }

                          let cyb_1 = 0;
                          let pyb_1 = 0;
                          if (browseData["Expenses"]) {
                            cyb_1 = browseData["Expenses"].reduce(
                              (totalCyb, i) => totalCyb + i.cy,
                              0
                            );
                            pyb_1 = browseData["Expenses"].reduce(
                              (totalPyb, i) => totalPyb + i.py,
                              0
                            );
                          }

                          const profit_pyb = pyb_0 - pyb_1;
                          const profit_cyb = cyb_0 - cyb_1;

                          // browsfs

                          let cys_0 = 0;
                          let pys_0 = 0;
                          if (rowssubsidiary["Revenue"]) {
                            cys_0 = rowssubsidiary["Revenue"].reduce(
                              (totalCys, i) => totalCys + i.cy,
                              0
                            );
                            pys_0 = rowssubsidiary["Revenue"].reduce(
                              (totalPys, i) => totalPys + i.py,
                              0
                            );
                          }

                          let cys_1 = 0;
                          let pys_1 = 0;
                          if (rowssubsidiary["Expenses"]) {
                            cys_1 = rowssubsidiary["Expenses"].reduce(
                              (totalCys, i) => totalCys + i.cy,
                              0
                            );
                            pys_1 = rowssubsidiary["Expenses"].reduce(
                              (totalPys, i) => totalPys + i.py,
                              0
                            );
                          }

                          let cys_tax = 0;
                          let pys_tax = 0;
                          if (rowssubsidiary["Expenses"]) {
                            cys_tax = rowssubsidiary["Expenses"][8].cy;
                            pys_tax = rowssubsidiary["Expenses"][8].py;
                          }

                          const profit_pys = pys_0 - pys_1;
                          const profit_cys = cys_0 - cys_1;

                          let cyb_tax = 0;
                          let pyb_tax = 0;
                          if (browseData["Expenses"]) {
                            cyb_tax = browseData["Expenses"][8].cy;
                            pyb_tax = browseData["Expenses"][8].py;
                          }

                          let currentDate = moment(
                            currentCompany.current_financial_year
                          ).format("YYYY-04-01");
                          let previousDate = moment(
                            currentCompany.current_financial_year
                          )
                            .subtract(1, "year")
                            .format("YYYY-03-01");
                          let testDate = moment(
                            subsiaryData[ind].all_data.date_Of_investment
                              ? subsiaryData[ind].all_data.date_Of_investment
                              : ""
                          ).format("YYYY-MM-DD");

                          //2nd scenario
                          let py_date = moment(previousDate)
                            .subtract(1, "year")
                            .format("YYYY-03-01");
                          let first_scenario = moment(testDate).isBetween(
                            previousDate,
                            currentDate
                          );
                          let second_scenario = moment(testDate).isBetween(
                            py_date,
                            previousDate
                          );

                          if (first_scenario === true) {
                            cy_data +=
                              profit_cys + cys_tax - (profit_cyb + cyb_tax);
                            py_data += 0;
                            return (
                              <>
                                <td style={totalStyle}>
                                  {(
                                    (profit_cys + cys_tax
                                      ? profit_cys + cys_tax
                                      : 0) -
                                    (profit_cyb + cyb_tax
                                      ? profit_cyb + cyb_tax
                                      : 0)
                                  ).toLocaleString("en-IN")}
                                </td>
                                <td style={totalStyle}>0</td>
                              </>
                            );
                          } else if (second_scenario === true) {
                            cy_data += profit_cys + cys_tax;
                            py_data +=
                              profit_pys + pys_tax - (profit_pyb + pyb_tax);
                            return (
                              <>
                                <td style={totalStyle}>
                                  {" "}
                                  {(profit_cys + cys_tax).toLocaleString(
                                        "en-IN"
                                      )
                                    ? (profit_cys + cys_tax).toLocaleString(
                                        "en-IN"
                                      )
                                    : "-"}
                                </td>
                                <td style={totalStyle}>
                                  {(profit_pys + pys_tax
                                    ? (profit_pys + pys_tax)
                                    : 0) -
                                    (profit_pyb + pyb_tax
                                      ? (profit_pyb + pyb_tax)
                                      : 0).toLocaleString(
                                        "en-IN"
                                      )}
                                </td>
                              </>
                            );
                          } else {
                            cy_data += profit_cys + cys_tax;
                            py_data += profit_pys + pys_tax;
                            return (
                              <>
                                <td style={totalStyle}>
                                  {" "}
                                  {profit_cys + cys_tax
                                    ? (profit_cys + cys_tax).toLocaleString(
                                        "en-IN"
                                      )
                                    : "-"}
                                </td>
                                <td style={totalStyle}>
                                  {" "}
                                  {profit_pys + pys_tax
                                    ? (profit_pys + pys_tax).toLocaleString(
                                        "en-IN"
                                      )
                                    : "-"}
                                </td>
                              </>
                            );
                          }
                        })}

                      <td style={totalStyle}></td>
                      <td style={totalStyle}></td>
                      <td style={totalStyle}></td>
                      <td style={totalStyle}>
                        {(profit_cy + cy_tax + cy_data).toLocaleString("en-IN")}
                      </td>
                      <td style={totalStyle}>
                        {(profit_py + py_tax + py_data).toLocaleString("en-IN")}
                      </td>
                      <td style={totalStyle}></td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>
      </div>
      </> : <p className="text-danger">{error_message || "Something went wrong!!!"}</p>}
    </>
  );
}

export default ConsolePnlStatement

