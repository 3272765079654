import { libraryData } from "../../data/library";

const ActionTypes = {
    IND_AS_1_NOTE_NO: "IND_AS_1_NOTE_NO",
    IND_AS_1_FROM_BS_PNL: "IND_AS_1_FROM_BS_PNL",
};

const initialState = {
    indAs1NoteNo: 0,
    indAs1CateData: libraryData[0].category,
};

const libraryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.IND_AS_1_NOTE_NO:
            return {
                ...state,
                indAs1NoteNo: action.payload,
            };
        case ActionTypes.IND_AS_1_FROM_BS_PNL:
            const filterIndex = libraryData.findIndex((element) => element.name === action.payload);
            return {
                ...state,
                indAs1NoteNo: filterIndex > -1 ? filterIndex : state.indAs1NoteNo,
                indAs1CateData: filterIndex > -1 ? libraryData[filterIndex].category : state.indAs1CateData,
            };
        default:
            return state;
    }
};

export default libraryReducer;