import { getPrepareConsole } from "../../Console/Component/services/apiHandler";


export const fetchPrepareConsoleData = (prepare_console_id) => {
    return dispatch => {
        dispatch({type:"PrepareConsole_ACTION_START"})
        getPrepareConsole(prepare_console_id).then((result)=>{
            dispatch({
                type: "PrepareConsole_FETCH_DATA",
                payload: {data: result.data, set_payload: prepare_console_id}
            })
        }).catch((err)=>{
            console.log(err,"error");
            dispatch({
                type:"PrepareConsole_FETCH_DATA_FAILED",
                payload: err?.response?.data?.error || err.message
            })
        })        
    }
}

export const socketUpdatePrepareConsole = (prepare_console_id, result) => {
    return dispatch => {
        dispatch({
            type: "PrepareConsole_SOCKET_UPDATE",
            payload: {
                data: result,
                set_payload: prepare_console_id
            }
        })
    }
}