import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import {
  FindOneAdjustment,
  UpdateAdjustmentDAta,
} from "../services/apiHandler";
import { Autocomplete, TextField } from "@mui/material";
import swal from "sweetalert";
import { editAdjustment } from "../../../redux/actions/actionAdjustment";
import { useDispatch } from "react-redux";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1800,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const UpdateAdjustment = (props) => {
  const {
    openModalUpdate,
    handleClose,
    adjustData,
    allsubdata,
  } = props;
  const [oneAdjustmentData, setOneAdjustmentData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const res = await FindOneAdjustment(adjustData._id);
      console.log(res.data.getOneAdjustment, "res data123");
      if (res.status === 200) {
        setOneAdjustmentData(res.data.getOneAdjustment);
      }
    })();
  }, []);

  const handleChange = (field, value, index) => {
    console.log("change", field, value, index);
    if(index !== undefined){
      if(field === "ledger_name"){
        oneAdjustmentData[0]['adjustment_other_data'][index][field] = value?.sub_grp;
        oneAdjustmentData[0]['adjustment_other_data'][index]['notes_no'] = value?.notes_no;
        oneAdjustmentData[0]['adjustment_other_data'][index]['fs_grp'] = value?.fs_grp;
      }else{
        oneAdjustmentData[0]['adjustment_other_data'][index][field] = value;
      }
      console.log("updatedData", oneAdjustmentData[0])
      setOneAdjustmentData(oneAdjustmentData);
    }else if(field === "scenario"){
      console.log("val", value)
      oneAdjustmentData[0]['adjustment_other_data'][0][field] = value;
      oneAdjustmentData[0]['adjustment_other_data'][1][field] = value;
      setOneAdjustmentData(oneAdjustmentData);
    }
    else{
      oneAdjustmentData[0][field] = value;
      setOneAdjustmentData(oneAdjustmentData);
    }
  }

  const handleUpdate = async (adj_id) => {
    console.log(oneAdjustmentData, "UpdateAdjCompanyData");
    const data = oneAdjustmentData[0];
    const result = await UpdateAdjustmentDAta(adj_id, data);
    if (result.status === 200) {
      swal("", "Adjustments Updated", "success");
      dispatch(editAdjustment(adj_id, result.data.updateAdjustmentData));
      handleClose();
    } else if (result.data.checkData === false) {
      swal("", "Adjustments Value sum should be 0", "warning");
    } else {
      swal("", "Adjustments Not Updated", "danger");
    }
  };

  return (
    <>
      <Modal
        open={openModalUpdate}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <table>
              <thead style={{ fontSize: "2rem" }}>
                <tr>
                  <th style={{ width: "5%", maxWidth: "5%" }}>Sl.No</th>
                  <th
                    style={{
                      width: "4%",
                      maxWidth: "8%",
                      height: "62px",
                      textAlign: "left",
                    }}
                  >
                    Date input 
                  </th>
                  <th style={{ width: "15%", maxWidth: "15%" }}>
                    Comapany Code
                  </th>
                  <th style={{ width: "15%", maxWidth: "15%" }}>
                    Comapany Name
                  </th>
                  <th style={{ width: "20%", maxWidth: "20%" }}>Ledger Name</th>
                  <th style={{ width: "10%", maxWidth: "10%" }}>
                    Adjustments Value
                  </th>
                  <th style={{ width: "20%", maxWidth: "20%" }}>Entry</th>
                  <th style={{ width: "10%", maxWidth: "10%" }}>Remark</th>
                  <th
                    style={{
                      width: "15%",
                      maxWidth: "15%",
                      textAlign: "left",
                      paddingLeft: "3rem",
                    }}
                  >
                    Action
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td
                    rowSpan={
                      oneAdjustmentData[0]?.adjustment_other_data?.length
                    }
                  >
                    {"1"}
                  </td>
                  <td
                    style={{ textAlign: "center" }}
                    rowSpan={
                      oneAdjustmentData[0]?.adjustment_other_data?.length
                    }
                  >
                    <input
                      type="date"
                      onChange={(e) =>
                        handleChange("adjustment_date", e.target.value)
                      }
                      defaultValue={oneAdjustmentData[0]?.date_of_adjustment}
                    ></input>
                  </td>
                  <td  rowSpan={
                      oneAdjustmentData[0]?.adjustment_other_data?.length
                    }>{"Company_code"}</td>
                  <td  rowSpan={
                      oneAdjustmentData[0]?.adjustment_other_data?.length
                    }>
                    {oneAdjustmentData[0]?.adjustment_other_data[0]?.company_id}
                  </td>
                  <td>
                    <div className=" col-8 form-group">
                      <Autocomplete
                        options={allsubdata}
                        sx={{ width: 450 }}
                        onChange={(e, value) =>
                          handleChange("ledger_name", value, 0)
                        }
                        defaultValue={allsubdata.find(
                          (data) =>
                            data.sub_grp ===
                            oneAdjustmentData[0]?.adjustment_other_data[0]
                              ?.ledger_name
                        )}
                        renderInput={(params) => <TextField {...params} />}
                        getOptionSelected={(option) =>
                          option.sub_grp ===
                          oneAdjustmentData[0]?.adjustment_other_data[0]
                            ?.ledger_name
                        }
                        getOptionLabel={(option) => option.sub_grp}
                        renderOption={(props, option, index) => {
                          return (
                            <li {...props} key={index.index}>
                              {option.sub_grp}
                            </li>
                          );
                        }}
                      />
                    </div>
                  </td>
                  <td>
                    <input
                      type="number"
                      style={{
                        maxWidth: "140px",
                        border: "none",
                        textAlign: "right",
                      }}
                      defaultValue={
                        oneAdjustmentData[0]?.adjustment_other_data[0]
                          ?.adjustment_value
                      }
                      onChange={(e) =>
                        handleChange(
                          "adjustment_value",
                          e.target.value,
                          0
                        )
                      }
                    />
                  </td>
                  <td  rowSpan={
                      oneAdjustmentData[0]?.adjustment_other_data?.length
                    }>
                    <select
                      className="from-control"
                      style={{
                        width: "200px",
                        height: "50px",
                        overflow: "scroll",
                      }}
                      onChange={(e) =>
                        handleChange("scenario", e.target.value)
                      }
                    >
                      <option
                        selected={
                          oneAdjustmentData[0]?.adjustment_other_data[0]
                            ?.scenario === ""
                        }
                      >
                        Select...
                      </option>
                      <option
                        value="temporary"
                        selected={
                          oneAdjustmentData[0]?.adjustment_other_data[0]
                            ?.scenario === "temporary"
                        }
                      >
                        Temporary Entry
                      </option>
                      <option
                        value="permanent"
                        selected={
                          oneAdjustmentData[0]?.adjustment_other_data[0]
                            ?.scenario === "permanent"
                        }
                      >
                        Permanent Entry
                      </option>
                    </select>
                  </td>
                  <td
                    rowSpan={
                      oneAdjustmentData[0]?.adjustment_other_data?.length
                    }
                  >
                    <div className="col-6 form-group">
                      <textarea
                        defaultValue={oneAdjustmentData[0]?.remark}
                        onChange={(e) =>
                          handleChange("remark", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </td>
                  <td
                    style={{ height: "150px", textAlign: "center" }}
                    rowSpan={
                      oneAdjustmentData[0]?.adjustment_other_data?.length
                    }
                  >
                    <button
                      class="btn btn-primary1 mt-3"
                      onClick={() => {
                        handleUpdate(adjustData._id);
                      }}
                    >
                      Save
                    </button>
                  </td>
                </tr>
                {oneAdjustmentData[0]?.adjustment_other_data.map(
                  (item, key) => (
                    <>
                      {key !== 0 ? (
                        <tr>
                          {/* <td>{"company_code"}</td>
                          <td>{item?.company_id}</td> */}
                          <td>
                            <div className=" col-8 form-group">
                              <Autocomplete
                                options={allsubdata}
                                sx={{ width: 450 }}
                                onChange={(e, value) =>
                                  handleChange(
                                    "ledger_name",
                                    value,
                                    key
                                  )
                                }
                                defaultValue={allsubdata.find(
                                  (data) => data.sub_grp === item?.ledger_name
                                )}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                getOptionLabel={(option) => option.sub_grp}
                                renderOption={(props, option, index) => {
                                  return (
                                    <li {...props} key={index.index}>
                                      {option.sub_grp}
                                    </li>
                                  );
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              style={{
                                maxWidth: "140px",
                                border: "none",
                                textAlign: "right",
                              }}
                              defaultValue={item?.adjustment_value}
                              onChange={(e) =>
                                handleChange(
                                  "adjustment_value",
                                  e.target.value,
                                  key
                                )
                              }
                            />
                          </td>
                          {/* <td>
                            <select
                              className="from-control"
                              style={{
                                width: "200px",
                                height: "50px",
                                overflow: "scroll",
                              }}
                              onChange={(e) =>
                                handleChange(
                                  "scenario",
                                  e.target.value,
                                  key
                                )
                              }
                            >
                              <option selected={item?.scenario === ""}>
                                Select...
                              </option>
                              <option
                                value="temporary"
                                selected={item?.scenario === "temporary"}
                              >
                                Temporary Entry
                              </option>
                              <option
                                value="permanent"
                                selected={item?.scenario === "permanent"}
                              >
                                Permanent Entry
                              </option>
                            </select>
                          </td> */}
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  )
                )}
              </tbody>
            </table>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default UpdateAdjustment;
