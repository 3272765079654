
import * as React from "react";
import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import swal from "sweetalert";
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import moment from "moment";
import useFetchApi from "../../../../services/CustomHook/useFetchApi";

import "../../../Workspace-v2/sidebarv2.css";
import "../../../../.././src/WorkSpace/SignUp2.css"
import "./workspaceSetting.css"
import { Navbar } from "../../../../Components/Navbar";
import Sidbarv2 from "../../../Workspace-v2/Sidbarv2";
import WorkspaceSettingAccordion from "./components/WorkspaceSettingAccordion";
import WorkspaceSettingCheckBox from "./components/WorkspaceSettingCheckBox";
import { createWorkspaceName, GetAllCompany, GetCheckedIR, CreateCompany, fetchCoa, CreatePresetCheck,CreateWorkspace, getPermission, getApproverForEditWorkspaces, addworkspacedraft, getworkspacedraft, GetAllImportedCompanies } from "../../ApiServices/apiHandler";
import { fetchWSData } from "../../../../redux/actions/workspace/actionWorkspace";
import { fetchAllCompany } from "../../../../redux/actions/workspace/actionCompany";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { fetchAllLoans } from "../../../../redux/actions/workspace/actionLoans";
import { fetchAllLease } from "../../../../redux/actions/workspace/actionLease";
import { fetchAllReceivables } from "../../../../redux/actions/workspace/actionReceivables";
import { fetchAllPayables } from "../../../../redux/actions/workspace/actionPayables";
import { fetchAllPresets } from "../../../../redux/actions/workspace/actionAllPreset";
import { Box, FormControl, MenuItem, Modal, Select } from "@mui/material";
import { socket } from '../../../../services/socket/socket';

const stylemodal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  height:250,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p:2,
};

const WorkspaceSetting = () => {
  const users = JSON.parse(localStorage.getItem('user'));
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const a = location.pathname;
  const w_id = a.split("/");
  const { response, error } = useFetchApi(`api/v1/company-data/all-IR`);

  const workspace_data = useSelector((state) => state.WSReducer);
  const all_company = useSelector((state) => state.CompanyReducer);
  const all_lease = useSelector((state) => state.LeaseReducer);
  const all_loans = useSelector((state) => state.LoansReducer);
  const all_receivables = useSelector((state) => state.ReceivablesReducer);
  const all_payables = useSelector((state) => state.PayablesReducer);
  const all_presets = useSelector((state) => state.AllPresetsReducer);

  const [workspaceDetails, setWorkspaceDetails] = useState(workspace_data.response[0] || {});
  const [colorSnippet, setColorSnippet] = useState({
    nameColor: true
  });

  const [companies, setCompanies] = useState(all_company.response || []);
  const [allInvestmentRegisters, setAllInvestmentRegisters] = useState([]);
  const [leases, setLeases] = useState([]);
  const [loans, setLoans] = useState([]);
  const [receivable, setReceivable] = useState([]);
  const [payable, setPayable] = useState([]);
  const [presets, setPresets] = useState([]);
  const [allCoaData, setAllCoa] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState([]);
  const [selectedInvestmentRegister, setSelectedInvestmentRegister] = useState([]);
  const [selectedPayable, setSelectedPayable] = useState([]);
  const [selectedReceivable, setSelectedReceivable] = useState([]);
  const [selectedInvestments, setSelectedInvestments] = useState([]);
  const [selectedLeases, setSelectedLeases] = useState([]);
  const [selectedLoans, setSelectedLoans] = useState([]);

  const [selectedpresets, setSelectedpresets] = useState([]);
  const [permission,setPermission]=useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRemarkModal, setOpenRemarkModal] = useState(false);
  const [approvalId,setApprovalId]=useState([]);
  const [approverId,setApproverId]=useState({});
  const [reason,setReason]=useState('');
  const [draftWorkspace,setDraftWorkspace]=useState([]);
  const [hasApproved,setHasApproved]=useState("draft");
  const [importedCompany, setImportedCompany] = useState([]);

  useEffect(() => {
    if (all_company.isSuccess) {
      setCompanies(all_company.response);
    } else {
      dispatch(fetchAllCompany());
    }

    if (all_lease.isSuccess) {
      setLeases(all_lease.response);
    } else {
      dispatch(fetchAllLease());
    }

    if (all_loans.isSuccess) {
      setLoans(all_loans.response);
    } else {
      dispatch(fetchAllLoans());
    }

    if (all_receivables.isSuccess) {
      setReceivable(all_receivables.response);
    } else {
      dispatch(fetchAllReceivables());
    }

    if (all_payables.isSuccess) {
      setPayable(all_payables.response);
    } else {
      dispatch(fetchAllPayables());
    }
    if (all_presets.isSuccess) {
      setPresets(all_presets.response);
    } else {
      dispatch(fetchAllPresets(id));
    }

    if (workspace_data.isSuccess === false) {
      dispatch(fetchWSData(id));
    } else {
      setWorkspaceDetails(workspace_data.response[0]);
    }
  }, [all_company, all_lease, all_loans,all_receivables,all_payables, all_presets, workspace_data, id]);

  useEffect(() => {
    if (response && Object.keys(response).length > 0) {
      setAllInvestmentRegisters(response.data.fetchIR)
    }
    if (error) {
      return swal("Error", error, "error");
    }
  }, [response || error]);

  useEffect(() => {
    getCoas();
    getCheckedIrs();
    getPermissionOfWorkspace();
    getDraftWorkspace();
    GetAllImportedCompany();
  }, []);

  useEffect(() => {
    getApprovalIds();
  }, [selectedCompany,draftWorkspace]);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenCancelModal = () => setOpenCancelModal(true);
  const handleCloseCancelModal = () => setOpenCancelModal(false);
  const handleOpenRemarkModal = () => setOpenRemarkModal(true);
  const handleCloseRemarkModal = () => setOpenRemarkModal(false);

  const getApprovalIds = async()=>{
    const parent_id = users.parent_id;
    const data={company: draftWorkspace?.data?.all_company_checked ? draftWorkspace?.data?.all_company_checked: selectedCompany}
    getApproverForEditWorkspaces(parent_id,data)
        .then(response => {
          setApprovalId(response.data.result);
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }

  const GetAllImportedCompany = async () => {
    const user_id=users._id;
    const res = await GetAllImportedCompanies(user_id);
    if (res.status === 200) {
      setImportedCompany(res.data.result);
    }
  };

  const getDraftWorkspace = async()=>{
    const workspace_id =w_id[2];
    getworkspacedraft(workspace_id)
        .then(response => {
          setDraftWorkspace(response.data.getworkspace);
        })
        .catch(error => {
        setHasApproved("approved");
        console.error('Error:', error);
        });
  }

  const handleApproverChange = (event) => {
    const newValue = event.target.value;
    setApproverId(newValue);
  };

  const getCoas = () => {
    fetchCoa().then(response => {
      setAllCoa(response.data.coa)
    }).catch(error => {
      console.log("getting error from coa api", error)
    });
  };
  

  const getPermissionOfWorkspace = async () => {
    const workspace_id = w_id[2];
    const res = await getPermission(workspace_id);
    if (res.status === 200) {
      setPermission(res.data.response.workspace_access)
    }
  }
  const getCheckedIrs = async () => {
    try {
      const res = await GetCheckedIR(id);
  
      if (res.status === 200 && res.data.getIR.length > 0) {
        const { all_company_checked, all_IR_checked, all_preset_checked, all_checked_loans, all_checked_leases, all_checked_investment, all_checked_receivable, all_checked_payable } = res.data.getIR[0];
  
        if (all_company_checked.length > 0) {
          setSelectedCompany(all_company_checked);
        }
        if (all_IR_checked.length > 0) {
          setSelectedInvestmentRegister(all_IR_checked);
        }
        if (all_preset_checked.length > 0) {
          setSelectedpresets(all_preset_checked);
        }
        if (all_checked_loans.length > 0) {
          setSelectedLoans(all_checked_loans);
        }
        if (all_checked_leases.length > 0) {
          setSelectedLeases(all_checked_leases);
        }
        if (all_checked_investment.length > 0) {
          setSelectedInvestments(all_checked_investment);
        }
        if (all_checked_receivable?.length > 0) {
          setSelectedReceivable(all_checked_receivable);
        }
        if (all_checked_payable?.length > 0) {
          setSelectedPayable(all_checked_payable);
        }
      }
    } catch (error) {}
  };

  const companyName = (c_id) => {
    try {
      return companies.find((adj) => adj._id === c_id)?.company_name;
    } catch (error) {
      return 0;
    }
  };

  const CoaName = (p_id) => {
    const nameOfCoa = allCoaData.find((adj) => adj.project_id === p_id);
    return nameOfCoa ? `(${nameOfCoa?.name})` : "";
  }

  const HandleWorkspaceNameUpdate = async () => {
    if (!workspaceDetails?.workspace_name) {
      return swal("Warning", "Please Enter Workspace Name", "warning");
    }

    const data = {
      workspaceName: workspaceDetails?.workspace_name,
      has_published:"published",
      status:"approved"
    };

    createWorkspaceName(id, data).then(response => {
      dispatch(fetchWSData(id));
      toast.success('Workspace Name Updated Successfully.');
      setColorSnippet({ ...colorSnippet, nameColor: true });
    }).catch(error => {
      swal("Error", "Workspace Name Update Failed!", "error");
    })
  };
  const HandleWorkspaceYearUpdate = async () => {
    const data = {
      workspaceId:id,
      standalone_cy: dayjs(workspaceDetails.standalone_cy).format('YYYY-MM-DD'),
      standalone_py: dayjs(workspaceDetails.standalone_py).format('YYYY-MM-DD'),
      has_published:"published",
      status:"approved"
    };

    CreateWorkspace(data).then(response => {
      toast.success('Workspace Standalone Year Updated Successfully.');
    }).catch(error => {
      swal("Error", "Workspace Standalone Year Update Failed!", "error");
    })
  };

  // ? Update IRs
  const updateCheckedIR = (key, checked_data, importSelectedName=null) => {
    const data = {
      w_id: id,
      [key]: checked_data
    }
    CreateCompany(data).then(res => {
      if( importSelectedName ) { toast.success(`Selected ${importSelectedName} Updated Successfully.`); }
      getCheckedIrs();
      return res
    }).catch(err => {
      swal("Error", err, "error");
      return err
    })
  }

  // ? SELECT CHECKBOX FOR UPDATE 
  const handleSelect = (event, id, setSelectedItems, name) => {
    if(draftWorkspace?.data){
      setDraftWorkspace(prevState => {
        const newAllChecked = prevState.data[name].includes(id)
          ? prevState.data[name].filter(ids => ids !== id)
          : [...prevState.data[name], id];
  
        return {
          ...prevState,
          data: {
            ...prevState.data,
            [name]: newAllChecked
          }
        };
      });
    }else{
      setSelectedItems((prevItems) => {
        if (prevItems?.includes(id)) {
          const filterItems = prevItems.filter((item) => item !== id);
          if (users.responsibilities !== "preparer") {
            updateCheckedIR(name, filterItems);
          }
          return filterItems;
        } else {
          const updatedItems = [...prevItems, id];
          if (users.responsibilities !== "preparer") {
            updateCheckedIR(name, updatedItems);
          }
          return updatedItems;
        }
      });
    }
  };
  

  // ? IMPORT ALL
  const importAll = (items, setSelectedItems, key , toastName) => {
    const allIds = items.map((item) => item._id);
    setSelectedItems(allIds);
    updateCheckedIR(key, allIds);
    toast.success(`All ${toastName} Imported Successfully.`);
  };
  

  const handleChangePreset = (field, e, id,name) => {
    if(draftWorkspace?.data){
      setDraftWorkspace(prevState => {
        const newAllChecked = prevState.data[name].includes(id)
          ? prevState.data[name].filter(ids => ids !== id)
          : [...prevState.data[name], id];
  
        return {
          ...prevState,
          data: {
            ...prevState.data,
            [name]: newAllChecked
          }
        };
      });
    }else{
      const selectedIndex = selectedpresets?.indexOf(id);
      let Preset = [];
      if (selectedIndex === -1) {
        Preset = Preset.concat(selectedpresets, id);
      } else if (selectedIndex === 0) {
        Preset = Preset.concat(selectedpresets.slice(1));
      } else if (selectedIndex === selectedpresets?.length - 1) {
        Preset = Preset.concat(selectedpresets.slice(0, -1));
      } else if (selectedIndex > 0) {
        Preset = Preset.concat(
          selectedpresets.slice(0, selectedIndex),
          selectedpresets.slice(selectedIndex + 1)
        );
      }
      setSelectedpresets(Preset);
    }
  };
  const handleSelectedPresets = async () => {
    try {
      const data = {
        w_id: id,
        all_Preset_checked: selectedpresets,
      };
      const res = await CreatePresetCheck(data);
      if (res.status === 200) {
        toast.success('All Presets Updated Successfully.');
        getCheckedIrs();
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const importAllPresets = async () => {
    try {
      if (presets?.length > 0) {
        const newSelecteds = presets.map((n) => n._id);
        setSelectedpresets(newSelecteds);
        const data = {
          w_id: id,
          all_Preset_checked: newSelecteds,
        };
        const res = await CreatePresetCheck(data);
        if (res.status === 200) {
          toast.success('All Presets Updated Successfully.');
          getCheckedIrs();
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const handleSelectAllPresets = async () => {
    try {
      if (presets?.length > 0) {
        const newSelecteds = presets.map((n) => n._id);
        setSelectedpresets(newSelecteds);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
 
  const addDraftWorkspace=()=>{
    let data={};
    if(!draftWorkspace._id){
       data={
        workspace_id:w_id[2],
        approver_id:approverId,
        data:{
        workspace_name: workspaceDetails.workspace_name,
        all_IR_checked: selectedInvestmentRegister,
        all_company_checked:selectedCompany,
        all_preset_checked:selectedpresets,
        all_checked_loans:selectedLoans,
        all_checked_leases:selectedLeases,
        all_checked_investment:selectedInvestments,
        all_checked_payable:selectedPayable,
        all_checked_receivable:selectedReceivable,
        standalone_cy:workspaceDetails.standalone_cy,
        standalone_py:workspaceDetails.standalone_py
        },
        current_status:"draft"
      }}else{
      data={
        unique_id:draftWorkspace._id,
        approver_id:approverId,
        data:{
        workspace_name: draftWorkspace?.data?.workspace_name,
        all_IR_checked: draftWorkspace?.data?.all_IR_checked,
        all_company_checked:draftWorkspace?.data?.all_company_checked,
        all_preset_checked:draftWorkspace?.data?.all_preset_checked,
        all_checked_loans:draftWorkspace?.data?.all_checked_loans,
        all_checked_leases:draftWorkspace?.data?.all_checked_leases,
        all_checked_investment:draftWorkspace?.data?.all_checked_investment,
        all_checked_payable:draftWorkspace?.data?.all_checked_payable,
        all_checked_receivable:draftWorkspace?.data?.all_checked_receivable,
        standalone_cy:draftWorkspace?.data?.standalone_cy,
        standalone_py:draftWorkspace?.data?.standalone_py
        },
        cancel_reason:"",
        current_status:"draft"
      }}
      addworkspacedraft(data)
      .then(response => {
        if (response.status === 200) {
          setOpenModal(false);
          setHasChanged(false)
          getDraftWorkspace();
          getCheckedIrs();
          swal("Success", "Workspace Approval Sent Successfully", "success");
          socket.emit("add-notification", {
            receiver_id: approverId,
            description: "updation of workspace",
            type:"send approval",
            path:"/Notification"
          });
          socket.emit('get-notification');
        } 
      })
      .catch(error => {
        swal("Error", "Failed to Send workspace Approval", "error");
      });
  }
  const handleCancel=()=>{
    const data={
      unique_id:draftWorkspace._id,
      workspace_id:draftWorkspace.workspace_id,
      approver_id:draftWorkspace.approver_id,
      current_status:"cancelled",
      cancel_reason:reason,
      data:draftWorkspace.data
    }
    addworkspacedraft(data)
    .then(response => {
      if (response.status === 200) {
        setOpenCancelModal(false);
        getDraftWorkspace();
        swal("Success", "Workspace Cancelled Successfully", "success");
        socket.emit("add-notification", {
          receiver_id: draftWorkspace.user_id,
          description: "workspace",
          type:"Cancelled",
          path:"/Notification"
        });
        socket.emit('get-notification');
      } 
    })
    .catch(error => {
      swal("Error", "Failed to cancel workspace ", "error");
    });
  }

  const handleApprove=()=>{
    const data={
      unique_id:draftWorkspace._id,
      workspace_id:draftWorkspace.workspace_id,
      approver_id:draftWorkspace.approver_id,
      current_status:"published",
      cancel_reason:"",
      data:draftWorkspace.data
    }
    addworkspacedraft(data)
    .then(response => {
      if (response.status === 200) {
        dispatch(fetchWSData(id));
        swal("Success", "Workspace Approved Successfully", "success");
        getDraftWorkspace();
        socket.emit("add-notification", {
          receiver_id: draftWorkspace.user_id,
          description: "workspace",
          type:"approved",
          path:"/Notification"
        });
        socket.emit('get-notification');
      } 
    })
    .catch(error => {
      swal("Error", "Failed to Approve workspace ", "error");
    });
  }
  return (
    <>
      <div>
        <div>
          <Navbar />
        </div>
        <div className="d-flex">
          <div>
            <Sidbarv2
              w_id={w_id}
            />
          </div>
          <div className="d-flex flex-column w-100">
            <div className="d-flex justify-content-end mt-3">
              <Button
                onClick={HandleWorkspaceNameUpdate}
                className="mr-4 text-capitalize rounded text-white save-button custom-common-theme-bg-color"
              >
                View Logs
              </Button>
            </div>
            <div class="px-4 w-100 mt-3">
              <WorkspaceSettingAccordion name={"Workspace Name"}>
                <div className="p-2 ml-1">
                  <p className="font-weight-bold mr-5" style={{ color: "#7F8C8D" }}>Name of your workspace</p>
                  <TextField
                    id="outlined-basic"
                    className={colorSnippet.nameColor ? "bg-light" : "bg-white"}
                    onClick={() => setColorSnippet({ ...colorSnippet, nameColor: false })}
                    variant="outlined"
                    fullWidth
                    name="workspace_name"
                    disabled={permission && permission.length > 0 && permission[0].editworkspace_name===false || users.responsibilities==="approver"||permission && permission.length === 0}
                    value={draftWorkspace?.data?.workspace_name?draftWorkspace?.data?.workspace_name:workspaceDetails?.workspace_name}
                    onChange={(e) => {
                      if (draftWorkspace?.data) {
                        setDraftWorkspace((prevState) => ({
                          ...prevState,
                          data: {
                              ...prevState.data,
                              workspace_name: e.target.value
                          }
                      }));
                      }else{
                        setWorkspaceDetails({ ...workspaceDetails, [e.target.name]: e.target.value })
                      }
                      setHasChanged(true);
                    }
                   }
                   InputProps={{
                    style: {
                      color: draftWorkspace?.data?.workspace_name && draftWorkspace?.data?.workspace_name !== workspaceDetails?.workspace_name ? "#f08998" : "inherit"
                    }
                  }}
                  />
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex justify-content-center mt-3">
                    <Button
                      onClick={HandleWorkspaceNameUpdate}
                      disabled={permission && permission.length > 0 && permission[0].editworkspace_name===false || users.responsibilities==="approver" || users.responsibilities==="preparer"||permission && permission.length === 0}
                      className="mr-3 text-capitalize rounded text-white save-button custom-common-theme-bg-color"
                    >
                      Save
                    </Button>
                    <Button 
                      className="text-capitalize rounded text-white save-button custom-common-theme-bg-color" 
                      disabled={permission && permission.length > 0 && permission[0].editworkspace_name===false || users.responsibilities==="approver" || users.responsibilities==="preparer"||permission && permission.length === 0}
                      onClick={() => {
                        dispatch(fetchWSData(id));
                        setColorSnippet({ ...colorSnippet, nameColor: true });
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  }
                </div>
              </WorkspaceSettingAccordion>

              <WorkspaceSettingAccordion name={"Import Companies"} >
                <WorkspaceSettingAccordion name={"Investment Register"} >
                  <div className="mt-3 handle-scroll">
                    {
                      allInvestmentRegisters.length > 0 && allInvestmentRegisters?.map((ir, irx) => (
                        <WorkspaceSettingCheckBox
                          key={irx}
                          companyName={companyName(ir.company)}
                          checked={draftWorkspace?.data?.all_IR_checked?draftWorkspace?.data?.all_IR_checked:selectedInvestmentRegister?.includes(ir._id)}
                          disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"||permission && permission.length === 0}
                          id={ir._id}
                          onChange={ (e) => {handleSelect(e, ir._id, setSelectedInvestmentRegister, "all_IR_checked")
                          setHasChanged(true);
                          } }
                          style={{
                            color: draftWorkspace?.data?.all_IR_checked?((draftWorkspace?.data?.all_IR_checked?.includes(ir._id) 
                                    && selectedInvestmentRegister.includes(ir._id)) 
                                    ? "#03565A" 
                                    :(draftWorkspace?.data?.all_IR_checked?.includes(ir._id) 
                                    ||selectedInvestmentRegister.includes(ir._id))? "#f08998" :'#03565A'):"#03565A"
                          }}
                        >
                          <div className="flex flex-column" >
                            <h6 className="ml-2 mb-0">{ir?.ir_name}</h6>
                            <div className="d-flex ml-2" >
                              <div className="text-secondary" >
                                {
                                  ir?.date_Of_investment ?
                                    <>
                                      <CalendarMonthIcon className="font-size-14" />
                                      <span className="font-size-12">
                                        {moment(ir?.date_Of_investment).format("MMMM DD,YYYY")}
                                      </span>
                                    </>
                                    : ""
                                }
                              </div>
                              <div className="text-secondary" >
                                {
                                  ir?.date_Of_investment ?
                                    <>
                                      <CalendarMonthIcon className="font-size-14" />
                                      <span className="font-size-12">
                                        {moment(ir?.date_Of_investment).format("MMMM DD,YYYY")}
                                      </span>
                                    </>
                                    : ""
                                }
                              </div>
                            </div>
                          </div>
                        </WorkspaceSettingCheckBox>
                      ))
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        onClick={() => updateCheckedIR("all_IR_checked", selectedInvestmentRegister, "IR")}
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                      >
                        Import selected
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={() => importAll(allInvestmentRegisters, setSelectedInvestmentRegister, "all_IR_checked", "IR") }
                      >
                        Import all IR
                      </Button>
                    </div>
                  </div>}
                </WorkspaceSettingAccordion>

                <WorkspaceSettingAccordion name={"Companies List"} >
                  <div className="mt-3 handle-scroll">
                    {
                      companies.length > 0 && companies?.map((comp, ix) => (
                        <WorkspaceSettingCheckBox
                          checked={draftWorkspace?.data?.all_company_checked?draftWorkspace?.data?.all_company_checked?.includes(comp._id):selectedCompany?.includes(comp._id)}
                          disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer" && !importedCompany.includes(comp._id)||permission && permission.length === 0}
                          id={comp._id}
                          onChange={ (e) => {handleSelect(e, comp._id, setSelectedCompany, "all_company_checked")
                          setHasChanged(true);
                          } }
                          key={ix}
                          style={{
                            color: draftWorkspace?.data?.all_company_checked?((draftWorkspace?.data?.all_company_checked?.includes(comp._id) 
                                    && selectedCompany.includes(comp._id)) 
                                    ? "#03565A" 
                                    :(draftWorkspace?.data?.all_company_checked?.includes(comp._id) 
                                    ||selectedCompany.includes(comp._id))? "#f08998" :'#03565A'):"#03565A"
                          }}
                        >
                          <h6 className="ml-2 mb-0">{comp?.company_name}</h6>
                        </WorkspaceSettingCheckBox>
                      ))
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={() => updateCheckedIR("all_company_checked", selectedCompany, "Company")}
                      >
                        Import selected
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        onClick={ () => importAll(companies, setSelectedCompany, "all_company_checked", "Company") }
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                      >
                        Import all companies
                      </Button>
                    </div>
                  </div>
                  }
                  {/* <div className="d-flex justify-content-center mt-3">
                    <Button className="mr-3 text-capitalize rounded text-white save-button custom-common-theme-bg-color" >Save</Button>
                    <Button className="text-capitalize rounded text-white save-button custom-common-theme-bg-color" >Cancel</Button>
                  </div> */}
                </WorkspaceSettingAccordion>
              </WorkspaceSettingAccordion>

              <WorkspaceSettingAccordion name={"Import Registers"} >
                <WorkspaceSettingAccordion name={"Sales"} isEmpty={true} />
                <WorkspaceSettingAccordion name={"Purchase"} isEmpty={true} />
                <WorkspaceSettingAccordion name={"Leases"}>
                  <div className="mt-3 handle-scroll">
                    {
                      leases.length > 0 && leases?.map((lease, ix) => (
                        <WorkspaceSettingCheckBox key={ix}
                          checked={draftWorkspace?.data?.all_checked_leases?draftWorkspace?.data?.all_checked_leases?.includes(lease._id):selectedLeases?.includes(lease._id)}
                          disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"||permission && permission.length === 0}
                          id={lease._id}
                          companyName={lease?.company}
                          onChange={ (e) =>{ handleSelect(e, lease._id, setSelectedLeases, "all_checked_leases") 
                          setHasChanged(true);
                          }}
                          style={{
                            color: draftWorkspace?.data?.all_checked_leases?((draftWorkspace?.data?.all_checked_leases?.includes(lease._id) 
                                    && selectedLeases.includes(lease._id)) 
                                    ? "#03565A" 
                                    :(draftWorkspace?.data?.all_checked_leases?.includes(lease._id) 
                                    ||selectedLeases.includes(lease._id))? "#f08998" :'#03565A'):"#03565A"
                          }}
                        >
                          <div className="flex flex-column" >
                            <h6 className="ml-2 mb-0">{lease?.name}</h6>
                          </div>
                        </WorkspaceSettingCheckBox>
                      ))
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={() => updateCheckedIR("all_checked_leases", selectedLeases, "Lease")}
                      >
                        Import selected Leases
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={ () => importAll(leases, setSelectedLeases, "all_checked_leases", "Lease") }
                      >
                        Import all Leases
                      </Button>
                    </div>
                  </div>}
                </WorkspaceSettingAccordion>
                <WorkspaceSettingAccordion name={"Investment"}>
                  <div className="mt-3 handle-scroll">
                    {
                      allInvestmentRegisters.length > 0 && allInvestmentRegisters?.map((ir, irx) => (
                        <WorkspaceSettingCheckBox
                          key={irx}
                          companyName={companyName(ir.company)}
                          checked={draftWorkspace?.data?.all_checked_investment?draftWorkspace?.data?.all_checked_investment?.includes(ir._id):selectedInvestments?.includes(ir._id)}
                          disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"||permission && permission.length === 0}
                          id={ir._id}
                          onChange={ (e) =>{ handleSelect(e, ir._id, setSelectedInvestments, "all_checked_investment") 
                          setHasChanged(true);
                          }}
                          style={{
                            color: draftWorkspace?.data?.all_checked_investment?((draftWorkspace?.data?.all_checked_investment?.includes(ir._id) 
                                    && selectedInvestments.includes(ir._id)) 
                                    ? "#03565A" 
                                    :(draftWorkspace?.data?.all_checked_investment?.includes(ir._id) 
                                    ||selectedInvestments.includes(ir._id))? "#f08998" :'#03565A'):"#03565A"
                          }}
                        >
                          <div className="flex flex-column" >
                            <h6 className="ml-2 mb-0">{ir?.ir_name}</h6>
                            <div className="d-flex ml-2" >
                              <div className="text-secondary" >
                                {
                                  ir?.date_Of_investment ?
                                    <>
                                      <CalendarMonthIcon className="font-size-14" />
                                      <span className="font-size-12">
                                        {moment(ir?.date_Of_investment).format("MMMM DD,YYYY")}
                                      </span>
                                    </>
                                    : ""
                                }
                              </div>
                              <div className="text-secondary" >
                                {
                                  ir?.date_Of_investment ?
                                    <>
                                      <CalendarMonthIcon className="font-size-14" />
                                      <span className="font-size-12">
                                        {moment(ir?.date_Of_investment).format("MMMM DD,YYYY")}
                                      </span>
                                    </>
                                    : ""
                                }
                              </div>
                            </div>
                          </div>
                        </WorkspaceSettingCheckBox>
                      ))
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={() => updateCheckedIR("all_checked_investment", selectedInvestments, "Investment")}
                      >
                        Import selected Investment
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={ () => importAll(allInvestmentRegisters, setSelectedInvestments, "all_checked_investment", "Investment") }
                      >
                        Import all Investment
                      </Button>
                    </div>
                  </div>}
                </WorkspaceSettingAccordion>
                <WorkspaceSettingAccordion name={"Fixed Assets"} isEmpty={true} />
                <WorkspaceSettingAccordion name={"Receivable"}>
                  <div className="mt-3 handle-scroll">
                    {
                      receivable.length > 0 && receivable?.map((receivable, ix) => (
                        <WorkspaceSettingCheckBox
                          key={ix}
                          companyName={receivable?.company}
                          checked={draftWorkspace?.data?.all_checked_receivable?draftWorkspace?.data?.all_checked_receivable?.includes(receivable._id):selectedReceivable?.includes(receivable._id)}
                          disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"||permission && permission.length === 0}
                          id={receivable._id}
                          onChange={ (e) =>{ handleSelect(e, receivable._id, setSelectedReceivable, "all_checked_receivable") 
                          setHasChanged(true);
                          }}
                          style={{
                            color: draftWorkspace?.data?.all_checked_receivable?((draftWorkspace?.data?.all_checked_receivable?.includes(receivable._id) 
                                    && selectedReceivable.includes(receivable._id)) 
                                    ? "#03565A" 
                                    :(draftWorkspace?.data?.all_checked_receivable?.includes(receivable._id) 
                                    ||selectedReceivable.includes(receivable._id))? "#f08998" :'#03565A'):"#03565A"
                          }}
                        >
                          <div className="flex flex-column" >
                            <h6 className="ml-2 mb-0">{receivable?.name}</h6>
                          </div>
                        </WorkspaceSettingCheckBox>
                      ))
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={() => updateCheckedIR("all_checked_receivable", setSelectedReceivable, "Receivable")}
                      >
                        Import selected Receivables
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={ () => importAll(receivable, setSelectedReceivable, "all_checked_receivable", "Receivable") }
                      >
                        Import all Receivables
                      </Button>
                    </div>
                  </div>}

                </WorkspaceSettingAccordion>
                <WorkspaceSettingAccordion name={"Payable"}>
                  <div className="mt-3 handle-scroll">
                    {
                      payable.length > 0 && payable?.map((payable, ix) => (
                        <WorkspaceSettingCheckBox
                          key={ix}
                          companyName={payable?.company}
                          checked={draftWorkspace?.data?.all_checked_payable?draftWorkspace?.data?.all_checked_payable?.includes(payable._id):selectedPayable?.includes(payable?._id)}
                          disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"||permission && permission.length === 0}
                          id={payable?._id}
                          onChange={ (e) =>{ handleSelect(e, payable._id, setSelectedPayable, "all_checked_payable") 
                          setHasChanged(true);
                          }}
                          style={{
                            color: draftWorkspace?.data?.all_checked_payable?((draftWorkspace?.data?.all_checked_payable?.includes(payable._id) 
                                    && selectedPayable.includes(payable._id)) 
                                    ? "#03565A" 
                                    :(draftWorkspace?.data?.all_checked_payable?.includes(payable._id) 
                                    ||selectedPayable.includes(payable._id))? "#f08998" :'#03565A'):"#03565A"
                          }}
                        >
                          <div className="flex flex-column" >
                            <h6 className="ml-2 mb-0">{payable?.name}</h6>
                          </div>
                        </WorkspaceSettingCheckBox>
                      ))
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={() => updateCheckedIR("all_checked_payable", setSelectedPayable, "Payable")}
                      >
                        Import selected Payables
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={ () => importAll(payable, setSelectedPayable, "all_checked_payable", "Payable") }
                      >
                        Import all Payables
                      </Button>
                    </div>
                  </div>}
                </WorkspaceSettingAccordion>
                <WorkspaceSettingAccordion name={"Presets"} isEmpty={true} />
                <WorkspaceSettingAccordion name={"Chart of Account"} isEmpty={true} />
                <WorkspaceSettingAccordion name={"Loans"}>
                  <div className="mt-3 handle-scroll">
                    {
                      loans.length > 0 && loans?.map((loans, ix) => (
                        <WorkspaceSettingCheckBox
                          key={ix}
                          companyName={loans?.company}
                          checked={draftWorkspace?.data?.all_checked_loans?draftWorkspace?.data?.all_checked_loans?.includes(loans._id):selectedLoans?.includes(loans._id)}
                          disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"||permission && permission.length === 0}
                          id={loans._id}
                          onChange={ (e) =>{ handleSelect(e, loans._id, setSelectedLoans, "all_checked_loans")
                          setHasChanged(true);
                          } }
                          style={{
                            color: draftWorkspace?.data?.all_checked_loans?((draftWorkspace?.data?.all_checked_loans?.includes(loans._id) 
                                    && selectedLoans.includes(loans._id)) 
                                    ? "#03565A" 
                                    :(draftWorkspace?.data?.all_checked_loans?.includes(loans._id) 
                                    ||selectedLoans.includes(loans._id))? "#f08998" :'#03565A'):"#03565A"
                          }}
                        >
                          <div className="flex flex-column" >
                            <h6 className="ml-2 mb-0">{loans?.name}</h6>
                          </div>
                        </WorkspaceSettingCheckBox>
                      ))
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={() => updateCheckedIR("all_checked_loans", selectedLoans, "Loans")}
                      >
                        Import selected Loans
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                        onClick={ () => importAll(loans, setSelectedLoans, "all_checked_loans", "Loans") }
                      >
                        Import all Loans
                      </Button>
                    </div>
                  </div>}

                </WorkspaceSettingAccordion>
                <WorkspaceSettingAccordion name={"Book Closure"} isEmpty={true} />

                {/* <div className="d-flex justify-content-center mt-3">
                  <Button className="mr-3 text-capitalize rounded text-white save-button custom-common-theme-bg-color" >Save</Button>
                  <Button className="text-capitalize rounded text-white save-button custom-common-theme-bg-color" >Cancel</Button>
                </div> */}
              </WorkspaceSettingAccordion>

              <WorkspaceSettingAccordion name={"Import Presets"} >
                <div>
                  <div class="d-flex justify-content-between mt-3">
                    <h6 className="mt-2 font-weight-bold mr-5">Presets created</h6>
                    {users.isAdmin==="No"?<></>:
                    <button className="custom-common-theme-text-color preset-button bg-white" onClick={handleSelectAllPresets}
                    disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                    >Select all preset</button>}
                  </div>
                  <div className="mt-3 handle-scroll">
                    {
                      presets.length > 0 && presets?.map((presets, ix) => {
                        const { _id } = presets;
                        const selectedUser = selectedpresets?.indexOf(_id) !== -1;
                        return (
                          <WorkspaceSettingCheckBox
                            key={ix}
                            checked={draftWorkspace?.data?.all_preset_checked?draftWorkspace?.data?.all_preset_checked?.includes(_id):selectedUser}
                            disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"||permission && permission.length === 0}
                            companyName={companyName(presets.company)}
                            onChange={(event) =>{
                              handleChangePreset(
                                "check_company",
                                event,
                                _id,
                                'all_preset_checked'
                              )
                              setHasChanged(true);
                             }
                            }
                            style={{
                              color: draftWorkspace?.data?.all_preset_checked?((draftWorkspace?.data?.all_preset_checked?.includes(presets._id) 
                                      && selectedpresets?.includes(presets._id)) 
                                      ? "#03565A" 
                                      :(draftWorkspace?.data?.all_preset_checked?.includes(presets._id) 
                                      ||selectedpresets?.includes(presets._id))? "#f08998" :'#03565A'):"#03565A"
                            }}
                          >
                            <div className="flex flex-column" >
                              <h6 className="ml-2 mb-0">{presets?.project_name}
                                {CoaName(presets._id)}
                              </h6>
                              <div className="d-flex ml-2" >
                                <div className="text-secondary" >
                                  {
                                    presets?.previous_year ?
                                      <>
                                        <CalendarMonthIcon className="font-size-14" />
                                        <span className="font-size-12">
                                          {moment(presets?.previous_year).format("MMMM DD,YYYY")}
                                        </span>
                                      </>
                                      : ""
                                  }
                                </div>
                                <div className="text-secondary" >
                                  {
                                    presets?.createdAt ?
                                      <>
                                        <CalendarMonthIcon className="font-size-14" />
                                        <span className="font-size-12">
                                          {moment(presets?.createdAt).format("MMMM DD,YYYY")}
                                        </span>
                                      </>
                                      : ""
                                  }
                                </div>
                              </div>
                            </div>
                          </WorkspaceSettingCheckBox>
                        )
                      })
                    }
                  </div>
                  {users.isAdmin==="No"?<></>:
                  <div className="d-flex">
                    <div>
                      <Button
                        className="font-sans ml-0 custom-common-theme-bg-color text-white import-button"
                        onClick={handleSelectedPresets}
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                      >
                        Import selected Preset
                      </Button>
                    </div>
                    <div className="ml-3">
                      <Button
                        className="font-sans import-button custom-common-theme-text-color"
                        onClick={importAllPresets}
                        disabled={permission && permission.length > 0 && permission[0].addcompanies===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                      >
                        Import all Preset
                      </Button>
                    </div>
                  </div>}
                </div>
              </WorkspaceSettingAccordion>

              <WorkspaceSettingAccordion name={"Workspace Date"}>
                <div className="d-flex flex-column align-items-center mt-3">
                  <div className="d-flex justify-content-between" style={{ gap: "35px", flexWrap: "wrap" }}>
                    {/* CY */}
                    <div className="text-center mb-4 mx-auto" style={{ maxWidth: "100%" }}>
                      <h6 className="ml-5">CY</h6>
                      <div className="d-flex align-items-center justify-content-center flex-wrap">
                        <div className="mr-3 mt-2"><h6>Standalone</h6></div>
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker', 'MobileDatePicker']} >
                              <MobileDatePicker 
                                value={draftWorkspace?.data?.standalone_cy ? dayjs(draftWorkspace.data.standalone_cy) : dayjs(workspaceDetails.standalone_cy)}
                                disabled={permission && permission.length > 0 && permission[0].editworkspace_name===false || users.responsibilities==="approver"||permission && permission.length === 0}
                                onChange={(value) =>
                               {if(draftWorkspace?.data){
                                setDraftWorkspace((prevState) => ({
                                  ...prevState,
                                  data: {
                                      ...prevState.data,
                                      standalone_cy: value
                                  }
                              }));
                               }else{
                                setWorkspaceDetails({ ...workspaceDetails, standalone_cy: value })
                               }
                                setHasChanged(true);
                              }}
                              sx={{
                                '& .MuiInputBase-root': {
                                    color:draftWorkspace?.data?.standalone_cy && workspaceDetails.standalone_cy !== draftWorkspace?.data?.standalone_cy?"#f08998":"inherit"
                                },
                              }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                    {/* PY */}
                    <div className="text-center mb-4 mx-auto pybox">
                      <h6 className="mr-5">PY</h6>
                      <div className="d-flex align-items-center justify-content-center flex-wrap">
                        <div>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker', 'MobileDatePicker']}>
                            <MobileDatePicker 
                              value={draftWorkspace?.data?.standalone_py ? dayjs(draftWorkspace.data.standalone_py) :dayjs(workspaceDetails.standalone_py)} 
                              maxDate={draftWorkspace?.data?.standalone_cy ? dayjs(new Date(draftWorkspace?.data?.standalone_cy)) :dayjs(new Date(workspaceDetails.standalone_cy))}
                              disabled={permission && permission.length > 0 && permission[0].editworkspace_name===false || users.responsibilities==="approver"||permission && permission.length === 0}
                              onChange={(value) => {
                                if(draftWorkspace?.data){
                                  setDraftWorkspace((prevState) => ({
                                    ...prevState,
                                    data: {
                                        ...prevState.data,
                                        standalone_py: value
                                    }
                                }));
                                 }else{
                                  setWorkspaceDetails({ ...workspaceDetails, 
                                    standalone_py : value 
                                  })
                                 }
                                setHasChanged(true);
                               }}
                              sx={{
                                '& .MuiInputBase-root': {
                                    color:draftWorkspace?.data?.standalone_py && workspaceDetails.standalone_py !== draftWorkspace?.data?.standalone_py?"#f08998":"inherit"
                                },
                            }}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {users.isAdmin==="No"?<></>:
                <div className="d-flex justify-content-center mt-3">
                  <Button
                    onClick={HandleWorkspaceYearUpdate}
                    disabled={permission && permission.length > 0 && permission[0].editworkspace_name===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                    className="mr-3 text-capitalize rounded text-white save-button custom-common-theme-bg-color"
                  >
                    Save
                  </Button>
                  <Button 
                    className="text-capitalize rounded text-white save-button custom-common-theme-bg-color" 
                    onClick={() => dispatch(fetchWSData(id))}
                    disabled={permission && permission.length > 0 && permission[0].editworkspace_name===false || users.responsibilities==="approver"|| users.responsibilities==="preparer"||permission && permission.length === 0}
                  >
                    Cancel
                  </Button>
                </div>
                }
              </WorkspaceSettingAccordion>

            </div>
            {draftWorkspace.user_id===users._id && draftWorkspace.current_status==="cancelled" || users.responsibilities==="preparer"&& hasChanged===true?
              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="mr-4 text-capitalize rounded text-white w-20 custom-common-theme-bg-color"
                  onClick={handleOpenModal}
                  disabled={draftWorkspace.current_status==="draft"}
                >
                  {draftWorkspace.current_status==="draft"?"Already Sent For Approval":"Send For Approval"}
                </Button>
                <Button
                  className="mr-4 text-capitalize rounded text-white custom-common-theme-bg-color w-20"
                  onClick={handleOpenRemarkModal}
                >
                  Remark
                </Button>
              </div>: draftWorkspace.approver_id===users._id&& draftWorkspace?.current_status==="draft"&&hasApproved ==="draft"?
              <>
              <div className="d-flex justify-content-end mt-3">
                <Button
                  className="mr-4 text-capitalize rounded text-white save-button custom-common-theme-bg-color"
                  onClick={handleApprove}
                >
                  Approve
                </Button>
                <Button
                  className="mr-4 text-capitalize rounded text-white save-button custom-common-theme-bg-color"
                  onClick={() =>
                   handleOpenCancelModal() 
                 }
                >
                  Cancel
                </Button>
              </div>
              </>:<></>
             }
          </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                  <Box sx={stylemodal}>
                        <FormControl fullWidth className="mt-5">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={approverId|| ''}
                              onChange={handleApproverChange}
                            >
                            {approvalId && approvalId.length>0&&approvalId.map((data)=>
                              <MenuItem key={data._id} value={data._id}>{data.name}</MenuItem>
                            )}
                            </Select>
                        </FormControl>
                        <div className="d-flex flex-row justify-content-center mt-3 ">
                            <Button style={{
                                backgroundColor: "#03565a",
                                color: "white",
                                textTransform: "none",
                                margin:"10px",
                                height:"36px",
                                width:"50%"
                              }}
                              className="font-sans"
                              onClick={handleCloseModal}
                              >
                                Return
                            </Button>
                            <Button
                              style={{
                                backgroundColor: "#03565a",
                                color: "white",
                                textTransform: "none",
                                width:"50%",
                                height:"36px",
                                marginTop:"10px"
                              }}
                              disabled={!approverId}
                              className="font-sans"
                              onClick={addDraftWorkspace}
                            >
                              Send for approval
                            </Button>
                        </div>
                  </Box>
            </Modal>
            <Modal
              open={openCancelModal}
              onClose={handleCloseCancelModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={stylemodal}>
                  <div>
                    <label className="font-sans">Cancel Reason:</label>
                    <textarea 
                    className="w-100"
                    value={reason}
                    onChange={(event)=>setReason(event.target.value)}
                    />
                  </div>
                  <div className="d-flex  justify-content-center mt-3 ">
                    <Button
                      style={{
                      backgroundColor: "#03565a",
                      color: "white",
                      textTransform: "none",
                      width:"50%",
                      marginTop:"10px"
                      }}
                      className="font-sans"
                      onClick={handleCancel}
                    >
                      Submit
                    </Button>
                </div>
              </Box>
           </Modal>
           <Modal
              open={openRemarkModal}
              onClose={handleCloseRemarkModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              >
              <Box sx={stylemodal}>
              <div className="d-flex flex-row-reverse font-sans" onClick={handleCloseRemarkModal} style={{cursor:"pointer"}}>Close</div>
                  <div style={{maxHeight:"150px",overflowY:"auto"}}className="mt-4">
                    {draftWorkspace.cancel_reason?
                    <p className="font-sans">{draftWorkspace.cancel_reason}</p>:
                    <center> There are no remarks </center>}
                  </div>
              </Box>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default WorkspaceSetting;
