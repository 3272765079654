import React, { useEffect, useState, useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ThemeContext } from '../../helper/DarkModeContext';
import { level1, total, level2, Note} from '../templateTabs/TableDND/tableStyle';
import { formatIndianCurrency } from '../../helper/ConvertValue';
import { useDispatch, useSelector } from "react-redux";
// import { addFsGrpForNoteGrp } from '../../../redux/actions/TemplateTabs/actionDragSelector';
import { socket } from '../../services/socket/socket';
import { CurrentProjectCy, CurrentProjectPy } from '../../helper/pnlCalculation';
import { getChecklistData } from '../../services/api/StandAlone/disclosureChecklist';
import swal from 'sweetalert';
import { useHistory } from 'react-router';
import Checkbox from '@mui/material/Checkbox';
import { removeSelectedNotesForSD, selectNotesForSD } from '../../redux/actions/TemplateTabs/actionDN';


const TableCell = ({ content, columnIndex, rowIndex, groupIndex, moveCell, isDraggable, cellKey, cellData, handleClick, HandleReference, actionKey, reduxChecklistData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [{ isDragging }, drag, preview] = useDrag({
      type: 'TABLE_CELL',
      item: { content, columnIndex, rowIndex, groupIndex, isDraggable },
      canDrag: () => isDraggable,
      options: {
        previewOptions: {
          captureDraggingState: true,
        },
      },
    });
  
    const [, drop] = useDrop({
      accept: 'TABLE_CELL',
      canDrop: () => isDraggable,
      drop: (draggedItem, monitor) => {
        if (
          draggedItem.rowIndex !== rowIndex ||
          draggedItem.columnIndex !== columnIndex ||
          draggedItem.groupIndex !== groupIndex
        ) {
          moveCell(draggedItem.columnIndex, draggedItem.rowIndex, columnIndex, rowIndex, groupIndex);
          draggedItem.rowIndex = rowIndex;
          draggedItem.columnIndex = columnIndex;
          draggedItem.groupIndex = groupIndex;
        }
      },
    });

    const SwitchReferenceTab = (element) => {
      if(element?.is_reference === "ind_as_reference"){
        dispatch({ type: "IND_AS_1_FROM_BS_PNL", payload: element.refData.name });
        history.push("/library-indas-1")
      }
      if(element?.is_reference === "checklist_reference"){
        const index = reduxChecklistData.findIndex((item) => item.name === element.refData.name);
        dispatch({ type: "NOTE_KEY", payload: 8 });
        dispatch({ type: "NOTE_INDEX", payload: index });
      }
    }

    return (
        <>
        {cellKey === 'notes_no' ? 
            <td className="font-sans" style={Note} onClick={()=>handleClick(cellData.notes_index)}>
            {cellData.notes_index}
            </td>
            : cellKey === 'is_reference' ?
              <>
                <td>
                  <input 
                    type="checkbox" 
                    value={"ind_as_reference"}
                    disabled={!cellData["reference"]}
                    onChange={(e) => HandleReference(e, actionKey, cellData.group, rowIndex)}
                    checked={content === "ind_as_reference"}
                  />
                </td>
                <td>
                  <input 
                    type="checkbox"
                    disabled={!cellData["reference"]}
                    value={"checklist_reference"}
                    onChange={(e) => HandleReference(e, actionKey, cellData.group, rowIndex)}
                    checked={content === "checklist_reference"}
                  />
                </td>
              </>
            :cellKey === 'reference' ? 
              <td className={`text-left pl-3 ${cellData["is_reference"] ? "hyper-link-ref cursor-pointer" : "" }`} onClick={() => SwitchReferenceTab(cellData) }>
                {content}
              </td> : 
            <td
                className={`font-sans ${['cy', 'py'].includes(cellKey) ? "text-right pr-3" : "text-left pl-3"}`}
                ref={(node) => {
                preview(drop(node)); // Pass preview ref to drop
                drag(node); // Attach drag ref
                }}
                style={{
                ...level2,
                opacity: isDragging ? 0.5 : 1,
                transition: 'opacity 0.3s ease',
                cursor: isDraggable ? 'grab' : 'default', // Set cursor style based on draggability
                }}
            >
                {['cy', 'py'].includes(cellKey) ? formatIndianCurrency(content) : content}
            </td>
}
        </>
    );
};
  
const TableRow = ({ cells, rowIndex, groupIndex, moveCell, isDraggable, handleClick, HandleReference, actionKey, reduxChecklistData, selectNotes }) => {
const { selectedNotes } = useSelector((state)=>state.NotesSoftDelete)

const handleCheckbox = (cells) => {
  const checked = selectedNotes.find(note => note.notes_no === cells.notes_no);
  return checked ? true : false;
}

return (
    <tr>
      <td className="font-sans">
        <Checkbox 
          checked={handleCheckbox(cells)} 
          onChange={(e)=>selectNotes(e, cells)}
        />
      </td>
    {Object.keys(cells).filter(item => !['refData', 'notes_index', 'group', 'headings'].includes(item)).map((content, columnIndex) => (
        <TableCell
            key={columnIndex}
            content={cells[content]}
            cellKey={content}
            cellData={cells}
            columnIndex={columnIndex}
            rowIndex={rowIndex}
            groupIndex={groupIndex}
            moveCell={moveCell}
            isDraggable={isDraggable && columnIndex === 0}
            handleClick={handleClick}
            actionKey={actionKey}
            HandleReference={HandleReference}
            reduxChecklistData={reduxChecklistData}
        />
    ))}
    </tr>
);
};
  
export const BSTableDND = ({headerData, groupData, tableData, handleClick, TotalNoteGroupsValue, companyType, HandleReference}) => {
    const [headers, setHeaders] = useState([]);
    const [groups, setGroups] = useState([]);
    const [rows, setRows] = useState({});
    const { theme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");
    const reduxChecklistData = useSelector((initialState) => initialState.DisclosureChecklistReducer.disclosureChecklistData);

    useEffect(()=>{
        setHeaders(headerData);
        setGroups(groupData);
        setRows(tableData)
    }, [headerData, groupData, tableData])

    useEffect(() => {
      if (reduxChecklistData.length <= 0) {
        getChecklistData(project_id, tb_id).then(response => {
          dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
        }).catch(error => {
          swal("Error", error, "error");
        });
      }
    }, [reduxChecklistData])

    const TotalAssets = (type) => {
        const currentAssets = companyType === 'NBFC' ? 'Financial assets' : 'Current Assets';
        const nonCurrentAssets = companyType === 'NBFC' ? 'Non-financial Assets' : 'Non Current Assets';
        return TotalNoteGroupsValue(nonCurrentAssets, type) + TotalNoteGroupsValue(currentAssets, type)
      }
    
      const TotalLiabilities = (type) => {
        const currentLiabilities = companyType === 'NBFC' ? 'Financial liabilities' : 'Current Liabilities';
        const nonCurrentLiabilities = companyType === 'NBFC' ? 'Non-financial liabilities' : 'Non Current Liabilities';
        return TotalNoteGroupsValue(nonCurrentLiabilities, type) + TotalNoteGroupsValue(currentLiabilities, type)
      }

      const selectNotes = (e, cell) => {
        if(e.target.checked){
            dispatch(selectNotesForSD({type: 'bs', notes_no: cell.notes_no, note_index: cell.notes_index, group: cell.group}))
        }else{
            dispatch(removeSelectedNotesForSD({type: 'bs', notes_no: cell.notes_no, note_index: cell.notes_index}))
        }
    }

    const moveCell = (startColumnIndex, startRowIndex, endColumnIndex, endRowIndex, groupIndex) => {
        const updatedRows = { ...rows };
        const draggedCell = updatedRows[groups[groupIndex]][startRowIndex]
        const endDraggedCell = updatedRows[groups[groupIndex]][endRowIndex]
        updatedRows[groups[groupIndex]][startRowIndex] = {
        ...updatedRows[groups[groupIndex]][startRowIndex],
        notes_grp: endDraggedCell.notes_grp}
        
        updatedRows[groups[groupIndex]][endRowIndex] = { 
        ...endDraggedCell, 
        notes_grp: draggedCell.notes_grp};
        socket.emit("rearrange-balance-sheet", {
            project_id: project_id,
            tb_id: tb_id,
            fsGroup: groups[groupIndex], 
            draggedIndex: startRowIndex, 
            targetIndex: endRowIndex
        })
        setRows(updatedRows);
    };

    return (
        <DndProvider backend={HTML5Backend}>
        <div>
            <table style={{ width: '100%' }}>
            <thead>
                <tr>
                {headers.map((header, index) => (
                    <th
                    key={index}
                    className="text-uppercase font-inter font-size-15"
                    style={{
                        padding: '8px',
                        border: '1px solid #ccc',
                        margin: '15px',
                        backgroundColor:"#a2c0c2",
                        minWidth: '6rem'
                    }}
                    >
                    {header}
                    </th>
                ))}
                </tr>
            </thead>
            <tbody
            style={{
                background: `${theme ? "" : "#363535"}`,
                color: `${theme ? "" : "#fff"}`,
              }}
            >
                {groups.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                    <tr style={level1}>
                      <td className='font-sans'></td>
                      <td className="font-sans text-left pl-3">
                          {group}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {rows[group].map((cells, rowIndex) => (
                    <TableRow
                        key={rowIndex}
                        cells={{...cells, group: group, reference: cells?.refData?.reference, is_reference: cells?.refData?.isReference }}
                        rowIndex={rowIndex}
                        groupIndex={groupIndex}
                        moveCell={moveCell}
                        isDraggable={true}
                        handleClick={handleClick}
                        HandleReference={HandleReference}
                        actionKey="bs"
                        reduxChecklistData={reduxChecklistData}
                        selectNotes={selectNotes}
                    />
                    ))}
                    <tr key={groupIndex} style={{ fontWeight: "bold" }} >
                        <td></td>
                        <td className="font-sans text-left pl-3">
                          {`Total ${group}`}
                        </td>
                        <td></td>
                        <td className="font-sans text-right pr-3">
                          {formatIndianCurrency(TotalNoteGroupsValue(group, 'cy'))}
                        </td>
                        <td className="font-sans text-right pr-3">
                          {formatIndianCurrency(TotalNoteGroupsValue(group, 'py'))}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {
                        groupIndex === 1 && (
                          <tr key={groupIndex} style={total}>
                            <td></td>
                            <td className="font-sans text-left pl-3">
                              {companyType === 'NBFC' ? 'Total Financial and Non Financial Assets' : "Total Non Current & Current Assets"}
                            </td>
                            <td></td>
                            <td className="font-sans text-right pr-3">{formatIndianCurrency(TotalAssets('cy'))}</td>
                            <td className="font-sans text-right pr-3">{formatIndianCurrency(TotalAssets('py'))}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        )
                      }
                      {
                        groupIndex === 4 && (
                          <React.Fragment>
                            <tr key={groupIndex} style={total}>
                            <td></td>
                              <td className="font-sans text-left pl-3">
                                {companyType === 'NBFC' ? 'Total Financial and Non-Financial liabilities' : "Total Non Current & Current Liabilities"}
                              </td>
                              <td></td>
                              <td className='text-right pr-3'>{formatIndianCurrency(TotalLiabilities('cy'))}</td>
                              <td className='text-right pr-3'>{formatIndianCurrency(TotalLiabilities('py'))}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr key={groupIndex} style={total}>
                            <td></td>
                              <td className="font-sans text-left pl-3">
                                {"Total Equity & Liabilities"}
                              </td>
                              <td></td>
                              <td className='text-right pr-3'>{formatIndianCurrency(TotalNoteGroupsValue("Equity", 'cy') + TotalLiabilities('cy'))}</td>
                              <td className='text-right pr-3'>{formatIndianCurrency(TotalNoteGroupsValue("Equity", 'py') + TotalLiabilities('py'))}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </React.Fragment>
                        )
                      }
                </React.Fragment>
                ))}
            </tbody>
            </table>
        </div>
        </DndProvider>
    );
};

export const PNLTableDND = ({headerData, groupData, tableData, handleClick, TotalNoteGroupsValue, companyType, HandleReference}) => {
    const [headers, setHeaders] = useState([]);
    const [groups, setGroups] = useState([]);
    const [rows, setRows] = useState({});
    const { theme } = useContext(ThemeContext);
    const dispatch = useDispatch();
    const project_id = localStorage.getItem("project_id");
    const tb_id = localStorage.getItem("tb_id");
    const reduxChecklistData = useSelector((initialState) => initialState.DisclosureChecklistReducer.disclosureChecklistData);

    useEffect(()=>{
        setHeaders(headerData);
        setGroups(groupData);
        setRows(tableData)
    }, [headerData, groupData, tableData])

    useEffect(() => {
      if (reduxChecklistData.length <= 0) {
        getChecklistData(project_id, tb_id).then(response => {
          dispatch({ type: "DISCLOSURE_CHECKLIST_DATA", payload: response?.data?.data });
        }).catch(error => {
          swal("Error", error, "error");
        });
      }
    }, [reduxChecklistData])

    const selectNotes = (e, cell) => {
     
      if(e.target.checked){
          dispatch(selectNotesForSD({type: 'pnl', notes_no: cell.notes_no, note_index: cell.notes_index, group: cell.group}))
      }else{
          dispatch(removeSelectedNotesForSD({type: 'pnl', notes_no: cell.notes_no, note_index: cell.notes_index}))
      }
    }

    const moveCell = (startColumnIndex, startRowIndex, endColumnIndex, endRowIndex, groupIndex) => {
        const updatedRows = { ...rows };
        const draggedCell = updatedRows[groups[groupIndex]][startRowIndex]
        const endDraggedCell = updatedRows[groups[groupIndex]][endRowIndex]
        updatedRows[groups[groupIndex]][startRowIndex] = {
        ...updatedRows[groups[groupIndex]][startRowIndex],
        notes_grp: endDraggedCell.notes_grp}
        
        updatedRows[groups[groupIndex]][endRowIndex] = { 
        ...endDraggedCell, 
        notes_grp: draggedCell.notes_grp};
        socket.emit("rearrange-pnl-statement", {
            project_id: project_id,
            tb_id: tb_id,
            fsGroup: groups[groupIndex], 
            draggedIndex: startRowIndex, 
            targetIndex: endRowIndex
        })
        setRows(updatedRows);
    };

    const calCulateProfitBefore = (type) => {
        try {
          const income = companyType === 'NBFC' ? 'Revenue from operations' : 'Income';
          const expenses = 'Expenses'
          const otherIncome = 'Other Income'
          if(type === "cy"){
            const currProject = CurrentProjectCy(tableData, income) + CurrentProjectCy(tableData, otherIncome)
            return ( companyType === 'NBFC' ? currProject : CurrentProjectCy(tableData, income) ) - CurrentProjectCy(tableData, expenses)
          }else if(type === "py"){
            const currProject = CurrentProjectPy(tableData, income) + CurrentProjectPy(tableData, otherIncome)
            return ( companyType === 'NBFC' ? currProject : CurrentProjectPy(tableData, income) ) - CurrentProjectPy(tableData, expenses)
          }
        } catch (error) {
          console.log("err", error.message)
          return "**"
        }
      }
    
      const calProfitBeforeTax = (type) => {
        if(type === 'cy'){
          return calCulateProfitBefore(type) - CurrentProjectCy(tableData, 'Profit before exceptional items and tax');
        }else if(type === "py"){
          return calCulateProfitBefore(type) - CurrentProjectPy(tableData, 'Profit before exceptional items and tax');
        }
      }
    
      const calProfitContinuing = (type) => {
        if(type === 'cy'){
          return calProfitBeforeTax(type) - CurrentProjectCy(tableData, 'Tax expense');
        }else if(type === "py"){
          return calProfitBeforeTax(type) - CurrentProjectPy(tableData, 'Tax expense');
        }
      }

    return (
        <DndProvider backend={HTML5Backend}>
            <div>
                <table style={{ width: '100%' }}>
                    <thead>
                        <tr>
                        {headers.map((header, index) => (
                           <th
                           key={index}
                           className="text-uppercase font-inter font-size-15"
                           style={{
                               padding: '8px',
                               border: '1px solid #ccc',
                               margin: '15px',
                               backgroundColor:"#a2c0c2",
                               minWidth: '6rem'
                           }}
                           >
                           {header}
                           </th>
                        ))}
                        </tr>
                    </thead>
                    <tbody
                        style={{
                            background: `${theme ? "" : "#363535"}`,
                            color: `${theme ? "" : "#fff"}`,
                        }}
                    >
                        {groups.map((group, groupIndex) => (
                        <React.Fragment key={groupIndex}>
                            <tr style={[3,4,5,6].includes(groupIndex) ? {...level1,backgroundColor:"transparent"} : level1}>
                              <td className="font-sans"></td>
                                <td className="font-sans text-left pl-3">
                                    {group}
                                </td>
                                <td></td>

                                <td className='font-sans text-right pr-3'>
                                {companyType === 'NBFC' && group === 'Profit before exceptional items and tax' 
                                ? formatIndianCurrency(calCulateProfitBefore('cy'))
                                : companyType === 'NBFC' && group === 'Profit before tax' 
                                ? formatIndianCurrency(calProfitBeforeTax('cy'))
                                : companyType === 'NBFC' && group === 'Profit for the period from continuing operations'
                                ? formatIndianCurrency(calProfitContinuing('cy'))
                                : ""
                                // formatIndianCurrency(TotalNoteGroupsValue(group, 'cy'))
                                }
                                </td>
                                <td className='font-sans text-right pr-3'>
                                    {companyType === 'NBFC' && group === 'Profit before exceptional items and tax' 
                                    ? formatIndianCurrency(calCulateProfitBefore('py'))
                                    : companyType === 'NBFC' && group === 'Profit before tax' 
                                    ? formatIndianCurrency(calProfitBeforeTax('py'))
                                    : companyType === 'NBFC' && group === 'Profit for the period from continuing operations'
                                    ? formatIndianCurrency(calProfitContinuing('py'))
                                    :  ""
                                    // formatIndianCurrency(TotalNoteGroupsValue(group, 'py'))
                                    }
                                </td>
                                {/* <td></td>
                                <td></td> */}
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {rows[group].map((cells, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                cells={{...cells, group: group, reference: cells?.refData?.reference,is_reference: cells?.refData?.isReference}}
                                rowIndex={rowIndex}
                                groupIndex={groupIndex}
                                moveCell={moveCell}
                                isDraggable={true}
                                handleClick={handleClick}
                                HandleReference={HandleReference}
                                actionKey="pnl"
                                reduxChecklistData={reduxChecklistData}
                                selectNotes={selectNotes}
                            />
                            ))}
                            {(companyType === 'Manufacturing' && groupIndex === 1) || 
                            ([3,4,6].includes(groupIndex)) ? <></> :
                            <tr style={{fontWeight:"bold"}}>
                              <td className='font-sans'></td>
                                <td className="font-sans text-left pl-3">
                                {`Total ${group}`}
                                </td>
                                <td></td>
                                <td className='text-right pr-3'>
                                { formatIndianCurrency(TotalNoteGroupsValue(group, 'cy')) }
                                </td>
                                <td className='text-right pr-3'>
                                { formatIndianCurrency(TotalNoteGroupsValue(group, 'py')) }
                                </td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            }
                        </React.Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </DndProvider>
    )
}