import axios from 'axios';
// const auth = localStorage.getItem("auth_token");
const getToken = ()=>{
    return localStorage.getItem("auth_token");
}
const headers = {
    "x-auth-token": getToken(),
};



export const state = async () => { 
    return axios.get(`api/v1/seeder/get-state`)
}
export const country = async () => { 
    return axios.get(`api/v1/seeder/get-county`)
}
export const cities = async () => { 
    return axios.get(`api/v1/seeder/get-cities`)
}

export const signupV2 = async (data) => { 
    return axios.post(`api/v1/auth/signupV2`,data)
}

export const VerifyOtp = async (data) => { 
    return axios.post(`api/v1/auth/send-otp`,data)
}

export const getCompany = async () => { 
    return axios.get(`api/v1/company/get-comapny`,{headers})
}

export const resetPassword = async (data) => { 
    return axios.post(`api/v1/superadmin/resetpassword`,data)
  }

  export const createNewPassword = async (data) => { 
    return axios.post(`api/v1/superadmin/createpassword`,data)
  }

  export const emailverification = async (data) => { 
    return axios.post(`api/v1/auth/verify-email`,data)
  }

export const multiCompany = async (data) => { 
    return axios.post(`api/v1/company/multiple-company`,data,{headers})
}

export const getWorkspaceMultiCompany = async () => { 
    return axios.get(`api/v1/company/get-multipleCompany`,{headers})
}

export const createCompany = async (data) => { 
    return  axios.post('api/v1/company/create-company', data, { headers })
}

export const CreateWorkspace = async (data)=>{
    return axios.post('api/v1/workspace/create-workspace',data,{headers})
}

export const createWorkspaceName =async(w_id,data)=>{
    return axios.post(`api/v1/workspace/update-workspaceName/${w_id}`,data,{headers})
}
export const UpdateStepStatus =async(w_id,data)=>{
    return axios.post(`api/v1/workspace/update-status/${w_id}`,data,{headers})
}

export const CreateIr =async(data)=>{
    return axios.post(`api/v1/workspace/set-allCheckedIr`,data,{headers})
}

export const CreateCompany =async(data)=>{
    return axios.post(`api/v1/workspace/set-allCheckedCompany`,data,{headers})
}
export const CreatePresetCheck =async(data)=>{
    return axios.post(`api/v1/workspace/set-allCheckedPreset`,data,{headers})
}

export const GetCheckedIR =async(wid)=>{
    return axios.get(`api/v1/workspace/get-checkedIR/${wid}`,{headers})
}

export const GetUser =async(data)=>{
    return axios.get(`api/v1/auth/all-user`,{headers})
}

export const CreateAccess =async(data)=>{
    return axios.post(`api/v1/workspace/set-userAccess`,data,{headers})
}

export const GetAccess =async(data)=>{
    return axios.get(`api/v1/workspace/all-Access`,{headers})
}

export const GetAllCompany =async()=>{
    return axios.get(`api/v1/company/get-allCompany`,{headers})
}

export const GetAllCompanyData =async()=>{
    return axios.get(`api/v1/company/get-allCompanyData`,{headers})
}



export const GetAllProject =async(w_id)=>{
    return axios.get(`api/v1/project/get-project/${w_id}`,{headers})

}

export const GetTeamAccess =async()=>{
    return axios.get(`api/v1/workspace/all-Access`,{headers})
}

export const getAllIR = () => {
    return axios.get('api/v1/company-data/all-IR', {
        headers
    })
}

export const getAllInvestRegister = () => {
    return axios.get('api/v1/company-data/allInvestRegister', {
        headers
    })
}

export const getRegisterInvest = (ir_id) => {
    return axios.get(`api/v1/company-data/all-register/${ir_id}`, {
        headers
    })
}

export const getAllWorkspace = () => {
    return axios.get('api/v1/workspace/all-workspace', {
        headers
    })
}
export const AllWorkspace = () => {
    return axios.get('api/v1/workspace/workspace', {
        headers
    })
}
export const getWorkspace = (W_id)=>{
    return axios.get(`api/v1/workspace/get-Oneworkspace/${W_id}`,{
        headers
    })
}
export const accessWorkspacedata = ()=>{
    return axios.get(`api/v1/workspace/get-accessWorkspace`,{
        headers
    })
}
export const getOneAccessWorkspacedata = (wid)=>{
    return axios.get(`api/v1/workspace/get-OneAcessworkspace/${wid}`,{
        headers
    })
}

export const deleteIr = (Ir_id)=>{
    return axios.get(`api/v1/company-data/delete-Ir/${Ir_id}`,{
        headers
    })
}

export const saveFsData = (data)=>{
    return axios.post(`api/v1/prepare-consol/create-fsGroup`,data,{headers})
}

export const updateFsData = (p_id,data)=>{
    return axios.post(`api/v1/prepare-consol/update-preConsol-data/${p_id}`,data,{headers})
}


export const deleteControlFS = (fs_id)=>{
    return axios.get(`api/v1/prepare-consol/delete-prepare-console/${fs_id}`,{
        headers
    })
}

export const WorkspaceUpdate =async(data,w_id)=>{
    return axios.post(`api/v1/workspace/update-workspace/${w_id}`,data,{headers})
}

export const DuplicateWorkspace =async(w_id)=>{
    return axios.post(`api/v1/workspace/duplicate-workspace/${w_id}`,{headers})
}

export const getCheckData = (w_id)=>{
    return axios.get(`api/v1/workspace/get-checkedIR/${w_id}`,{
        headers
    })
}

export const findCompanyData = (c_id)=>{
    return axios.get(`api/v1/company/get-onecomapny/${c_id}`,{
        headers
    })
}

export const TeamMember = (w_id)=>{
    return axios.get(`api/v1/workspace/get-member/${w_id}`,{
        headers
    })
}

export const GetProject =async()=>{
    return axios.get(`api/v1/project/get-project`,{headers})

}

export const getStandaloneFS =async(p_id)=>{
    return axios.get(`api/v1/grouping/fs-data/${p_id}`,{headers})

}

export const saveRolePermission = async(data)=>{
    return axios.post(`/api/v1/rolePermission/save-role`,data,{headers})
}

export const getRolePermission = async()=>{
    return axios.get(`/api/v1/rolePermission/get-role`,{headers})
}

export const saveUserData = async(data)=>{
    return axios.post(`/api/v1/auth/save-member`,data,{headers})
}

export const getMeber = async()=>{
    return axios.get(`/api/v1/auth/all-member`,{headers})
}

// export const saveDeletedMembers = async (data) => {
//         return axios.post(`/api/v1/deleted-users/save-deleted-members`,data,{headers})
// }

export const getDeletedMembers = async () => {
    return axios.get(`/api/v1/auth/fetch-deleted-members`,{headers})
}

export const deleteRoleData = async(r_id)=>{
    return axios.get(`/api/v1/rolePermission/delete-role/${r_id}`,{headers})
}
export const deleteUserData = async(u_id)=>{
    return axios.get(`/api/v1/auth/delete-member/${u_id}`,{headers})
}

export const deleteRestoreData = async (u_id) =>{
    return axios.get(`/api/v1/auth/delete-restore-members/${u_id}`,{headers})
}

export const deleteCompanyData = async(c_id,data)=>{
    return axios.post(`/api/v1/company/delete-comapny/${c_id}`,data,{headers})
}


export const deleteWorkspace = async(w_id)=>{
    return axios.get(`/api/v1/workspace/deleteWorkspace/${w_id}`,{headers})
}

export const editCompanyData = async(c_id,data)=>{
    return axios.post(`/api/v1/company/edit-company/${c_id}`,data,{headers})
}

export const fetchCoa = async()=>{
    return axios.get(`/api/v1/fs-classification/get-coa`,{headers})
}

export const fetchCoaById = async(w_id)=>{
    return axios.get(`/api/v1/fs-classification/getCoa/${w_id}`,{headers})
}


export const deletePreset = (preset_id)=>{
    return axios.get(`api/v1/project/delete-project/${preset_id}`,{
        headers
    })
}

export const createDuplicate = async(ir_id,userId)=>{
    return axios.post(`/api/v1/company-data/duplicateInvest/${ir_id}`,userId,{headers})
}

export const updateProject = async(p_id,data)=>{
    return axios.post(`/api/v1/project/update-project/${p_id}`,data,{headers})
}



export const GetLoan =async()=>{
    return axios.get(`https://loan.test.api.myfinalyst.com/loan/all?sortBy=createdAt&sort=-1`,{headers})
}

export const GetLease =async()=>{
    return axios.get(`${process.env.REACT_APP_LEASE_URL}project/all?sortBy=createdAt&sort=-1`,{headers})
} 


export const createChildUser = async(data)=>{
    return axios.post(`/api/v1/auth/create-child-user/`, data, {
        headers
    })
}

export const updateChildUser = async(id,data)=>{
    return axios.put(`/api/v1/auth/update-child-user/${id}`, data, {
        headers
    })
}

export const getUserPermission = async (user_id) => {
    return await axios.get(`/api/v1/rolePermission/user-permission/${user_id}`, {headers})
}

export const adminAddUserPermission = async (data) => {
    return await axios.post(`/api/v1/rolePermission/admin/permissions`, data, {
        headers
    })
}

export const addCompanyToPermission = async (user_id, data) => {
    return await axios.post(`/api/v1/rolePermission/user-permission/company/${user_id}`, data, {headers})
}


export const deleteCompanyFromPermission = async (user_id, company_id) => {
    return await axios.delete(`/api/v1/rolePermission/delete/user-permission/${user_id}/${company_id}`, {headers})
}

export const updateUserPermission = async (user_id, data) => {
    return await axios.put(`/api/v1/rolePermission/user-permission/${user_id}`, data, {headers})
}   


export const GetReceivable =async()=>{
    return axios.get(`https://receivable.test.api.myfinalyst.com/receivable/all?sortBy=createdAt&sort=-1`,{headers})
}

export const GetPayable =async()=>{
    return axios.get(`https://payable.test.api.myfinalyst.com/payable/all?sortBy=createdAt&sort=-1`,{headers})
}

export const GetLogs =async()=>{
    return axios.get(`/api/v1/rolePermission/logs`,{headers})
}

export const createLogs = async(data)=>{
    return axios.post(`/api/v1/rolePermission/create-logs`, data, {
        headers
    })
}

export const GetSubscriptionPlans =async()=>{
    return axios.get(`/api/v1/superadmin/getplan`,{headers})
}

export const GetSubscriptionPlansDetails = async(plan_id)=>{
    return axios.get(`/api/v1/superadmin/${plan_id}`,{headers})
}

export const getActivePlan = async()=>{
    return axios.get(`/api/v1/payment/get-active-plan`,{headers})
}

export const getAllSubscriptions = async(id)=>{
    return axios.get(`/api/v1/payment/get-all-subscriptions?customer_id=${id}`,{headers})
}

export const getSubscriptionById = async(id)=>{
    return axios.get(`/api/v1/payment/get-subscription-by-id/${id}`,{headers})
}

export const createSubscriptionInvoice = async(data)=>{
    return axios.post(`/api/v1/payment/createInvoice`, data, { headers })
}

export const getInvoiceById = async()=>{
    return axios.get(`/api/v1/payment/get-invoice`,{headers})
}

export const createWorkApprover = async (data)=>{
    return axios.post('api/v1/workspace/add-work-approver',data,{headers})
}
export const getWorkApprover = async (moduleId, pageName, moduleName) => {
    return axios.get(`api/v1/workspace/get-work-approver/?module_id=${moduleId}&page_name=${pageName}&module_name=${moduleName}`, { headers });
}
export const getWorkApproverByModulename = async (moduleName) => {
    return axios.get(`api/v1/workspace/get-work-approverbymodulename/?module_name=${moduleName}`, { headers });
}
export const getChildUser = async(parent_id,workspace_id) => {
    return axios.get(`/api/v1/auth/get-child-users/?parent_id=${parent_id}&workspace_id=${workspace_id}`, { headers });
  }
  
export const getCompanyByApproverId = async () => { 
    return axios.get(`api/v1/company/get-company-approverid`,{headers})
}

export const getApprovedCompany = async () => { 
    return axios.get(`api/v1/company/get-company-approved`,{headers})
}

export const getCompanyByPreparerId = async () => { 
    return axios.get(`api/v1/company/get-company-preparerid`,{headers})
}
   

export const getTicket = async (id) => {
    return axios.get(`api/v1/superadmin-ticket/ticket-contacts/${id}`,{ headers })
} 
   
export const createTicket = async (data) => {
    return axios.post(`api/v1/superadmin-ticket/createticket`, data, { headers })
}  

export const getTicketById = async (id, data) => {
    return axios.get(`api/v1/superadmin-ticket/${id}`, data, { headers })
  }

  export const updateTicket = async (id, data) => {
    return axios.put(`api/v1/superadmin-ticket/${id}`, data, { headers })
  }

  export const postNotes = async (data) => {
    return axios.post(`/api/v1/ticket-note/createnote`,data,{ headers })
  }

  export const getNotes = async (id) => {
    return axios.get(`/api/v1/ticket-note/getnote?ticket_id=${id}`,{ headers })
  }

  export const closeTicketStatus = async (id,data) => {
    return axios.put(`/api/v1/superadmin-ticket/close-status/${id}`,data,{ headers })
  }
  
  export const reopenTicketStatus = async (id,data) => {
    return axios.put(`/api/v1/superadmin-ticket/reopen/${id}`,data,{ headers })
  }

  export const comanyUpdate = async (data) => {
    return axios.post(`/api/v1/auth/companyUpdate`,data,{ headers })
  }

  export const getcomanyUpdate = async (id) => {
    return axios.get(`/api/v1/auth/getcompanyUpdate?user_id=${id}`,{ headers })
  }
export const addworkspaceteamMember = async (data)=>{
    return axios.post('api/v1/workspace/add-team-member',data,{headers})
}

export const getworkspaceteamMember = async (workspace_id)=>{
    return axios.get(`api/v1/workspace/get-team-member?workspace_id=${workspace_id}`,{headers});
}

export const getMemberbyPreparerId = async(parent_id)=>{
    return axios.get(`/api/v1/auth/all-member-bypreparer-id?parent_id=${parent_id}`,{headers})
}
export const deleteworkspaceteamMember = async (data)=>{
    return axios.put('api/v1/workspace/delete-team-member',data,{headers})
}

export const getAllWorkspacebyUserid = () => {
    return axios.get('/api/v1/workspace/all-workspace-by-userid', {headers})
}

export const addworkspacePermission = async (data)=>{
    return axios.post('api/v1/workspace/create-permission',data,{headers})
}

export const getAllWorkspaces = () => {
    return axios.get('api/v1/workspace/get-all-workspace', {
        headers
    })
}

export const GetAllImportedCompanies =async(user_id)=>{
    return axios.get(`api/v1/company/get-all-importedcompany?user_id=${user_id}`,{headers})
}

export const getApprover = async(parent_id) => {
    return axios.get(`/api/v1/auth/get-all-approver/?parent_id=${parent_id}`, { headers });
}

export const getPermission = async(workspace_id) => {
    return axios.get(`/api/v1/workspace/get-permission?workspace_id=${workspace_id}`, { headers });
}

export const addworkspacedraft = async (data)=>{
    return axios.post('api/v1/workspace/add-workspace-draft',data,{headers})
}

export const getworkspacedraft = async (workspace_id)=>{
    return axios.get(`api/v1/workspace/get-workspace-draft?workspace_id=${workspace_id}`,{headers})
}

export const getTeam = async (data) => {
    return axios.get(`api/v1/superadmin-team/teamdata`, data, { headers })
  } 
export const getApproverForDeleteCompany = async(parent_id) => {
    return axios.get(`/api/v1/auth/get-approver-delete-company/?parent_id=${parent_id}`, { headers });
}

export const fetchDraftCompany = (c_id)=>{
    return axios.get(`api/v1/company/get-draftcompany/${c_id}`,{headers})
}

export const updateDraftCompany = (c_id,data)=>{
    return axios.post(`api/v1/company/update-draftcompany/${c_id}`,data,{headers})
}

export const getUserById = async (userId) => {
    return axios.get(`/api/v1/auth/all-user/${userId}`,{ headers })
}
export const getApproverForDeleteWorkspace = async(parent_id,workspace_id) => {
    return axios.get(`/api/v1/auth/get-approver-delete-workspace/?parent_id=${parent_id}&workspace_id=${workspace_id}`, { headers });
}

export const checkDeleteAccess = async(workspace_id) => {
    return axios.get(`/api/v1/workspace/check-delete-access/?workspace_id=${workspace_id}`, { headers });
}

export const permittedPreparerIds = async(workspace_id,data) => {
    return axios.post(`/api/v1/workspace/permitted-preparerId/?workspace_id=${workspace_id}`,data, { headers });
}

export const createRequest = async(data) => {
    return axios.post(`/api/v1/workspace/create-request`,data, { headers });
}

export const getRequest = async() => {
    return axios.get(`/api/v1/workspace/get-request`, { headers });
}

export const getOneRequest = async(data) => {
    return axios.post(`/api/v1/workspace/get-one-request`,data, { headers });
}

export const addDeleteDraftWorkspace = async(data) => {
    return axios.post(`/api/v1/workspace/add-delete-workspace-draft`,data, { headers });
}

export const getDeleteWorkspace = async() => {
    return axios.get(`/api/v1/workspace/get-delete-workspace-draft`, { headers });
}

export const getSingleDeleteWorkspacedraft = async (workspace_id)=>{
    return axios.get(`api/v1/workspace/get-delete-workspace?workspace_id=${workspace_id}`,{headers})
}

export const getGetDataRegisterLogs = async (moduleName)=>{
    return axios.get(`${process.env.REACT_APP_LEASE_URL}dataRegisterLog/get?module=${moduleName}&sortBy=createdAt&sort=-1`, { headers })
}
export const returnDataRegisterLog = async(data, registerId) => {
    return axios.patch(`${process.env.REACT_APP_LEASE_URL}dataRegisterLog/return?id=${registerId}`,data, { headers });
}
export const approveDataRegisterLog = async(registerId) => {
    return axios.patch(`${process.env.REACT_APP_LEASE_URL}dataRegisterLog/approve?id=${registerId}`,{}, { headers });
}

export const GetAllLogs =async()=>{
    return axios.get(`/api/v1/logs/get-logs`,{headers})
}

export const getApproverForEditWorkspaces = async(parent_id,data) => {
    return axios.post(`/api/v1/auth/get-approver-edit-workspace/?parent_id=${parent_id}`,data, { headers });
}

export const getPermissionByUid = async(workspace_id,user_id) => {
    return axios.get(`/api/v1/workspace/get-permission-by-uid?workspace_id=${workspace_id}&user_id=${user_id}`, { headers });
}

export const addTeamMemberDraft = async (data)=>{
    return axios.post('api/v1/workspace/add-team-member-draft',data,{headers})
}

export const getTeamMemberDraft = async (workspace_id)=>{
    return axios.get(`api/v1/workspace/get-draft-teammember?workspace_id=${workspace_id}`,{headers})
}