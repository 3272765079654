export const columnBaseTable = [
    {
        Header: 'LEDGER CODE',
        accessor : 'ledgerCode',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 50,
            width: 250, 
        }
    },
    {
        Header: 'LEDGER NAME',
        accessor : 'ledgerName',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 250,
            width: 250, 
        }
    },
    {
        Header: 'Period Beginning Balance',
        accessor : 'periodBeginingBalance',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 150,
            width: 250, 
        }
    },
    {
        Header: 'Period Ending Balance',
        accessor : 'periodEndingBalance',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 150,
            width: 250, 
        }
    },
    {
        Header: 'Amount In',
        accessor : 'amountIn',
        Style:{
            textTransform:"uppercase",
            maxWidth: 400,
            minWidth: 150,
            width: 250, 
        }
    }
]