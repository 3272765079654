import React from 'react'
import { useHistory, } from "react-router";
import leftarrow from "../../assets/Image/leftarrow.png"
import { useParams } from "react-router";

export const MatrixNav = () => {
    const history = useHistory();
    const { workspaceId, companyId } = useParams();

    return (
        <>
            <div className='custom-common-theme-bg-color' style={{ height: "70px" }}>
                <div className='d-flex  px-3 py-2'>
                    <button 
                        className='d-flex align-items-center mt-1 border-0 bg-color-inherit' 
                        onClick={() => history.push(`/bookclosure-entries/${workspaceId}/${companyId}`)}
                    >
                        <img src={leftarrow} />
                        <p className='mt-0 mb-0 font-size-24 custom-font-600 text-white ml-1'>Back</p>
                    </button>
                </div>
            </div>
        </>
    )
}
