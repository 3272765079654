import { getEntries, getMatrix, getTbDetails } from "../../../Bookclosure/Services/ApiHandler";

export const getBCTbRows = (bookClosureTbId) => {
    return dispatch => {
        dispatch({ type: "TBC_DATA_ACTION_START" })
        getTbDetails(bookClosureTbId).then((response) => {
            dispatch({
                type: "TBC_DATA_FETCH_DATA",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: "TBC_DATA_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}

export const setTabValue = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_TAB_VALUE",
            payload: payload
        })
    }
}

export const filterByCategory = (payload) => {
    const { groupings, type } = payload;
    const categoryItems = groupings.filter(item => item.category === type);

    return dispatch => {
        dispatch({
            type: "CATEGORY_ITEMS",
            payload: categoryItems
        })
    }
}

export const getBCEntries = (bookClosureId, companyId) => {
    return dispatch => {
        dispatch({ type: "BC_ENTRIES_ACTION_START" })
        getEntries(bookClosureId, companyId).then((response) => {
            dispatch({
                type: "BC_ENTRIES_FETCH_DATA",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: "BC_ENTRIES_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}

export const getBCMatrix = (bookClosureId, companyId) => {
    return dispatch => {
        dispatch({ type: "BC_MATRIX_ACTION_START" })
        getMatrix(bookClosureId, companyId).then((response) => {
            dispatch({
                type: "BC_MATRIX_FETCH_DATA",
                payload: response.data
            })
        }).catch((error) => {
            dispatch({
                type: "BC_MATRIX_FETCH_DATA_FAILED",
                payload: error?.response?.data?.error
            })
        });
    }
}

export const getLedgersViewMoreTask = (payload) => {
    return dispatch => {
        dispatch({ type: "SET_VIEW_MORE_LEDGERS",  payload});
    }
}

export const clearLedgersViewMoreTask = () => {
    return dispatch => {
        dispatch({ type: "CLEAR_VIEW_MORE_LEDGERS"});
    }
}

export const setIsAddToTackModalOpen = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_ADD_TO_TASK_MODAL_OPEN",
            payload: payload
        })
    }
}

export const setIsEditLedgerModal = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_IS_EDIT_LEDGER_TO_TASK_MODAL",
            payload: payload
        })
    }
}

export const setAddedLedgers = (payload) => {
    return dispatch => {
        dispatch({
            type: "SET_ADDED_LEDGERS",
            payload: payload
        })
    }
}