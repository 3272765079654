import React, { useEffect } from 'react';
import BookcluosureNav from './BookcluosureNav';
import Button from '@mui/material/Button';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getBCEntries } from '../../redux/actions/BookClosure/actionBookClosure';
import { getOneBookClosure } from '../Services/ApiHandler';
import swal from "sweetalert";

const BookClosureEntries = () => {
    const { workspaceId, companyId } = useParams();
    const dispatch = useDispatch();

    const {
        bookClosureId,
    } = useSelector((state) => state.BookClosureReducer);

    const {
        entries,
        sceLoading,
        bceErrorMessage,
        bceApiFetched
    } = useSelector((state) => state.BookClosureReducer);

    useEffect(() => {
        getBookClosureData();
        if (!bceApiFetched) {
            dispatch(getBCEntries(bookClosureId, companyId));
        }
    }, [dispatch]);

    const getBookClosureData = () => {
        const data = {
            // companies: [],
            // assignees: [],
            // approvers: [userId]
        }
        if (!bookClosureId) {
            getOneBookClosure(workspaceId, data).then(response => {
                dispatch({ type: "BOOK_cLOSURE_DATA", payload: response.data.bookClosure || {} });
                const book_closure_id = response.data.bookClosure._id;
            }).catch(error => {
                swal("Error", `Book Closure Error: ${error.response?.data?.error}`, 'error');
            })
        }
    }

    return (
        <>
            <BookcluosureNav />
            <div>
                {
                    sceLoading ? <p>Loading ....</p> :
                        bceErrorMessage ? <p className='text-danger' > {bceErrorMessage} </p> :
                            entries ? <>
                                <table>
                                    <thead style={{ backgroundColor: "#E5E7E9" }}>
                                        <tr>
                                            <th className='text-right text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>S NO</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Ledger Code</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Ledger Name</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Amount</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Preparer</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Approver</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>remarks</th>
                                            <th className='text-left text-uppercase text-secondary font-size-14 custom-font-600 font-inter'>Modules</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {entries?.length > 0 && entries?.map((entry, ex) => (
                                            <tr>
                                                <td>{ex + 1}</td>
                                                <td className='text-left'><div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>{entry?.ledgerCode}</div></td>
                                                <td className='text-left'><div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>{entry?.ledgerName}</div></td>
                                                <td className='text-left'><div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>{entry?.periodEndingBalance}</div></td>
                                                <td className='text-left'><div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>{entry?.approver?.name}</div></td>
                                                <td className='text-left'>
                                                    <div className='ml-3 font-size-14' style={{ fontWeight: 400 }}>
                                                        {entry?.assignees?.length > 0
                                                            ? entry.assignees
                                                                .map(item => item?.name)
                                                                .filter(Boolean)
                                                                .join(", ")
                                                            : null
                                                        }
                                                    </div>
                                                </td>
                                                <td className='text-left'><div className='ml-3 font-size-14 ' style={{ fontWeight: "400px" }}>{entry?.remarks}</div></td>
                                                <td className="text-center align-middle">
                                                    <div className="d-flex p-3 justify-content-center align-items-center  w-100">
                                                        <p className="mt-0 mb-0 font-size-16 " style={{ fontWeight: "400px" }}>{entry?.module}</p>
                                                        {/* <p className="mt-0 mb-0 ml-2 font-size-16 custom-font-600 custom-common-theme-text-color">View</p> */}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </>
                                : <></>
                }
                <div className='d-flex justify-content-center align-items-center' style={{ height: "60vh" }}>
                    <Button variant="contained" className='custom-common-theme-bg-color text-capitalize font-size-16 custom-font-600 font-sans text-white' style={{ width: "200px", height: "55px", borderRadius: "7px" }}>Save as preset</Button>
                </div>
            </div>
        </>
    );
}

export default BookClosureEntries;
