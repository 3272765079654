import React, { useState } from "react";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import Card from '@mui/material/Card';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Select from "react-select";
import makeAnimated from "react-select/animated";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import "./../../Bookclosure/Styles/Bookclosure.css";
import { setIsAddToTackModalOpen, setIsEditLedgerModal } from "../../redux/actions/BookClosure/actionBookClosure";
import AddLedgerToTaskModal from "./AddLedgerToTaskModal";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditLedgerTaskModal from "./EditLedgerTaskModal";

export const customStyles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: '#F1F2F5',
        borderColor: state.isFocused ? 'gray' : provided.borderColor,
        boxShadow: state.isFocused ? '0 0 0 0' : provided.boxShadow,
        '&:hover': {
            borderColor: state.isFocused ? 'gray' : provided['&:hover'].borderColor
        },
        minHeight: '50px',
        height: '50px',
    }),
    menu: (provided) => ({
        ...provided,
        zIndex: 2,
    }),
};

const AddTaskModal = ({
    isAddTaskModal,
    setAddTaskForm,
    addTaskForm,
    AddTask,
    handleTaskModalClose,
    ReturnTask,
    isTaskAction,
    userData,
    AddLedgerToTask,
    UpdateLedgerInTask
}) => {
    const animatedComponents = makeAnimated();
    const dispatch = useDispatch();
    const {
        assignOptions,
        approveOptions,
        addedLedgers,
    } = useSelector((state) => state.BookClosureReducer);

    const [ledgerDetails, setLedgerDetails] = useState({
        remark:"",
        amount: "",
        ledgerId: ""
    })

    const handleSelectChange = (selectedOptions, { name }) => {
        if (name === "assigneeID") {
            setAddTaskForm(prev => ({ ...prev, selectedAssignId: selectedOptions }))
            setAddTaskForm(prev => ({ ...prev, [name]: selectedOptions.map(option => option.value) }))
        } else {
            setAddTaskForm(prev => ({ ...prev, selectedApproverId: selectedOptions }))
            setAddTaskForm(prev => ({ ...prev, [name]: selectedOptions.value }))
        }
    };

    return (
        <>
            <Modal open={isAddTaskModal} onClose={handleTaskModalClose}>
                <Box sx={{
                    position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                    width: '90%', bgcolor: 'background.paper', boxShadow: 24, p: 1, backgroundColor: "#F0DBC8", borderRadius: "10px",
                }}>
                    <div>
                        <div className='d-flex justify-content-between mt-1 p-2'>
                            <p className='mt-0 mb-0 font-size-24 custom-font-600'>Template</p>
                            <div className="d-flex" >
                                <button
                                    className={`border-0 radius-6px bg-transparent py-1 px-3 font-size-16 custom-font-500
                                    ${userData?.responsibilities === "approver" ? "text-success" : "custom-common-theme-text-color"}
                                `}
                                    onClick={AddTask}
                                >
                                    {userData?.responsibilities === "approver" ? "Approve" :
                                        userData?.responsibilities === "preparer" ? "Submit" :
                                            isTaskAction ? "Update" : "Save"}
                                </button>
                                {userData?.responsibilities === "approver" &&
                                    <button
                                        className={`border-0 radius-6px bg-transparent py-1 px-3 font-size-16 custom-font-500 text-danger`}
                                        onClick={ReturnTask}
                                    >
                                        Return
                                    </button>
                                }
                                <button
                                    className={`border-0 radius-6px bg-transparent py-1 px-3 font-size-16 custom-font-500`
                                    }
                                    onClick={handleTaskModalClose}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                        <div >
                            <Card style={{ overflow: "scroll", height: "600px" }}>
                                <div className='p-3'>
                                    <div className='d-flex justify-content-between'>
                                        <div className='d-flex flex-column'>
                                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Task Name <span className='text-danger'>*</span></h3>
                                            <input
                                                style={{ width: "250px" }}
                                                type={"text"}
                                                className='custom-bg-gray-300 radius-6px'
                                                placeholder="Enter task name"
                                                name="name"
                                                value={addTaskForm.name}
                                                onChange={(e) => setAddTaskForm(prev => ({ ...prev, name: e.target.value }))}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-center align-items-center ml-1'>
                                            <div className='link-icon'>
                                                <CalendarMonthIcon className='mt-2 ml-2 calndr' style={{ width: "20px", height: "20px", color: "black" }} />
                                            </div>
                                            <div>
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['DatePicker', 'MobileDatePicker']}>
                                                        <MobileDatePicker
                                                            className='datepicker-color'
                                                            value={dayjs(addTaskForm.dueDate)}
                                                            onChange={(value) => { setAddTaskForm(prev => ({ ...prev, dueDate: value })) }}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div className='link-iconn'>
                                                <ExpandMoreIcon className='mt-2 arrow-icon' />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='d-flex flex-column'>
                                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Task Description  <span className='text-danger'>*</span></h3>
                                            <textarea
                                                style={{ height: "96px", textAlign: "start", resize: "none" }}
                                                className='custom-bg-gray-300 radius-6px w-50 p-2 mt-1 '
                                                placeholder="Enter task description"
                                                name="description"
                                                value={addTaskForm.description}
                                                onChange={(e) => setAddTaskForm(prev => ({ ...prev, description: e.target.value }))}
                                            />
                                        </div>
                                    </div>
                                    <div className='d-flex mt-4'>
                                        <div className='d-flex flex-column w-25'>
                                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Assignee <span className='text-danger'>*</span></h3>
                                            <Select
                                                isMulti
                                                placeholder="Assign..."
                                                name="assigneeID"
                                                value={addTaskForm.selectedAssignId}
                                                onChange={handleSelectChange}
                                                options={assignOptions}
                                                className="basic-multi-select w-100 mt-1 custom-bg-gray-300"
                                                classNamePrefix="select"
                                                components={animatedComponents}
                                                closeMenuOnSelect={false}
                                                styles={customStyles}
                                            />
                                        </div>
                                        <div className='d-flex flex-column ml-2 w-25'>
                                            <h3 className='font-size-14 custom-font-500 mt-0 mb-0 text-secondary'>Approver <span className='text-danger'>*</span></h3>
                                            <Select
                                                // isMulti'
                                                placeholder="Approve..."
                                                name="approverID"
                                                value={addTaskForm.selectedApproverId}
                                                onChange={handleSelectChange}
                                                options={approveOptions}
                                                className="basic-multi-select w-100 mt-1 custom-bg-gray-300"
                                                classNamePrefix="select"
                                                components={animatedComponents}
                                                // closeMenuOnSelect={false}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-3'>
                                        <label className="mt-1 mb-2 ml-1 custom-font-500 font-size-14 text-secondary">Workings and Remarks </label>
                                        <Card className='w-100 radius-6px' style={{ border: "2px solid #F2F3F4", }}>
                                            <div className='w-100 p-1'>
                                                <CKEditor
                                                    // disabled={isTaskAction}
                                                    editor={ClassicEditor}
                                                    data={addTaskForm.workingsAndRemarks || ""}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        setAddTaskForm(prev => ({ ...prev, workingsAndRemarks: data }));
                                                    }}
                                                    config={{
                                                        placeholder: 'Type......',
                                                        // isReadOnly: isTaskAction
                                                    }}
                                                />
                                            </div>
                                        </Card>
                                    </div>
                                    {/*ledgers table */}
                                    {
                                        isTaskAction &&
                                        <div className="mt-4">
                                            <Card className="radius-8px">
                                                <div className="p-1 radius-8px">
                                                    <table className="table rounded" >
                                                        <thead style={{ backgroundColor: "#F2F3F4 " }}>
                                                            <tr>
                                                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Ledger Code</th>
                                                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Ledger Name</th>
                                                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Date</th>
                                                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Amount</th>
                                                                <th className="text-left text-uppercase font-size-12 custom-font-500 text-secondary font-inter">Remarks</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                addedLedgers?.length > 0 && addedLedgers.map((ledger, lx) => (
                                                                    <tr key={lx}>
                                                                        <td className="text-left"> { ledger?.ledgerCode } </td>
                                                                        <td className="text-left"> { ledger?.ledgerName } </td>
                                                                        {
                                                                            ledger?.tasks && ledger?.tasks.length > 0 && 
                                                                            ledger?.tasks.map((task, tx) => (
                                                                                <React.Fragment key={tx}>
                                                                                    <td className="text-left"> { dayjs(task?.date).format("DD/MM/YYYY") } </td>
                                                                                    <td className="text-left">{ task?.amount }</td>
                                                                                    <td className="text-left"> { task?.remarks } </td>
                                                                                </React.Fragment>
                                                                            ))
                                                                        }
                                                                        <td> 
                                                                            <div 
                                                                                className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    dispatch(setIsEditLedgerModal(true))
                                                                                    setLedgerDetails(prev => ({...prev, ledgerId: ledger._id}))
                                                                                }}
                                                                            >
                                                                                <DriveFileRenameOutlineIcon className="text-secondary" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                            <tr>
                                                                <th scope="row" colSpan={4}>
                                                                    <button
                                                                        className="w-100 d-flex align-items-center justify-content-center border-0 bg-color-inherit"
                                                                        style={{ gap: "6px" }}
                                                                        onClick={() => {
                                                                            dispatch(setIsAddToTackModalOpen(true))
                                                                        }}
                                                                    >
                                                                        <span><AddIcon style={{ width: "16px", height: "16px" }} /></span>
                                                                        <p className="mt-1 mb-0 font-size-12 custom-font-500 text-secondary">Add task</p>
                                                                    </button>
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Card>
                                        </div>
                                    }
                                </div>
                            </Card>
                        </div>

                    </div>
                </Box>
            </Modal>
            <AddLedgerToTaskModal
                AddLedgerToTask={AddLedgerToTask}
            />
            <EditLedgerTaskModal 
                UpdateLedgerInTask={() => UpdateLedgerInTask(ledgerDetails.ledgerId, ledgerDetails.remark, ledgerDetails.amount)}
                ledgerDetails={ledgerDetails}
                setLedgerDetails={setLedgerDetails}
            />
        </>
    );
};

export default AddTaskModal;
