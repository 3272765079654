import React from 'react';
import { Checkbox } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { Button } from "react-bootstrap";

const TbcHeader = () => {
    const history = useHistory();

    return (
        <>
            <div className="conventionalMenu" style={{ zIndex: "9" }}>
                <div
                    className="delete"
                    onClick={() => { }}
                >
                    <p>Delete record</p>
                </div>

                <div
                    className="switchMode"
                    onClick={() => { }}
                >
                    <p>Switch Mode</p>
                </div>
                <div
                    className="filter"
                    onClick={() => { }}
                >
                    <p>Filter</p>
                </div>

                <div className="deletedItem cursor-pointer">
                    <p>Deleted items</p>
                </div>

                <div
                    className="deletedItem cursor-pointer"
                    onClick={() => { }}
                >
                    <p>Deleted items</p>
                </div>

                <div className="adjustment">
                    <p>Adjustment</p>
                </div>

                <div
                    className="adjustment"
                    onClick={() => { }}
                >
                    <p>Adjustment</p>
                </div>

                <div className="checkYearly" >
                    <Checkbox
                        label={"Split mapping"}
                        sx={{
                            '&.Mui-checked': {
                                color: '#03565a',
                            }
                        }}
                        onChange={(e) => {
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                    <p>Split mapping</p>
                </div>
                <div className="uploadConventional">
                    <div className="addTb" >
                        <h4>Upload trial balance</h4>
                    </div>
                    <div>
                        <Button className="custom-common-theme-bg-color text-white font-size-16 custom-font-600 font-sans" variant="contained" onClick={() => history.push('/classification-preview')} style={{ width: "200px", borderRadius: "5px" }}>Confirm Grouping</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TbcHeader