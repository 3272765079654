
import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AccordionItem = ({ task, index }) => {
    return (
        <>
            <Accordion className='mt-1' >
                <AccordionSummary 
                    className="p-1 w-100 radius-10px" 
                    expandIcon={<ExpandMoreIcon style={{ backgroundColor: '#F2F3F4', height: '24px', width: '24px', borderRadius: '6px' }} />} 
                    aria-controls="panel1-content" 
                    id="panel1-header"
                >
                    {`${index}.  ${task?.name}`}
                </AccordionSummary>
                <AccordionDetails>
                    <p className='m-0 custom-font-500 font-size-12 text-secondary text-left'> {task?.description} </p>
                </AccordionDetails>
            </Accordion>
        </>

    );
};

export default AccordionItem;
