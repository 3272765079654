import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TaskIcon from '@mui/icons-material/Task';
import dayjs from "dayjs";

const BookClosureTaskList = ({
    module,
    ViewTaskMore,
    setAddTaskForm,
    setIsFieldDialog,
    bookClosureArray
}) => {
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className='text-capitalize py-2 px-3 d-flex justify-content-between'
            >
                {module?.module}
            </AccordionSummary>
            <AccordionDetails>
                <table>
                    <thead className="text-white custom-bg-white-150">
                        <tr>
                            <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter">Tasks</th>
                            <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter">Execute</th>
                            <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter">Assignee</th>
                            <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter">Approver</th>
                            <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter">Due</th>
                            <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter">Notification</th>
                            <th className="text-left text-secondary text-uppercase font-size-16 custom-font-600 font-inter">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            module?.tasks && module?.tasks.length > 0 &&
                            module?.tasks.map((task, index) => (
                                <tr key={index}>
                                    <td className="text-left pl-2 custom-font-500 font-size-12 font-sans mt-1">
                                        {task?.name}
                                    </td>
                                    <td className="text-left pl-2">
                                        <button
                                            className='d-flex align-items-center mt-2 border-0 bg-color-inherit'
                                        onClick={() => ViewTaskMore(task._id)}
                                        >
                                            <TaskIcon style={{ width: '14px', height: '14px', color: '#03565A', marginTop: '-4px' }} />
                                            <p className='mt-0 mb-0 custom-font-500 font-size-12 ml-1 custom-common-theme-text-color font-sans'>View more</p>
                                        </button>
                                    </td>
                                    <td className="text-left pl-2 custom-font-500 font-size-12 font-sans">
                                        {task.assigneeData.map(assign => assign?.name).join(', ')}
                                    </td>
                                    <td className="text-left pl-2 custom-font-500 font-size-12 font-sans">
                                        {task.approverData?.name}
                                    </td>
                                    <td className="text-left pl-2 custom-font-500 font-size-12 font-sans">
                                        {task?.dueDate ? dayjs(task?.dueDate).format("DD-MM-YYYY") : ""}
                                    </td>
                                    <td className="text-left pl-2 custom-font-500 font-size-12 font-sans">
                                    </td>
                                    <td className="text-left pl-2 custom-font-500 font-size-12 font-sans">
                                        {task.status}
                                    </td>
                                </tr>
                            ))
                        }
                        {
                            bookClosureArray.length === 0 &&
                            (<tr className='custom-common-secondary-bg'>
                                <th className="text-left font-size-16 custom-font-600 font-sans" colSpan="7">Lease</th>
                            </tr>)
                        }
                        <tr>
                            <th className="text-center" colSpan="7">
                                <button
                                    onClick={() => {
                                        setAddTaskForm(prev => ({ ...prev, 
                                            module: bookClosureArray.length === 0 ? "lease" : module?.module 
                                        }));
                                        setIsFieldDialog(true);
                                    }}
                                    className='border-0 bg-color-inherit font-size-12 custom-font-500 font-inter text-secondary'
                                >
                                    + Add Task
                                </button>
                            </th>
                        </tr>
                    </tbody>
                </table>
            </AccordionDetails>
        </Accordion>
    )
}

export default BookClosureTaskList