import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import moment from "moment";

import "./setUpProject.css";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../redux/actionTypes";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Button } from "@mui/material";
import { useParams } from "react-router";
import {
  GetAllCompanyData,
  GetProject,
  findCompanyData,
  getCheckData,
  getWorkspace,
} from "../../WorkSpace/Components/ApiServices/apiHandler";
import { getProjectOptions } from "../../services/api/StandAlone/project";
import { createPreset } from "../../services/api/StandAlone/preset";

const initialState = {
  company: "",
  projectName: "",
  companyNature: "",
  GAAP: "",
  prevDate: moment().subtract(1, "years").toDate(),
  currentDate: moment().toDate(),
  preset: "",
  currency: "",
};

const gaapOptions = [
  { value: "IND AS", label: "IND AS" },
  { value: "UK Gaap", label: "UK GAAP", isDisabled: true },
  { value: "US Gaap", label: "US GAAP", isDisabled: true },
  { value: "GAAP", label: "GAAP"},
  { value: "IFRS", label: "IFRS"},
];

const natureOptions = [
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Trading", label: "Trading", isDisabled: true },
  { value: "NBFC", label: "NBFC", isDisabled: false },
  { value: "Banking", label: "Banking", isDisabled: true },
];

const QuestionnaireNew = () => {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();
  let history = useHistory();
  const param = useParams();
  const [value, setValue] = useState(initialState);
  const [companyval, setCompany] = useState("");
  const [valid, setValid] = useState(false);
  const [extra, setExtra] = useState("");
  const [workspaceData, setWorkspace] = useState("");
  const [previousdata, setPreviousData] = useState("");
  const [oneCompany, setOneCompanyData] = useState("");
  const [allCompany, setAllCompany] = useState([]);
  const [allProject, setAllProject] = useState([]);
  const [companyAll, setCompanyAll] = useState([]);
  const [presetAll, setPresetAll] = useState([]);
  const [w_id, setW_id] = useState("");

  useEffect(() => {
    setW_id(param.wid);
    getWorkSpaceCheck(param.wid);
    companyData();
    projectData();
  }, []);

  const companyData = async () => {
    try {
      const res = await GetAllCompanyData();
      if (res.status === 200) {
        setAllCompany(res.data.getAllCompanyData);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const projectData = async () => {
    try {
      const res = await GetProject();
      if (res.status === 200) {
        setAllProject(res.data.project);
      }
    } catch (error) {
      console.log(error.message, "error");
    }
  };

  const getPreviousYearData = (w_id, companyNatureValue) => {
    getProjectOptions(w_id, companyNatureValue)
      .then(res => setPresetAll(res.data.projectsData))
      .catch(err => swal("Error", err.response?.data?.error || "Project Data Not Found !", "error"));
  };

  const handleChange = async (field, e) => {
    if (field === "company") {
      const findCompany = await findCompanyData(e.value);
      if (findCompany.status === 200) {
        setOneCompanyData(findCompany.data.getOneCompany[0]);
        const companyNature = findCompany.data.getOneCompany[0]?.nature_of_company;
        setValue({ ...value, companyNature });
        getPreviousYearData(w_id, companyNature);
      }
      setCompany(e.value);
    }
    // else if (field === "companyNature"){
    //   setValue({ ...value, [field]: e.value });
    //   getPreviousYearData(w_id, e.value);
    // }
    else{
      setValue({ ...value, [field]: e.value });
    }
  };
  function validationExisting() {
    let val = delete value.company;
    let obj = Object.values(val);
    if (obj.includes("") | obj.includes(null) && obj[5] !== "") {
      setValid(false);
    } else {
      setValid(true);
    }
  }

  useEffect(() => {
    validationExisting();
    getWorkspaceData(param.wid);
  }, [value]);

  const handleSubmit = () => {
    if (valid) {
      const fd = {
        company: companyval,
        project_name: value.projectName,
        company_type: value.companyNature,
        gaap_selection: value.GAAP,
        current_year: moment(workspaceData[0]?.standalone_cy).format(),
        previous_year: moment(workspaceData[0]?.standalone_py).format(),
        previous_year_data: previousdata,
        extra_data: extra,
        type_fs: "vvv",
        preset_name: "not available",
        currency: value.currency,
        workspace_id: w_id,
      };

      // console.log(fd, "fd created");
      // dispatch(existingProject(fd))
      // fd.append('email','Value.email');
      // fd.append('password','Value.password');
      // console.log(Value)

      if (previousdata && previousdata.length > 0) {
        const filterPrevData = (previousdata || []).filter(ele => ele.label !== "Select");
        if(filterPrevData.length === 0) return swal("Warning", "Please Select Preset.", "warning");

        fd.previous_year_data = filterPrevData;

        dispatch({ type: "CREATE_PROJECT", payload: fd });
        dispatch({ type: "SELECTED_PRESETS", payload: filterPrevData });
        createPreset({ creating_project: fd })
          .then((res) => {
            history.push(`/preset/${res.data.presetId}`);
          })
          .catch((err) => {
            console.log("error happening while creating preset", err);
          });
      } else {
        const auth = localStorage.getItem("auth_token");
        let headers = {
          "x-auth-token": auth,
          "Content-Type": "application/json",
        };
        axios
          .post(`api/v1/tb-onboarding/tb-onboarding`, fd, { headers })
          .then((response) => {
            console.log(response, "response");
            history.push("/createProject/uploadTb");
            dispatch({ type: actionTypes.SET_RESTART, payload: "arr" });
            dispatch({ type: actionTypes.SET_NOTERESTART, payload: "arr" });
            localStorage.setItem("project_id", response.data.project_id);
          })
          .catch((error) => {
            swal("", error.response.data.error, "error");
          });
      }
    } else {
      swal("", "Please Fill All the input Fields", "warning");
    }
  };

  const getFinancialDate = (e, type) => {
    if (type === "current") {
      setValue({ ...value, currentDate: e });
    } else {
      setValue({ ...value, prevDate: e });
    }
    getPreviousYearData();
  };

  const getWorkspaceData = async (w_id) => {
    const res = await getWorkspace(w_id);
    if (res.status === 200) {
      setWorkspace(res.data.getworkspace);
    }
  };

  const getWorkSpaceCheck = async (w_id) => {
    const res = await getCheckData(w_id);
    if (res.status === 200) {
      setCompanyAll(res.data.getIR[0]?.all_company_checked);
    }
  };

  const CompanyName = (c_id) => {
    try {
      const nameCompany = allCompany.filter((adj) => adj._id === c_id);
      const result = nameCompany[0]?.company_name
        ? nameCompany[0]?.company_name
        : "unknown";
      return result;
    } catch (error) {
      console.log(error, "2222");
      return 0;
    }
  };
  
  return (
    <>
      <div className="row" style={{ marginRight: "0px" }}>
        <div className="col-4">
          <div className="track">
            <div className="progressBar">
              <div className="vector-1"></div>
              <div className="progressRoute">
                <div className="track1">
                  <h4>Project Creation</h4>
                  <p>Fill basic details for creating a <br /> Project</p>
                </div>
                <div className="track1">
                  <h4>File Upload</h4>
                  <p>Upload Individual or Multiple <br /> Trial Balances</p>
                </div>
                <div className="track1">
                  <h4>Data Mapping</h4>
                  <p>Map General ledgers to the Financial <br /> Statements</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-8 main">
          <div className="row">
            <div className="col-12">
              <div className="header">
                <h1>Create Blank Project</h1>
              </div>
            </div>
            {w_id === "noWp" ? (
              ""
            ) : (
              <div className="col-md-6">
                <div className="in-1">
                  <p>
                    Company<span>*</span>
                  </p>
                  <Select
                    placeholder="Select option"
                    options={companyAll?.map((det) => {
                      return { value: det, label: CompanyName(det) };
                    })}
                    onChange={(e) => handleChange("company", e)}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "6px",
                      colors: {
                        ...theme.colors,
                        primary25: "#03565a98",
                        primary: "#03565A",
                      },
                    })}
                  />
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="in-1">
                <p>
                  Name of the financials<span>*</span>
                </p>
                <input
                  placeholder="Enter name.."
                  onChange={(e) => {
                    setValue({ ...value, ["projectName"]: e.target.value });
                  }}
                ></input>
              </div>
            </div>
            <div className="col-md-6">
              <div className="in-1">
                <p>
                  Nature of the financials<span>*</span>
                </p>
                {/* <Select
                  placeholder="Select option"
                  options={natureOptions}
                  onChange={(e) => handleChange("companyNature", e)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "6px",
                    colors: {
                      ...theme.colors,
                      primary25: "#03565a98",
                      primary: "#03565A",
                    },
                  })}
                /> */}
                <input value={oneCompany?.nature_of_company} disabled />
              </div>
            </div>
            <div className="col-md-6">
              <div className="in-1">
                <p>
                  Selection of GAAP<span>*</span>
                </p>
                <Select
                  placeholder="Select option"
                  options={gaapOptions}
                  // value={value.companyNature}
                  onChange={(e) => handleChange("GAAP", e)}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "6px",
                    colors: {
                      ...theme.colors,
                      primary25: "#03565a98",
                      primary: "#03565A",
                    },
                  })}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="in-1">
                <p>
                  Current financial Year<span>*</span>
                </p>
                {w_id === "noWp" ? (
                  <DatePicker
                    value={value?.currentDate}
                    dateFormat="DD/MM/yyyy"
                    onChange={(e) => getFinancialDate(e, "current")}
                  />
                ) : (
                  <DatePicker
                    value={workspaceData[0]?.standalone_cy}
                    dateFormat="DD/MM/yyyy"
                    onChange={(e) => getFinancialDate(e, "current")}
                    disabled
                  />
                )}
              </div>
            </div>

            <div className="col-6">
              <div className="in-1">
                <p>
                  Previous financial Year<span>*</span>
                </p>
                {w_id === "noWp" ? (
                  <DatePicker
                    value={value?.prevDate}
                    dateFormat="DD/MM/yyyy"
                    onChange={(e) => getFinancialDate(e, "previous")}
                    maxDate={moment().toDate()}
                  />
                ) : (
                  <DatePicker
                    value={workspaceData[0]?.standalone_py}
                    dateFormat="DD/MM/yyyy"
                    onChange={(e) => getFinancialDate(e, "previous")}
                    maxDate={moment().toDate()}
                    disabled
                  />
                )}
              </div>
            </div>

            {w_id === "noWp" ? (
              ""
            ) : (
              <div className="col-md-6">
                <div className="in-1">
                  <p>Select presets/Chart of accounts</p>
                  <Select
                    placeholder="Select presets/Chart of accounts"
                    options={presetAll}
                    // onChange={(e) => handleChange("preset",e)}
                    onChange={(e) => setPreviousData(e)}
                    isMulti
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: "6px",
                      colors: {
                        ...theme.colors,
                        primary25: "#03565a98",
                        primary: "#03565A",
                      },
                    })}
                  />
                </div>
              </div>
            )}
            <div className="col-md-6">
              <div className="in-1">
                <p>Functional Currency</p>
                {/* <Select
                  placeholder="Select option"
                  options={currencyOptions}
                  // onChange={(e) => handleChange("currency", e)}
                   value={{
                    label: oneCompany?.functional,
                    value: oneCompany?.functional,
                  }}
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: "6px",
                    colors: {
                      ...theme.colors,
                      primary25: "#03565a98",
                      primary: "#03565A",
                    },
                  })}
                /> */}
                <input
                  value={oneCompany?.functional}
                  disabled
                ></input>
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center align-items-center">
              <Button

                onClick={() => handleSubmit()}
                disabled={valid ? false : true}
                variant="contained"
                className="custom-common-theme-bg-color px-4 py-3 font-inter"
              >
                {previousdata ? "Save and Proceed" : "Create Project"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireNew;
