import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useParams } from "react-router";
import { SplitPane } from "react-collapse-pane";
import swal from "sweetalert";
import { toast } from 'react-toastify';
import { Navbar } from "../../Components/Navbar";
import TbcHeader from "../Components/TBC/TbcHeader";
import { useDispatch, useSelector } from "react-redux";
import { getBCTbRows } from "../../redux/actions/BookClosure/actionBookClosure";
import Loader from "../../helper/loader";
import TbcTableData from "../Components/TBC/TbcTableData";
import TemplateTab from "../Components/TBC/TemplateTab";

const Tbc = () => {
  const { bookClosureTbId } = useParams();
  const dispatch = useDispatch();

  const {
    lineItems,
    groupings,
    filters,
    loading,
    errorMessage,
    error
  } = useSelector((initialState) => initialState.BookClosureTbcReducer);

  useEffect(() => {
    dispatch(getBCTbRows(bookClosureTbId));
  }, [dispatch, bookClosureTbId]);


  return (
    <React.Fragment>
      <Navbar />
      <div style={{ maxHeight: "77vh", overflowY: "hidden" }} >
        <TbcHeader />
        <div>
          <div
            className="tabsinside w-100"
            style={{ maxHeight: "77vh", overflow: "hidden" }}
          >
            <SplitPane
              minSizes={[600, 300]}
              initialSizes={[60, 40]}
              split="vertical"
              collapseOptions={{
                beforeToggleButton: <Button>⬅</Button>,
                afterToggleButton: <Button>➡</Button>,
                overlayCss: { backgroundColor: "black" },
                buttonTransition: "zoom",
                buttonPositionOffset: -20,
                collapsedSize: 50,
                collapseTransitionTimeout: 350,
              }}
              resizerOptions={{
                css: {
                  width: "5px",
                  background: "#03565ade",
                },
                hoverCss: {
                  width: "5px",
                  background: "4px solid rgba(0, 0, 0)",
                },
                grabberSize: "5px",
              }}
            >
              <div className="h-100 d-flex flex-column">
                <div style={{ overflow: "scroll", height: "96%" }}>
                {loading ? (
                    <Loader />
                  ) : error ? (
                    <p className="d-flex justify-content-center align-items-center h-100 text-danger">
                      {errorMessage}
                    </p>
                  ) : (
                    <TbcTableData lineItems={lineItems.length > 0 ? lineItems : []} />
                  )}
                </div>
              </div>

              <div style={{ maxHeight: "77vh", overflowY: "scroll" }}>
                <TemplateTab />
              </div>
            </SplitPane>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default React.memo(Tbc);
